import React, { useContext, useEffect, useState } from 'react';
import OrderFilterComponent from '../orders/OrderFilter';
import { Search, Select, Dropdown, Icon, Row, Col, FormItem, Form, Input } from '../../common/UIComponents';
import { removeSpecialCharacters } from '../../helpers/common';
import I18n from '../../common/I18n';
import { Button, Switch, Tooltip } from 'antd';
import { OrderConfigContext } from '../../context/OrderConfigContext';
import { isEmpty } from '../../common/Common';
import { getFromToDate } from '../orders/helpers';

const SearchOptions = (props) => {
  const { filter, handleFilterPlaceHolderChange, elementSize, onSearch, handleFilterValueChange, orderNumberSearch, searchType, searchText , setSearchText , closeFilterWindow,WarehouseContext } = props;
  const isContainsSearch = filter.search_type == "CONTAINS";

  const { filterPlaceHolder : savedFilter , setFilterPlaceHolder } = useContext(OrderConfigContext);

  return <Row type='flex' align='middle' gutter={ 8 } className='globalSearchInputContainer' style={ { width: "100%" } }>
    <Col span={ 4 }>
        { isContainsSearch && (
          <Select
          // value={ filter.search_key_type }
          onChange={ e => handleFilterValueChange("search_key_type", e) }
            size={ elementSize }
          // defaultValue={ "hawb" }
            value={ savedFilter.search_key_type}
            style={ {
              width: "100%",
              fontSize: "14px",
            } }
          dropdownMatchSelectWidth={ false }
          >
          <Select.Option value="customer_order_number">
              { I18n.t("order.filters.order_number") }
            </Select.Option>
            <Select.Option value="hawb">
              { I18n.t("general.hawb") }
            </Select.Option>
            <Select.Option value="mawb">
              { I18n.t("general.mawb") }
            </Select.Option>
            <Select.Option value="reference_1">
              { I18n.t("general.reference_1") }
            </Select.Option>
            <Select.Option value="reference_2">
              { I18n.t("general.reference_2") }
            </Select.Option>
            <Select.Option value="customer_first_name">
              { I18n.t("order.filters.name_search_key") }
            </Select.Option>
            <Select.Option value="company_name">
            { I18n.t("order.filters.company_name") }
            </Select.Option>
            <Select.Option value="customer_phone_one">
              { I18n.t("order.filters.phone_search_key") }
            </Select.Option>
            <Select.Option value="routing">
              { I18n.t("order.routing") }
            </Select.Option>
            <Select.Option value="street_address">
              {I18n.t("general.street_address")}
            </Select.Option>
            <Select.Option value="zipcode">
              {I18n.t("general.zipcode")}
            </Select.Option>
            <Select.Option value="city">
              {I18n.t("general.city")}
            </Select.Option>
            <Select.Option value="it_number">
              {I18n.t("general.it_number")}
            </Select.Option>
            </Select>
      ) }
    </Col>


    <Col span={ 20 } className='search_input'>
      <Search
        placeholder={ !isContainsSearch ? I18n.t("order.filters.placeholder") : I18n.t("search.value") }
        size={ elementSize }
        value={ searchText }
        style={ { fontSize: "14px", width: "100%" } }
        className='globalSearchInput'
        onChange={ (e) => {
          handleFilterPlaceHolderChange({
            ...filter,
            search_order_token: e.target.value,
            search_key_value: e.target.value,
          });
          setSearchText(e.target.value);
        } }
        onPressEnter={ (e) => {
          const filteredString =  e.target.value; // removeSpecialCharacters(e.target.value);
          if (!isContainsSearch) {
            orderNumberSearch(filteredString);
          } else {
            onSearch(true);
          }
        } }
        suffix={ props.suffixComponent }
        prefix={
          <Row
            type="flex"
            align="middle"
            className="invoiceSearchPrefixes"
            gutter={ 4 }
          >
            <Col>
                {/* <Switch
                  checked={isContainsSearch}
                  onChange={ checked => {
                    const searchType = checked ? "CONTAINS" : "EQUALS";
                    handleFilterPlaceHolderChange({
                      ...filter,
                      search_type: searchType,
                      search_key_type: checked ? "hawb" : ""
                    }, () => {
                      if (!isEmpty(searchText)) {
                        checked ? onSearch(false, true) : orderNumberSearch(searchText);
                      }
                    });
                  } }
                  checkedChildren="Contains"
                  unCheckedChildren="Equals"
                /> */}
              {/* <Select
                defaultValue={ "EQUALS" }
                style={ { width: 120 } }
                onChange={ value => {
                  const isContains = value === "CONTAINS";
                  handleFilterPlaceHolderChange({
                    ...filter,
                    search_type: value,
                    search_key_type: isContains ? "hawb" : ""
                  }, () => {
                    if (!isEmpty(searchText)) {
                      isContains ? onSearch(false, true) : orderNumberSearch(searchText);
                    }
                  });
                } }
              >
                <Option value="CONTAINS">Contains</Option>
                <Option value="EQUALS">Equals</Option>
              </Select> */}
              <Select
                size="small"
                // defaultValue={ "EQUALS" }
                // value={ searchType }
                value={ savedFilter.search_type }
                onChange={ (e) => {
                  if (e === "EQUALS") {
                    handleFilterPlaceHolderChange({
                      ...filter,
                      search_type: e,
                      search_key_type: ""
                    }, () => {
                      if (!isEmpty(searchText)) {
                        orderNumberSearch(searchText);
                      }
                    });
                  } else {
                    handleFilterPlaceHolderChange({
                      ...filter,
                      search_type: e,
                      search_key_type: "hawb",
                    }, () => {
                      if (!isEmpty(searchText)) {
                        onSearch(true);
                      }
                    }
                    );
                  }
                } }
                // value={ filter.search_type }
                style={ {
                  //textAlign: "center",
                  fontSize: "14px",
                  lineHeight: "1",
                } }
                dropdownMatchSelectWidth={ false }
              >
                <Select.Option key="CONTAINS" value="CONTAINS">CONTAINS</Select.Option>
                <Select.Option key="EQUALS" value="EQUALS">EQUALS</Select.Option>
              </Select>
            </Col>
          </Row>
        }
      />
    </Col>
  </Row>;
};

function GlobalSearch (props) {
  const { filterPlaceHolder, handleFilterValueChange, handleFilterPlaceHolderChange, handleSearch, handleSaveNSearch, handleVisibleChange, filterWindow, landingPage, currentUser, handleOnChange, isShipper, orderNumberSearch,WarehouseContext } = props;
  const [ searchText , setSearchText ] = useState(filterPlaceHolder.search_order_token)
  const [ searchType, setSearchType ] = useState(filterPlaceHolder.search_type)  
  var currentPath = window.location.pathname;
  var isOrderRelatedScreen = /^\/orders(\/|$)/.test(currentPath);
  const { filterPlaceHolder : savedFilter , setFilterPlaceHolder } = useContext(OrderConfigContext);

  useEffect(() => {
    // if(!filterPlaceHolder.search_order_token){
      setSearchText(filterPlaceHolder.search_order_token)
    // }
  }, [filterPlaceHolder.search_order_token])

  // useEffect(() => {
  //   setSearchType(filterPlaceHolder.search_type)
  // }, [filterPlaceHolder.search_type])

  useEffect(() => {
    if (!isOrderRelatedScreen) {
      // setIssearched(false);
      setSearchText("");
      setFilterPlaceHolder({
        ...savedFilter,
        search_key_value: "",
        search_order_token: "",
      });
    }
  }, [isOrderRelatedScreen]);

  const { fromDate, toDate } = getFromToDate('Current Week');
  return (
    <Row type='flex' align='middle' >
      <Col span={ 24 }>
        <SearchOptions
          filter={ filterPlaceHolder }
          handleFilterPlaceHolderChange={ handleFilterPlaceHolderChange }
          elementSize="default"
          onSearch={ handleSearch }
          setSearchType={ setSearchType }
          searchType={ searchType }
          searchText={ searchText }
          closeFilterWindow={ () => handleOnChange("filterWindow", false) }
          setSearchText={ setSearchText }
          orderNumberSearch={ orderNumberSearch }
          handleFilterValueChange={ handleFilterValueChange }
          suffixComponent={
            <Dropdown
              onVisibleChange={ handleVisibleChange }
              visible={ filterWindow }
              trigger={ [ "click" ] }
              placement="bottomRight"
              overlay={
                <div
                style={ {
                  backgroundColor: "white",
                  padding: 15,
                  marginTop: 10,
                  minWidth: 550,
                  padding: 15,
                  marginLeft: 40
                } }
                  className="boxShadow"
                >
                  <OrderFilterComponent
                    showOrderNoSearch={ false }
                    filter={ filterPlaceHolder }
                    onChange={ handleFilterPlaceHolderChange }
                    onSearch={ handleSearch }
                    onSaveNSearch={ handleSaveNSearch }
                    onOrderNoSearch={ null }
                    showWarehouses
                    showScheduleOption={ true }
                    isShipper={ isShipper }
                    showSaveNSearch={ true }
                    showClearFilter={ true }
                    setSearchType={ setSearchType }
                    searchType={ searchType }
                    handleFilterValueChange={ handleFilterValueChange }
                    WarehouseContext={ WarehouseContext }
                  />
                </div>
              }
            >
              <Row type='flex' align='middle' gutter={ 6 } >
                <Col>
                  { searchText !== "" && (
                    <Icon
                      type="close"
                      style={ { color: '#f5222d' } } 
                      onClick={ () => {
                        setSearchText("");
                        handleFilterPlaceHolderChange({
                          ...filterPlaceHolder,
                          schedule_day_filter : 'Current Week',
                          search_key_value: "",
                          search_order_token: "",
                          fromDate,
                          toDate,
                        }, () => {
                          handleSearch();
                        });
                      } }
                    />
                  ) }
                </Col>
                <Col>
                  <Button type="primary" shape="round" icon="file-search" size={ "small" }
                    onClick={ (e) => {
                      e.stopPropagation();
                      // if(searchText === ""){ return }
                      if (searchType !== "CONTAINS") {
                        orderNumberSearch(searchText);
                      } else {
                        handleSearch(true);
                      }
                    } }
                  />
                </Col>

                <Col>
                  <Button size="small" icon='caret-down' onClick={ () =>
                    {
                      handleOnChange("filterWindow", !filterWindow);
                    }
                  }>
                    More
              </Button>
                </Col>
              </Row>
            </Dropdown>
          }
        />
      </Col>
      <Col>
      </Col>
    </Row>
  )
}

export default GlobalSearch;

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Gallery from 'react-image-gallery';
import moment_tz from 'moment-timezone'
import PDFViewer from './PDFViewer';
import 'react-image-gallery/styles/css/image-gallery.css';
import './MixedGallery.css'; // Import the CSS file
import pdfIcon from '../../assets/images/download-pdf.svg';
import csvIcon from '../../assets/images/csv.png';
import excelIcon from '../../assets/images/excel.png';
import { Alert, Button, Col, Icon, Result, Row, Tag, Tooltip } from 'antd';
import AppConfig from '../config/AppConfig';
import media from '../../assets/images/creative.png';
import sign from '../../assets/images/sign.png';
import fileIcon from '../../assets/images/fileIcon.png';
import { red } from '@mui/material/colors';
import { downloadFile, printFile } from '../common/preview';


const MixedGallery = (props) => {
  const { files, currentImage, orderPictures, showCapturedDate = false, deleteImage, showDeleteButton = false, showPrintButton = false, closeComponent, handlePrint,currentOrder={} } = props;
  const fileTypes = AppConfig.fileTypes;
  const [ currentImageName, setCurrentImageName ] = useState("");
  const [errorStatus, setErrorStatus] = useState({});
  const [ isPrinting, setIsPrinting ] = useState(false);

  const galleryRef = useRef(null);
  // find the index of the currentImage from the files array if the currentImage is not empty
  let startIndex = 0;
  if (!_.isEmpty(currentImage)) {
    startIndex = files.findIndex((file) => file === currentImage);
  }

 useEffect(() => {
    if (startIndex !== -1 && currentImage) {
      const currentImageName = currentImage.slice(currentImage.lastIndexOf('/') + 1);
      setCurrentImageName(currentImageName);
    }
  }, []);


  // on change of files lenght if zero then close component 
  useEffect(() => {
    if (files.length === 0) {
      closeComponent();
    }
    if (files.length > 0 && !files.includes(currentImage)) {
      goToSlide(0);
    }
  }, [ files ]);


  useEffect(() => {
    if(!_.isEmpty(errorStatus) && errorStatus[currentImage]){
      setCurrentImageName("File Not Found");
    }
  }, [errorStatus]);
  
  const typeIcons = {
    pdf: pdfIcon,
    csv: csvIcon,
    excel: excelIcon,
    video: media,
  };
  
  const getFileType = (extension) => {
    for (const type in fileTypes) {
      if (fileTypes[type].includes(extension)) {
        return type;
      }
    }
    return 'file'; // Default to 'image' if no match is found
  };
  
  const items = files.map((file, index) => {
    // Handle when file is an object with fileUrl property
    const fileUrl = typeof file === 'object' ? file.fileUrl : file;
    let extension;
    let fileName;

    if (typeof fileUrl === 'string') {
      // Handle blob URLs
      if (fileUrl.startsWith('blob:')) {
        extension = file.originalTitle ? file.originalTitle.split('.').pop().toLowerCase() : 'pdf';
        fileName = file.originalTitle || 'download';
      } else {
        // Handle regular URLs
        extension = fileUrl.slice(fileUrl.lastIndexOf('.') + 1).toLowerCase();
        fileName = fileUrl.slice(fileUrl.lastIndexOf('/') + 1);
      }
    } else {
      console.error('Invalid file format:', file);
      return null;
    }

    const fileType = getFileType(extension);
    const finalFileName = file.originalTitle || fileName;

    if (fileType === 'pdf' || fileType === 'docs' || fileType === 'sheet' || fileType === 'video') {
      const type = fileType === 'docs' ? 'csv' : fileType === 'sheet' ? 'excel' : fileType;
      return {
        type: type,
        thumbnail: typeIcons[ type ],
        fileUrl: fileUrl,
        key: index,
        thumbnailHeight: 50,
        thumbnailWidth: 50,
        originalHeight: 200,
        originalWidth: 200,
        originalTitle: errorStatus[ fileUrl ] ? "File Not Found" : finalFileName
      };
    } else if (fileType === 'image') {
      return {
        type: 'image',
        imageUrl: fileUrl,
        original: fileUrl,
        thumbnail: errorStatus[ fileUrl ] ? sign : fileUrl,
        key: index,
        thumbnailHeight: 50,
        thumbnailWidth: 50,
        originalHeight: 500,
        originalWidth: 500,
        originalTitle: errorStatus[ fileUrl ] ? "File Not Found" : finalFileName
      };
    } else {
      return {
        type: 'file',
        thumbnail: fileIcon,
        fileUrl: fileUrl,
        key: index,
        thumbnailHeight: 50,
        thumbnailWidth: 50,
        originalHeight: 200,
        originalWidth: 200,
        originalTitle: finalFileName
      };
    }
  }).filter(Boolean); // Remove any null items


  // Constants for common styles and configurations
  const COMMON_STYLE = { height: "100%", width: "100%", objectFit: "cover", padding: '.5rem' };
  const IMAGE_STYLE = { height: "100%", width: "35vw", objectFit: "cover" };
  const VIDEO_STYLE = { height: "100%", width: "30vw", objectFit: "cover" };

  const renderItem = (item) => { 
    const itemObject = _.find(orderPictures, obj => obj.picture.url === item.original || item.fileUrl || obj.picture.Url === item.original);
    const imageId = _.get(itemObject, '_id', '') || _.get(itemObject, 'id', '');
    const capturedTime = _.get(itemObject, 'captured_at', '') || _.get(itemObject, 'capturedAt', '');
    const timeZoneId = currentOrder ? (_.get(currentOrder, 'timeZoneId', '') || _.get(currentOrder, 'order_timeZoneId', '')) : '';
    
    // Helper function to render captured date
    const renderCapturedDate = () => {
      if (showCapturedDate && capturedTime) {
        return (
          <div style={{ marginBottom: '1rem' }}>
            <strong>Captured Date:</strong>
            <i style={ { marginLeft: '5px' } }>
              {moment_tz.utc(capturedTime).tz(timeZoneId).format(AppConfig.dateTimeFormat)}
              {/* <DisplayTime
                dateTimeString={ capturedTime }
                timeFormat={ AppConfig.dateTimeFormat }
              /> */}
              { ` ${currentOrder.order_tz_short_name}` }
            </i>
          </div>
        );
      }
      return null;
    };

    // Helper function to render delete button
    const renderDeleteButton = (style = true) => {
        return (
          <div style={style ? { marginBottom: '45px', marginTop: '10px' } : null}>
            <Button type="danger" onClick={ () => deleteImage(imageId) }>
              Delete
            </Button>
          </div>
        );
    };

    const onPrint = async (url) => {
      try {
        setIsPrinting(true);
        await handlePrint(url);
      } catch (error) {
        console.error("Error during printing:", error);
      } finally {
        setIsPrinting(false);
      }
    };

    const renderDownloadButton = (url, newTab = false, showPrintButton = false, item = {}) => (
      <Row gutter={ 16 } type='flex' justify='center' style={ { marginBottom: '20px', marginTop: '10px' } }>
        <Col>
          <Button
            type="primary"
            href={ newTab ? undefined : url }
            onClick={ () => downloadFile(url, item.originalTitle) }
            download={ !newTab }
            icon={ 'download' }
          >
            Download
          </Button>
        </Col>
        { showPrintButton && (
          <Col>
            <Button
              type="primary"
              onClick={ () => onPrint(item.fileUrl) }
              icon={ 'printer' }
              style={ { marginBottom: '20px' } }
            >
              { isPrinting ? 'Printing' : 'Print' }
            </Button>
          </Col>
        ) }
      </Row>
    );

    const renderPDFExcelButtons = (url, newTab = false) =>
    (<div style={{ backgroundColor: red, width: '100%', height: '65px', marginTop: '10px' }}>
      <Row type="flex" justify="center" gutter={10} >
        <Col >
          <Button
            type="primary"
            href={newTab ? undefined : url}
            onClick={newTab ? () => window.open(url, "_blank") : undefined}
            download={!newTab}
          // className="download-button"
          >
            Download
          </Button>
        </Col>
        <Col>
          <Button type="danger" onClick={() => deleteImage(imageId)}>
            Delete
          </Button>
        </Col>
      </Row>
    </div>)

    // Helper function to render error alert
    const renderErrorAlert = () => {
      if (errorStatus[ item.imageUrl || item.fileUrl ]) {
        return (
          <Alert
            message="File Not Found"
            type="error"
            style={ { width: "12%" } }
            showIcon
            icon={ <Icon type="info-circle" theme="filled" /> }
          />
        );
      }
      return null;
    };

    const renderActionButtons = (item) => {
      const filename = item.originalTitle || 'download';
      const fileUrl = item.fileUrl || item.imageUrl;

      return (
        <div className="action-buttons-wrapper">
          <Row gutter={ 16 } type="flex" justify="center">
            <Col>
              <Button
                type="primary"
                onClick={ () => downloadFile(fileUrl, filename) }
                icon="download"
              >
                Download
              </Button>
            </Col>
            { (item.type === 'image' || item.type === 'pdf') && (
              <Col>
                <Button
                  type="primary"
                  onClick={ () => printFile(fileUrl) }
                  icon="printer"
                >
                  Print
                </Button>
              </Col>
            ) }
          </Row>
        </div>
      );
    };

    // Switch statement to handle different item types
    switch (item.type) {
      case 'pdf':
        return (
          <div style={ COMMON_STYLE }>
            <Row>
              <Col span={ 24 }>
                <div onClick={ (e) => e.preventDefault() }>
                  { renderCapturedDate() }
                  <PDFViewer file={ item.fileUrl } isView={ true } />
                </div>
              </Col>
              <Col span={ 24 }>
                <Row type='flex' justify='center' gutter={ 16 }>
                  { showDeleteButton ? (
                    <Col>{ renderPDFExcelButtons(item.fileUrl, true) }</Col>
                  ) : (
                    <Col>{ renderDownloadButton(item.fileUrl, item.fileUrl.startsWith('blob:'), showPrintButton, item) }</Col>
                  ) }
                </Row>
              </Col>
            </Row>
          </div>
        );

      case 'csv':
      case 'excel':
        const icon = item.type === 'csv' ? csvIcon : excelIcon;
        return (
          <div style={ COMMON_STYLE }>
            { renderCapturedDate() }
            <img src={ icon } alt="file" style={ { height: "50%", width: "50%", objectFit: "cover" } } />
            {showDeleteButton ? renderPDFExcelButtons(item.fileUrl, true) : renderDownloadButton(item.fileUrl, true)}
          </div>
        );

      case 'video':
        return (
          <div style={ COMMON_STYLE } onClick={ (e) => e.preventDefault() }>
            { renderCapturedDate() }
            { renderErrorAlert() }
            <video
              src={ item.fileUrl }
              style={ VIDEO_STYLE }
              controls
              onError={ (e) => {
                setErrorStatus(prevStatus => ({ ...prevStatus, [ item.fileUrl ]: true }));
                e.target.src = sign;
              } }
            />
            {showDeleteButton ? renderPDFExcelButtons(item.fileUrl, true) : renderDownloadButton(item.fileUrl, true)}
          </div>
        );

      case 'image':
        return (
          <div style={ COMMON_STYLE }>
            { renderCapturedDate() }
            { renderErrorAlert() }
            <img
              src={ `${item.imageUrl}?` }
              alt="file"
              style={ IMAGE_STYLE }
              onError={ (e) => {
                setErrorStatus(prevStatus => ({ ...prevStatus, [ item.imageUrl ]: true }));
                e.target.src = sign;
              } }
            />
            <Row type='flex' justify='center' gutter={ 16 }>
              { showDeleteButton ? <Col >{ renderDeleteButton() }</Col> : null }
            </Row>
            {renderActionButtons(item)}
          </div>
        );

      default:
        return (
          <div style={ COMMON_STYLE }>
            <Result
              title="This file can't be viewed. Please download the file to view it."
              extra={ renderDownloadButton(item.fileUrl) }
            />
          </div>
        );
    }
  };



  const onThumbnailClick = (event, index) => {
    const newFileName = items[index].originalTitle
    setCurrentImageName(newFileName);
  };

  const onSlide = (currentIndex) => {
    const newFileName = items[currentIndex].originalTitle;
    setCurrentImageName(newFileName);
  };

  const goToSlide = (index) => {
    if (galleryRef.current) {
      galleryRef.current.slideToIndex(index);
    }
  };

  return (
    <div className="mixed-gallery" style={{ width: "100%", height: "100%" }}>
      <Gallery
        items={items}
        ref={ galleryRef }
        renderItem={renderItem}
        showPlayButton={false}
        showFullscreenButton={false}
        showBullets={true}
        showThumbnails={true}
        onThumbnailClick={onThumbnailClick}
        onSlide={onSlide}
        infinite={true}
        slideDuration={500}
        slideInterval={3000}
        startIndex={startIndex}
        showIndex
      />
    </div>
  );
};

export default MixedGallery;


MixedGallery.propTypes = {
  handlePrint: PropTypes.func,
  closeComponent: PropTypes.func,
  showPrintButton: PropTypes.bool,
}

MixedGallery.defaultProps = {
  closeComponent: () => {},
  showPrintButton: false,
  handlePrint: () => { },
}
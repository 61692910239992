import React, { createContext, useState, useEffect, useContext } from 'react';
import _, { isEmpty } from 'lodash';
import { UserContext } from './UserContext';
import { fetchCreditLimitExceededAccounts, fetchOrganizationConfigs } from '../api/Organisations';
import { AppLoadingScreen } from '../components/common/LogisticsLoadingScreen';
import { glCodeApi } from '../api/GLCodeApi';
import { fetchOrgAccounts } from '../api/Account';
import { fetchColorTags } from '../api/ColorTags';

const OrgContext = createContext({});

const formatOrgConfig = (orgConfigs) => {
  const formattedOrgConfigs = {};
  orgConfigs.forEach(item => {
    formattedOrgConfigs[ item.setting_name ] = item.setting_value;
  });
  return formattedOrgConfigs;
};

const OrgContextProvider = (props) => {
  const { children } = props;
  const [ orgSettings, setOrgSettings ] = useState({});
  const [ isLoading, setisLoading ] = useState(true);
  const { currentOrg, currentUser } = useContext(UserContext);
  const [accountsExceeded, setAccountsExceeded] = useState([]);
  const [ endUserName, setEndUserName ] = useState('Consignee');
  const [ glCodesList, setGlCodesList ] = useState([]);
  const [ accounts, setAccounts ] = useState([]);
  const [dispatchStatusColors, setDispatchStatusColors] = useState({});

  const fetchDispatchStatusColorData = async () => {
    setisLoading(true);
    const orgId = currentOrg.id;
    fetchColorTags(orgId).then((res) => {
      if(res.success){
        const modifiedData = res.data.color_tags.map((tag) => ({
          label: tag.name,
          code: tag.name.toLowerCase().replace(/\s+/g, '_') ,
          color: tag.color_code,
          id: tag.id
        }))
        setDispatchStatusColors(modifiedData)
      }else{
        setDispatchStatusColors([])
        setisLoading(false);
      }
    }).finally(() => {
      setisLoading(false);
    })
  };


  const fetchOrgData = async () => {
    setisLoading(true);
    const orgId = currentOrg.id;
    if(currentOrg.carrier_type === 'AIR_CARGO'){
      setEndUserName('Shipper')
    }
    const Configresult = await fetchOrganizationConfigs(orgId, false);
    if (Configresult.success) {
      setOrgSettings(formatOrgConfig(Configresult.org_configurations));
    }
    await fetchCreditData();
    await fetchGLCodesData();
    await fetchAccounts();
    await fetchDispatchStatusColorData();
    setisLoading(false);
  };

  const fetchCreditData = async () => {
    const orgId = currentOrg.id;
    const warehouseIds = (_.get(currentUser, 'active_warehouse_ids', []) || []).join(',');
    const exceededAccnts = await fetchCreditLimitExceededAccounts(orgId, warehouseIds);
    if (exceededAccnts.success) {
      setAccountsExceeded(exceededAccnts.accounts);
    }
  }

  const fetchAccounts = async () => {
    const orgId = currentOrg.id;
    const result = await fetchOrgAccounts(orgId);
    if (result.success) {
      setAccounts(result.accounts);
    }
  };

  const fetchGLCodesData = async () => {
    const orgId = currentOrg.id;
    const result = await glCodeApi.fetch(orgId);
    if (result.success) {
      setGlCodesList(result.data || []);
    }
  }

  const isAirCargo = () => {
    return currentOrg.carrier_type === 'AIR_CARGO';
  }

  useEffect(() => {
    const isSuperAdmin = currentUser && currentUser?.roles && currentUser?.roles?.includes('super_admin');
    if (isSuperAdmin) {
      setisLoading(false);
    } else {
      fetchOrgData();
    }
  }, []);

  const contextValues = {
    orgSettings,
    isLoading,
    setOrgSettings,
    currentOrg,
    accountsExceeded,
    fetchCreditData,
    endUserName,
    isAirCargo,
    setGlCodesList,
    glCodesList,
    accounts,
    dispatchStatusColors,
    setDispatchStatusColors,
  };

  return (
    <OrgContext.Provider value={ contextValues }>
      { isLoading ? <AppLoadingScreen /> : children }
    </OrgContext.Provider>
  );
};

export { OrgContext, OrgContextProvider };
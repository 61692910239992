import { Table } from "antd";
import React from "react";
import AppConfig from "../../config/AppConfig";
import moment_tz from 'moment-timezone';


const WMSList = (props) => {
  const columns = [
    {
      title: 'Comments',
      key: "comments",
      dataIndex: 'comment',
      render: (data) => <span>{ data }</span>,
    },
    {
      title: 'Captured At',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (data) => (
        data ? <span>
          { moment_tz.utc(data).tz(props.order_tz_short_name).format(AppConfig.dateTimeFormat) }
          { ` ${props.order_tz_short_name}` }
        </span> : 'NA'
      )
    },
    {
      title: 'Captured By',
      key: "createdBy",
      dataIndex: 'createdBy',
      render: (data) => <span>{ data ? data : '-' }</span>,
    },
  ];

  return (
    <div>
      <h4 className="primaryText textBold fontSize18 paddingTop10">Warehouse Comments</h4>
      <Table columns={ columns } size="large" dataSource={ props.data || [] } pagination={ { position: "none" } } />
    </div>
  );
};
export default WMSList;
import driver from "../../assets/images/people.png";
import vtype from "../../assets/images/truck-1.png";
import speedometer from "../../assets/images/speedometer.png";
import payment from "../../assets/images/pay (1).png";
import chequeMenu from "../../assets/images/chequeMenu.png";
import reportMenu from "../../assets/images/reportMenu.png"; 
import  capacity from "../../assets/images/storage.png";
import report from "../../assets/images/report.png";
import collectionReport from "../../assets/images/Vector.png";
import income from "../../assets/images/income.png";
import I18n from "./I18n";
import AppConfig from "../config/AppConfig";
import { fetchToken } from "../api/UsersApi";
import { renderAlertMessage } from "../helpers/common";
import InvoiceStatusMenu from "../../assets/images/invoiceStatusMenu.png";
import dunningReportMenu from "../../assets/images/dunningReportMenu.png"
import dock from "../../assets/images/dock.png"
import qbReportMenu from "../../assets/images/qbReportMenu.png"
import ImportIcon from "../../assets/images/download.png"
import barCode from "../../assets/images/barcode.png"
import cutOff from "../../assets/images/cut.png"
import charted from "../../assets/images/chart-tree.svg"
import divisions from "../../assets/images/divisions.png"

const [
  {
    OrderAccessCodes,
    preplanAccessCodes,
    BillingAccessCodes,
    AccountReceivableAccessCodes,
    BillingReportAccessCodes,
    DispatchReportAccessCodes,
    AccountMenuAccessCodes,
    LocationsMenuAccessCodes,
    OrgSettingsMenuAccessCodes,
    InsightsMenuAccessCodes,
    AIConverseCodes
  },
] = AppConfig.AccessCodes;

const redirectToDashboard = async () => {
  const token_details = JSON.parse(window.localStorage.getItem('auth') || '{}');
  const token = _.get(token_details, "access_token", "") || "";
  if(token){
    fetchToken().then((result) => {
      if (result.success) {
        const insigntsUrl = result.data.url+`${token}`
        window.open(insigntsUrl, "_blank");
      } else {
        renderAlertMessage(result.errors);
      }
    });
  }else{
    window.location.href= "/";
  }
 
};

export const superAdminMenuItems = [
  {
    key: "/users",
    link: "/users",
    icon: "user",
    text: I18n.t("menu.users"),
    className: "nav-text",
  },
  {
    key: "/organizations",
    link: "/organizations",
    icon: "bank",
    text: I18n.t("menu.organizations"),
    className: "nav-text",
  },
  {
    key: "/locations",
    link: "/locations",
    icon: "home",
    text: I18n.t("menu.locations"),
    className: "nav-text",
  },
  {
    key: "/search_files",
    link: "/search_files",
    icon: "file-search",
    text: I18n.t("searchFiles.title"),
    className: "nav-text",
  },
  {
    key: "/route_tracking",
    link: "/route_tracking",
    icon: "customer-service",
    text: I18n.t("menu.route_track"),
    className: "nav-text",
  },
  {
    key: "/driver_monthly_report",
    link: "/driver_monthly_report",
    icon: driver,
    text: I18n.t("driver_monthly_report.title"),
    className: "nav-text-for-custom-menu",
    style: { height: "20px", width: "20px", cursor: "pointer" },
  },
  {
    key: "/packages",
    link: "/packages",
    icon: "retweet",
    text: I18n.t("menu.packages"),
    className: "nav-text",
  },
  {
    key: "settings",
    link: "",
    icon: "setting",
    text: I18n.t("menu.settings_in_superadmin"),
    className: "nav-text",
    subMenuItems: [
      {
        key: "/vehicletype_mapping",
        link: "/vehicletype_mapping",
        icon: vtype,
        text: I18n.t("menu.vehicle_type_mapping"),
        className: "nav-text",
        style: { height: "28px", width: "28px", cursor: "pointer" },
      },
      {
        key: "/lable_settings",
        link: "/lable_settings",
        icon: "barcode",
        text: I18n.t("menu.lable_settings"),
        className: "nav-text",
      },
      {
        key: "/mapping_settings",
        link: "/mapping_settings",
        icon: "radius-setting",
        text: I18n.t("menu.mappingSetting"),
        className: "nav-text",
      },
      {
        key: "/account_settings",
        link: "/account_settings",
        icon: "contacts",
        text: I18n.t("menu.update_setting"),
        className: "nav-text",
      },
      {
        key: "/integrations",
        link: "/integrations",
        icon: "interaction",
        text: I18n.t("menu.integrations"),
        className: "nav-text",
      },
      {
        key: "/carrier_integration",
        link: "/carrier_integration",
        icon: "setting",
        text: I18n.t("menu.carrier_integration"),
        className: "nav-text",
      },
      {
        key: "/activities",
        link: "/activities",
        icon: "bars",
        text: I18n.t("activity.menu"),
        className: "nav-text",
      },
      {
        key: "/order_status_code",
        link: "/order_status_code",
        icon: "check-circle",
        text: I18n.t("OrderStatus.menu"),
        className: "nav-text",
      },
      {
        key: "/mobile_configuration",
        link: "/mobile_configuration",
        icon: "mobile",
        text: I18n.t("MobileConfiguration.menu"),
        className: "nav-text",
      },
      {
        key: "/set_order_lat_lng",
        link: "/set_order_lat_lng",
        icon: "environment",
        text: I18n.t("menu.order_Lat_Lon_Setting"),
        className: "nav-text",
      },
      {
        key: "templetes",
        link: "",
        icon: "setting",
        text: "Templates",
        className: "nav-text",
        subMenuItems: [
          {
            key: "/template_params",
            link: "/template_params",
            icon: "read",
            text: "Params",
            className: "nav-text",
          },
          {
            key: "/event_params",
            link: "/event_params",
            icon: "read",
            text: "Events",
            className: "nav-text",
          },
        ],
      },
    ],
  },
];

export const adminMenuItems = [
  {
    dashBoardMenuItems: [
      {
        key: "/dashboard",
        link: "/dashboard",
        icon: "dashboard",
        text: I18n.t("menu.dashboard"),
        className: "nav-text",
        accessCodes: ["NRLOGS", "UI", "COSTATS", "NRDS"],
      },
    ],
    orderMenuItems: [
      {
        key: "order_management_menu",
        link: "",
        icon: "order",
        text: I18n.t("menu.orders_management"),
        className: "nav-text",
        accessCodes: OrderAccessCodes,
        pathAccessCodes: ["COI", "OPSOS"],
        path: "/orders",
        subMenuItems: [
          {
            key: "/pending_orders",
            link: "/pending_orders",
            icon: "pending",
            text: I18n.t("menu.pending_orders"),
            className: "nav-text",
            accessCodes: ["COI", "OPSPO"],
          },
          {
            key: "/orders",
            link: "/orders",
            icon: "order",
            text: I18n.t("menu.orders"),
            className: "nav-text",
            accessCodes: ["COI", "OPSOS"],
          },
          // {
          //   key: "/orders-beta",
          //   link: "/orders-beta",
          //   icon: "order",
          //   text: I18n.t("menu.orders"),
          //   className: "nav-text",
          //   accessCodes: ["COI", "OPSOS"],
          //   badge: "Beta",
          // },
          {
            key: "/upload",
            link: "/upload",
            icon: "upload",
            text: I18n.t("menu.upload_orders"),
            className: "nav-text",
            accessCodes: ["OPSUOS"],
          },
          {
            key: "/quotes",
            link: "/quotes",
            icon: "quotes",
            text: I18n.t("menu.quotes"),
            className: "nav-text",
            accessCodes: ["QI", "OPSQOS"],
          },
          {
            key: "/recovery",
            link: "/recovery",
            icon: "recovery",
            text: I18n.t("menu.recovery"),
            className: "nav-text",
            accessCodes: ["ORI", "OPSROS"],
          },
          {
            key: "/releases",
            link: "/releases",
            icon: "release",
            text: I18n.t("menu.releases"),
            className: "nav-text",
            accessCodes: ["OREI", "OPSRLOS"],
          },
          {
            key: "/linehaul",
            link: "/linehaul",
            icon: "linehaul",
            text: "Linehaul",
            className: "nav-text",
            accessCodes: ["OPSLHOS"],
          },
          {
            key: "/templates",
            link: "/templates",
            icon: "repeat",
            text: I18n.t("menu.recurring_operations"),
            className: "nav-text",
            accessCodes: ["ORI", "OPSTOS"],
          },
          {
            key: "/deleted-orders",
            link: "/deleted-orders",
            icon: "delete",
            text: I18n.t("menu.deleted_orders"),
            className: "nav-text",
            accessCodes: ["COI", "OPSOS"],
          },
        ],
      },
    ],
    preplanMenuItems: [
      {
        key: "preplan_management_menu",
        link: "",
        icon: "preplan",
        text: I18n.t("menu.preplan_dispatch"),
        className: "nav-text",
        accessCodes: preplanAccessCodes,
        pathAccessCodes : ["OPSPPS"],
        path: "/preplan_management",
        subMenuItems: [
          // {
          //   key: "/preplan_management-beta",
          //   link: "/preplan_management-beta",
          //   icon: "bulb",
          //   text: I18n.t("menu.preplanroutes"),
          //   className: "nav-text",
          //   accessCodes: ["OPSPPS"],
          //   badge: "Beta",
          // },
          {
            key: "/preplan_management",
            link: "/preplan_management",
            icon: "preplan",
            text: I18n.t("menu.preplanroutes"),
            className: "nav-text",
            accessCodes: ["OPSPPS"],
          },
          // {
          //   key: "/preplan_management",
          //   link: "/preplan_management",
          //   icon: "preplan",
          //   text: I18n.t("menu.preplanroutes"),
          //   className: "nav-text",
          //   accessCodes: ["OPSPPS"],
          //   badge: "Beta",
          // },
          {
            key: "/routes",
            link: "/routes",
            icon: "dispatch",
            text: I18n.t("menu.routes"),
            className: "nav-text",
            accessCodes: ["OPSDS"],
          },
        ],
      },
    ],
    BillingMenuItems: [
      {
        key: "billing_management_menu",
        link: "",
        icon: "dollar",
        text: I18n.t("menu.billing_management"),
        className: "nav-text",
        accessCodes: BillingAccessCodes,
        pathAccessCodes: ["OPSBLS"],
        path: "/billing",
        subMenuItems: [
          {
            key: "/billing",
            link: "/billing",
            icon: "dollar",
            text: I18n.t("menu.billing"),
            className: "nav-text",
            accessCodes: ["OPSBLS"],
          },
          {
            key: "/external_billing",
            link: "/external_billing",
            icon: "dollar",
            text: I18n.t("menu.external_billing"),
            className: "nav-text",
            accessCodes: ["OPSBLS"],
          },
          {
            key: "/invoices",
            link: "/invoices",
            icon: "invoice",
            text: I18n.t("menu.invoices"),
            className: "nav-text",
            accessCodes: ["OPSINS"],
          },
          // {
          //   key: "/invoices-beta",
          //   link: "/invoices-beta",
          //   icon: "file-pdf",
          //   text: I18n.t("menu.invoices"),
          //   className: "nav-text",
          //   accessCodes: ["OPSINS"],
          //   badge: "Beta",
          // },
          {
            key: "/monthly_cutoff",
            link: "/monthly_cutoff",
            icon: cutOff,
            text: I18n.t("menu.monthly_cutoff"),
            style: { height: "20px", width: "20px", cursor: "pointer" },
            className: "nav-text",
            accessCodes: ["OPSBLS"],
          },
          {
            key: "/credit_limit",
            link: "/credit_limit",
            icon: speedometer,
            text: I18n.t("menu.credit_limit"),
            className: "file nav-text",
            style: {
              height: "18px",
              width: "18px",
              cursor: "pointer",
              marginRight: "12px",
              marginLeft: "4px",
            },
            accessCodes: ["OPSCLS"],
          },
        ],
      },
    ],
    AccountReceivableMenuItems: [
      {
        key: "ar_menu",
        icon: "getcash",
        text: I18n.t("menu.account_receivables"),
        className: "nav-text",
        accessCodes: AccountReceivableAccessCodes,
        pathAccessCodes: ["OPSARS"],
        path: "/account_receivables",
        subMenuItems: [
          {
            key: "/batches",
            link: "/batches",
            icon: "batch",
            text: I18n.t("menu.batch_management"),
            className: "nav-text",
            style: { height: "20px", width: "20px", cursor: "pointer" },
            accessCodes: ["OPSBS"],
          },
          {
            key: "/manage_checks",
            link: "/manage_checks",
            icon: "checkbook",
            text: I18n.t("menu.manage_checks"),
            className: "nav-text",
            style: { height: "20px", width: "20px", cursor: "pointer" },
            accessCodes: ["OPSMCS"],
            onClick: () => localStorage.removeItem("lastPath"),
          },
          {
            key: "/account_receivables",
            link: "/account_receivables",
            icon: "getcash",
            className: "file-pdf nav-text",
            text: I18n.t("menu.account_receivables"),
            accessCodes: ["OPSARS"],
            onClick: () => localStorage.removeItem("rootPath"),
          },
          {
            key: "/open_invoices",
            link: "/open_invoices",
            icon: "invoice",
            className: "nav-text",
            text: I18n.t("menu.open_invoices"),
            accessCodes: ["OPSOIS"],
          },
          {
            key: "/credit_memo",
            link: "/credit_memo",
            icon: "creditmemo",
            text: I18n.t("credit_notes.label"),
            className: "file nav-text",
            accessCodes: ["OPSCNS"],
          },
        ],
      },
    ],
    BillingReportMenuItems: [
      {
        key: "report_menu",
        link: "",
        icon: "reconciliation",
        text: I18n.t("general.reports"),
        className: "nav-text",
        accessCodes: BillingReportAccessCodes,
        pathAccessCodes: ["OPSAGRS"],
        path: "/aging_report",
        subMenuItems: [
          {
            key: "/aging_report",
            link: "/aging_report",
            icon: "aging",
            text: I18n.t("aging.menu"),
            className: "nav-text",
            style: { height: "15px", width: "16px", cursor: "pointer" },
            onClick: () => localStorage.removeItem("lastPath"),
            accessCodes: ["OPSAGRS"],
          },
          // {
          //   key: "/collection_report",
          //   link: "/collection_report",
          //   icon: collectionReport,
          //   text: I18n.t("reports.collection_report"),
          //   className: "nav-text",
          //   style: { height: "18px", width: "18px", cursor: "pointer" },
          //   accessCodes: ["OPSCR"],
          // },
          {
            key: "/open_balance",
            link: "/open_balance",
            icon: "transaction",
            text: I18n.t("menu.open_bal"),
            className: "nav-text",
            style: { height: "15px", width: "16px", cursor: "pointer" },
            accessCodes: ["OPSOBLS"],
          },
          {
            key: "/payment_journal",
            link: "/payment_journal",
            icon: "paymentjournal",
            text: I18n.t("menu.payment_journal"),
            className: "nav-text",
            style: { height: "18px", width: "18px", cursor: "pointer" },
            accessCodes: ["OPSPJS"],
          },
          {
            key: "/invoice_status_report",
            link: "/invoice_status_report",
            icon: "invoice-status",
            text: I18n.t("invoice_status_report.menu"),
            className: "nav-text",
            style: { height: "18px", width: "18px", cursor: "pointer" },
            accessCodes: ["OPSISR"],
          },
          {
            key: "/gl_code_report",
            link: "/gl_code_report",
            icon: "glcode",
            text: "GL Code Report",
            className: "nav-text",
            accessCodes: ["OPSGLC"],
            style: { height: "20px", width: "20px", cursor: "pointer" },
          },
          {
            key: "/dunning_notice",
            link: "/dunning_notice",
            icon: "dunning",
            text: I18n.t("dunning_notice.menu"),
            className: "nav-text",
            style: { height: "18px", width: "18px", cursor: "pointer" },
            accessCodes: ["OPSDN"],
          },
          {
            key: "/qb_report",
            link: "/qb_report",
            icon: "invoice-fusion",
            text: I18n.t("quickbook_report.menu"),
            className: "nav-text",
            style: { height: "18px", width: "18px", cursor: "pointer" },
            accessCodes: ["OPSQB"],
          }
        ],
      },
    ],
    DispatchReportMenuItems: [
      {
        key: "dispatch_report_menu",
        link: "",
        icon: "line-chart",
        text: I18n.t("general.reports_dispatch"),
        className: "nav-text",
        accessCodes: DispatchReportAccessCodes,
        pathAccessCodes: ["OPSSRS"],
        path: "/status_report",
        subMenuItems: [
          {
            key: "/status_report",
            link: "/status_report",
            icon: "edi",
            text: "EDI Status Report",
            className: "nav-text",
            accessCodes: ["OPSSRS"],
          },
          {
            key: "/customer_log_report",
            link: "/customer_log_report",
            icon: "customer-report",
            text: I18n.t("customer_log_report.menu"),
            className: "nav-text",
            accessCodes: ["OPSCLRS"],
          },
          {
            key: "/driver_log_report",
            link: "/driver_log_report",
            icon: "driver-report",
            text: I18n.t("driver_log_report.menu"),
            className: "nav-text",
            accessCodes: ["OPSDLRS"],
          },
          {
            key: "/capacity_report",
            link: "/capacity_report",
            icon: "gantt-chart",
            text: I18n.t("capacity_report.title_short"),
            className: "nav-text",
            accessCodes: ["OPSCR"],
            style: { height: "20px", width: "20px", cursor: "pointer" },
          },
          {
            key: "/pod_report",
            link: "/pod_report",
            icon: "pod",
            text: I18n.t("pod_report.title"),
            className: "nav-text",
            accessCodes: ["PODRSR"],
            style: { height: "20px", width: "20px", cursor: "pointer" },
          },
        ],
      },
    ],
    AccountMenuItems: [
      {
        key: "account_menu",
        link: "",
        icon: "account",
        text: I18n.t("menu.account"),
        className: "nav-text",
        accessCodes: AccountMenuAccessCodes,
        pathAccessCodes: ["AI", "OPSAS"],
        path: "/accounts",
        subMenuItems: [
          {
            key: "/accounts",
            link: "/accounts",
            icon: "account",
            text: I18n.t("menu.account"),
            className: "nav-text",
            accessCodes: ["AI", "OPSAS"],
          },
          {
            key: "/forms",
            link: "/forms",
            icon: "form",
            text: I18n.t("menu.forms"),
            className: "nav-text",
            accessCodes: ["FI", "OPSFS"],
          },
          {
            key: "/surveys",
            link: "/surveys",
            icon: "survey",
            text: I18n.t("menu.surveys"),
            className: "nav-text",
            accessCodes: ["SRVYI", "OPSSS"],
          }
        ],
      },
    ],
    LocationsMenuItems: [
      {
        key: "locations_menu",
        icon: "location",
        text: I18n.t("menu.location"),
        className: "nav-text",
        accessCodes: LocationsMenuAccessCodes,
        pathAccessCodes: ["WHI", "OPSLS"],
        path: "/locations",
        subMenuItems: [
          {
            key: "/locations",
            link: "/locations",
            icon: "warehouse",
            text: I18n.t("menu.locations"),
            className: "nav-text",
            accessCodes: ["WHI", "OPSLS"],
          },
          {
            key: "/predefined_stops",
            link: "/predefined_stops",
            icon: "location",
            text: I18n.t("menu.predefined_stops"),
            className: "nav-text",
            accessCodes: ["PDSI", "OPSPDSS"],
          },
        ],
      },
    ],
    OrgSettingsMenuItems: [
      {
        key: "org_configuration",
        link: "",
        icon: "setting",
        text: I18n.t("menu.org_config"),
        className: "nav-text",
        accessCodes: OrgSettingsMenuAccessCodes,
        pathAccessCodes: ["ORGCON", "OPSOCS"],
        path: "/configurations",
        subMenuItems: [
          {
            key: "/configurations",
            link: "/configurations",
            icon: "setting",
            text: I18n.t("menu.org_settings"),
            className: "nav-text",
            accessCodes: ["ORGCON", "OPSOCS"],
          },
          {
            key: "/users",
            link: "/users",
            icon: "user",
            text: I18n.t("menu.users"),
            className: "nav-text",
            accessCodes: ["OPSUS"],
          },
          {
            key: "/vehicle_types",
            link: "/vehicle_types",
            icon: "vtype",
            text: I18n.t("menu.vehicle_type"),
            className: "nav-text",
            style: { height: "28px", width: "28px", cursor: "pointer" },
            accessCodes: ["OPSVTS"],
          },
          {
            key: "/drivers",
            link: "/drivers",
            icon: "driver",
            text: I18n.t("menu.drivers"),
            className: "nav-text",
            accessCodes: ["UI", "OPSDVS"],
          },
          {
            key: "/trucks",
            link: "/trucks",
            icon: "truck",
            text: I18n.t("menu.trucks"),
            className: "nav-text",
            accessCodes: ["FLTI", "OPSTS"],
          },
          {
            key: "/roles",
            link: "/roles",
            icon: "role",
            text: I18n.t("menu.roles"),
            className: "nav-text",
            accessCodes: ["OPSRS"],
            RoleAccess : 'admin'
          },
          {
            key: "/agent",
            link: "/agent",
            icon: "agent",
            className: "nav-text",
            text: "Agent",
          },
          {
            key: "/charted_accounts",
            link: "/charted_accounts",
            icon: "iif-generation",
            text: I18n.t("charted_Accounts.label"),
            className: "nav-text",
            style: { height: "16px", width: "15px", cursor: "pointer", marginRight: "15px", marginLeft: "5px" },
          }
        ],
      },
    ],
    DmsMenuItems: [
      {
        key: "/document_management_system",
        link: "/document_management_system",
        icon: "pie-chart",
        className: "nav-text",
        text: I18n.t("dms.title"),
        accessCodes: ["OPSDMS"],
      },
    ],

    DockScanMenuItems : [
      {
        key:"dock_scan_menu",
        link:"",
        icon: dock,
        text: I18n.t("dock_scan_report.og"),
        className: "nav-text-for-custom-menu",
        style: { height: "20px", width: "20px", cursor: "pointer" },
        path: "/dock_scan_report",
        subMenuItems: [
          {
            key: "/dock_scan_report",
            link: "/dock_scan_report",
            icon: ImportIcon,
            text: I18n.t("dock_scan_report.upload"),
            className: "nav-text",
            style: { height: "20px", width: "20px", cursor: "pointer" },
          },
          {
            key: "/dock_scan_management",
            link: "/dock_scan_management",
            icon: barCode,
            text: I18n.t("dock_scan_report.title"),
            className: "nav-text",
            style: { height: "20px", width: "20px", cursor: "pointer" },
          }
        ]
      }

    ],
    InsightsMenuItems: [
      {
        key: "insights_menu",
        icon: "insights",
        text: I18n.t("menu.intelligence"),
        className: "nav-text textBold textPrimary",
        accessCodes: InsightsMenuAccessCodes,
        onClick: redirectToDashboard,
      },
    ],
    AIConverse: [
      {
        key: "ai_converse",
        icon: "chat",
        text: I18n.t("menu.ai_converse"),
        className: "nav-text textBold textPrimary",
        accessCodes: AIConverseCodes,
        onClick: () => typeof window.toggleChatBot === "function"  ?  window.toggleChatBot() : console.warn("toggleChatBot function is not available"),
      },
    ],
  },
];
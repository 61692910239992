import React, { Fragment, Component } from "react";
import _ from "lodash";
import BaseList from "../BaseList";
import {isEmpty, doFormate } from "../../common/Common";
import I18n from "../../common/I18n";
import { Input, Tooltip, Icon, Popconfirm, Row, Col } from "../../common/UIComponents";
import { formatDimension } from "../../helpers/orders";
import AppConfig from "../../config/AppConfig";
import { checkServiceExistance, getFormattedWeight, getWeightUnits } from "../../helpers/common";
import EditIcon from "../common/EditIcon";
import DeleteIcon from "../common/DeleteIcon";
import { displayWeightUnits } from "./helpers";

class ItemsForm extends BaseList {
  constructor(props) {
    super(props);
    this.state = {
      locationData: props.locationData?.locations ? props.locationData.locations : []
    }
    const columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => index + 1,
      },
      {
        key: "stops",
        title: "Stops",
        dataIndex: "stops",
        render: (data, record) => {
          const pickUpLocation = this.state.locationData.find(
            (location) => location.id === record.pick_up_location_id
          );
          const dropLocation = this.state.locationData.find(
            (location) => location.id === record.drop_location_id
          );

          const locOrderSequence =
            pickUpLocation?.loc_order_sequence ?? dropLocation?.loc_order_sequence;

          return !this.props.isVisible ? (
            <Input
              value={locOrderSequence}
              size="small"
              onChange={(e) =>
                this.props.onChange(record.stops, "stops", e.target.value)
              }
            />
          ) : (
            <Fragment>{`Stop${locOrderSequence + 1}`}</Fragment>
          );
        },
      },
      {
        key: "item_name",
        title: I18n.t("general.name"),
        dataIndex: "item_name",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(record.item_id, "item_name", e.target.value)
              }
            />
          ) : (
            <Fragment>{data}</Fragment>
          ),
      },
      {
        key: "item_model",
        title: I18n.t("order.model_no"),
        dataIndex: "item_model",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "item_model",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>{data}</Fragment>
          ),
      },
      {
        key: "serial_number",
        title: I18n.t("order.serial_no"),
        dataIndex: "serial_number",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "serial_number",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>{data}</Fragment>
          ),
      },
      {
        key: "quantity",
        title: I18n.t("order.pieces"),
        dataIndex: "item_quantity",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "item_quantity",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>{data}</Fragment>
          ),
        align: "center",
      },
      {
        key: "weight",
        title: I18n.t("order.weight"),
        dataIndex: "item_weight",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              type="number"
              min="0"
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "item_weight",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>
              <div>
                  <div>{!isEmpty(data) && data.toFixed(
                    Number(
                      _.get(
                        this.props.userContext,
                        "orgRules.org_billing_rules.round_off_decimals",
                        "2"
                      )
                    )
                  )} lbs</div>
                <div style={{ color: "#999999" }}>
                  {" "}
                  {!isEmpty(record.item_weight_in_kgs) &&
                    record.item_weight_in_kgs.toFixed(
                      Number(
                        _.get(
                          this.props.userContext,
                          "orgRules.org_billing_rules.round_off_decimals",
                          "2"
                        )
                      )
                    ) + " kgs"}{" "}
                </div>
              </div>

                {/* {!isEmpty(data) ? displayWeightUnits(data, this.props.accountUnitContext.accountWeightUnits, this.props.account.code, true) : ""} */}
            </Fragment>
          ),
      },
      {
        key: "pallets",
        title: I18n.t("order.pallets"),
        dataIndex: "pallets",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              type="number"
              min="0"
              value={data || 0}
              size="small" 
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "pallets",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>{data || 0}</Fragment>
          ),
        align: "center",
      },

      {
        key: "weightType",
        title: "Weight Type",
        dataIndex: "is_indv_weight",
        render: (data, record) => {
          const weightType = record.is_indv_weight;
          if (weightType !== null && weightType !== undefined) {
            return !this.props.isVisible ? (
              <Input
                value={data}
                size="small"
                onChange={(e) =>
                  this.props.onChange(
                    record.item_id,
                    "weight_type",
                    e.target.value
                  )
                }
              />
            ) : (
              <Fragment>
                {data === 0 ? 'Complete' : 'Individual'}
              </Fragment>
            );
          } else {
            return "";
          }
        },
      },
      {
        key: "total_weight",
        title: `Total ${I18n.t("order.weight")}`,
        dataIndex: "total_weight",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              type="number"
              min="0"
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "item_weight",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>
              <div>
                  <div>{!isEmpty(data) && data.toFixed(
                    Number(
                      _.get(
                        props.userContext,
                        "orgRules.org_billing_rules.round_off_decimals",
                        "2"
                      )
                    )
                  )} lbs</div>
                <div style={{ color: "#999999" }}>
                    {!isEmpty(record.total_weight_in_kgs) &&
                      record.total_weight_in_kgs.toFixed(
                      Number(
                        _.get(
                          props.userContext,
                          "orgRules.org_billing_rules.round_off_decimals",
                          "2"
                        )
                      )
                    ) + " kgs"}{" "}
                </div>
              </div>
              {/* {!isEmpty(data) ? getFormattedWeight(data, record.total_weight_in_kgs) : 0} */}
            </Fragment>
          ),
      },

    ];
    if (this.props.showItemType !== false) {
      columns.push({
        key: "item_type",
        title: I18n.t("order.item_type"),
        dataIndex: "item_type",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              value={data}
              size="small"
              disabled
              onChange={(e) =>
                this.props.onChange(record.item_id, "item_type", e.target.value)
              }
            />
          ) : (
            <Fragment>{data}</Fragment>
          ),
      });
    }
    columns.push({
      key: "wh_dock",
      title: I18n.t("order.wh_dock"),
      dataIndex: "wh_dock",
      render: (data, record) =>
        !this.props.isVisible ? (
          <Input
            value={data}
            size="small"
            disabled
            onChange={(e) =>
              this.props.onChange(record.wh_dock, "wh_dock", e.target.value)
            }
          />
        ) : (
          <Fragment>{doFormate(data)}</Fragment>
        ),
    });

    if (this.props.showDimensions !== false) {
      columns.push({
        key: "dimensions",
        title: (
          <div>
            {I18n.t("order.dimensions")}&nbsp;(<small>L * W * H</small>)
          </div>
        ),
        dataIndex: "item_width",
        render: (data, record) =>
          !this.props.isVisible ? (
            <Input
              type="number"
              min="0"
              value={data}
              size="small"
              onChange={(e) =>
                this.props.onChange(
                  record.item_id,
                  "item_width",
                  e.target.value
                )
              }
            />
          ) : (
            <Fragment>
              {formatDimension(
                record.item_length,
                record.item_length_uom,
                record.item_width,
                record.item_width_uom,
                record.item_height,
                record.item_height_uom
              )}
            </Fragment>
          ),
      });
      columns.push(
        {
          key: "dim_factor",
          title: <div>{I18n.t("configurations.dim_factor")}</div>,
          dataIndex: "dim_factor",
          render: (data, record) =>
            !this.props.isVisible ? (
              <Input
                type="number"
                min="0"
                value={data}
                size="small"
                onChange={(e) =>
                  this.props.onChange(
                    record.item_id,
                    "dim_factor",
                    e.target.value
                  )
                }
              />
            ) : (
              <Fragment>{data}</Fragment>
            ),
          align: "center",
        },
        {
          key: "dim_weight",
          title: () => {
            return <div>
              {`${I18n.t("configurations.dim_weight")}`}
            </div>
          },
          dataIndex: "dim_weight",
          render: (data, record) =>
            !this.props.isVisible ? (
              <Input
                type="number"
                min="0"
                value={data}
                size="small"
                onChange={(e) =>
                  this.props.onChange(
                    record.item_id,
                    "dim_weight",
                    e.target.value
                  )
                }
              />
            ) : (
              <Fragment>{data}</Fragment>
            ),
          align: "center",
        }
      );
    }

    if(props.checkForRemark === "true"){
      columns.push({
        key: "items_remark",
        title: <div>{props.itemRemarkLabel}</div>,
        dataIndex : "item_remarks"
      })
    }
  
    const renderInfoIcon = (record) => {
      if (record.item_picture_data?.some(item => _.get(item, "exception_code") !== "")) {
        return (
          <Tooltip title={I18n.t("order.item_failure")} placement="top">
            <Icon type="info-circle" className="info-icon" onClick={() => { this.props.handleModalView(record) }} />
          </Tooltip>
        );
      }
      return <div/>;
    };
    
    const renderEditIcon = (id) => {
      if (checkServiceExistance("UCOI") && !props.showException) {
        return (
          <Tooltip placement="topLeft" title="Edit">
            <EditIcon handleClick={() => this.props.editClick(id)} />
          </Tooltip>
        );
      }
      return <div/>;
    };
    
    const renderDeleteIcon = (id) => {
      if (checkServiceExistance("DCOI") && !props.showException) {
        return (
          <Popconfirm
            placement="topRight"
            title={I18n.t("messages.delete_confirm")}
            onConfirm={() => this.props.deleteClick(id)}
            okText="Yes"
            cancelText="No"
          >
            {" "}
            <DeleteIcon />
            &nbsp;&nbsp;&nbsp;
          </Popconfirm>
        );
      }
      return <div/>;
    };
    
    const renderActionButtons = (id, record) => {
      const collectedIconsJsx = [
        renderInfoIcon(record),
        renderEditIcon(id),
        renderDeleteIcon(id)
      ];
    
      const totalSpan = 24;
      const spanPerIcon = totalSpan / collectedIconsJsx.length;
    
      return (
        <Row gutter={8} type="flex" justify="start">
          {collectedIconsJsx.map((icon, index) => (
            <Col span={spanPerIcon} key={index}>
              {icon}
            </Col>
          ))}
        </Row>
      );
    };
    
    if (this.props.showActions !== false) {
      columns.push({
        key: "actions",
        dataIndex: "item_id",
        render: (id, record) => renderActionButtons(id, record),
      });
    }
    this.columns = !this.props.showStopColumn ? columns.filter(col => col.key !== "stops") : [...columns]
  }

  componentDidUpdate(prevProps) {
    // Reconstruct columns if relevant props change
    if (
      prevProps.account?.code !== this.props.account?.code ||
      prevProps.accountUnitContext?.accountWeightUnits !== this.props.accountUnitContext?.accountWeightUnits ||
      prevProps.showItemType !== this.props.showItemType ||
      prevProps.showDimensions !== this.props.showDimensions ||
      prevProps.checkForRemark !== this.props.checkForRemark ||
      prevProps.showActions !== this.props.showActions
    ) {
      // Re-run constructor logic to rebuild columns
      this.constructor.call(this, this.props);
    }
  }
}

ItemsForm.defaultProps = {
  showException: false,
  accountUnitContext: {},
  account: {},
};

export default ItemsForm;

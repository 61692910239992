export const base64ToBlob = (base64, type) => {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return new Blob([bytes], { type: type });
  };

  export const handleDownload = async (url, filename) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };


  export const handlePrint = async (url) => {
    try { 
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      return new Promise((resolve) => {
        iframe.onload = () => {
          try {
            const printWindow = iframe.contentWindow;

            // Add event listener for after print
            printWindow.addEventListener('afterprint', () => {
              document.body.removeChild(iframe);
              resolve();
            });

            // Trigger print
            printWindow.print();

            // Fallback timeout
            setTimeout(() => {
              if (document.body.contains(iframe)) {
                document.body.removeChild(iframe);
                resolve();
              }
            }, 10000);
          } catch (error) {
            console.error('Print failed:', error);
            document.body.removeChild(iframe);
            resolve();
          }
        };

        iframe.src = url;
      });
    } catch (error) {
      console.error('Print setup failed:', error);
    }
  };

export const downloadFile = async (url, filename) => {
  try {
    if (url.startsWith('blob:')) {
      // Handle blob URLs
      const link = document.createElement('a');
      link.href = url;
      link.download = filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      // Handle regular URLs
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = filename || 'download';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    }
  } catch (error) {
    console.error('Download failed:', error);
  }
};

export const printFile = async (url) => {
  return new Promise((resolve, reject) => {
    try {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      iframe.onload = () => {
        try {
          // For PDF files in blob URLs
          if (url.startsWith('blob:')) {
            const printWindow = iframe.contentWindow;
            printWindow.addEventListener('afterprint', () => {
              document.body.removeChild(iframe);
              resolve();
            });

            printWindow.print();

            // Fallback if afterprint event is not supported
            setTimeout(() => {
              if (document.body.contains(iframe)) {
                document.body.removeChild(iframe);
                resolve();
              }
            }, 10000); // 10 second fallback
          } else {
            // For regular URLs
            iframe.contentWindow.print();
            setTimeout(() => {
              document.body.removeChild(iframe);
              resolve();
            }, 1000);
          }
        } catch (error) {
          console.error('Print failed:', error);
          document.body.removeChild(iframe);
          reject(error);
        }
      };

      // Set iframe source
      if (url.startsWith('blob:')) {
        iframe.src = url;
      } else if (url.match(/\.(jpg|jpeg|png|gif)$/i)) {
        iframe.srcdoc = `
          <html>
            <head>
              <style>
                body { margin: 0; display: flex; justify-content: center; align-items: center; }
                img { max-width: 100%; max-height: 100vh; object-fit: contain; }
              </style>
            </head>
            <body>
              <img src="${url}" />
            </body>
          </html>
        `;
      } else {
        iframe.src = url;
      }
    } catch (error) {
      console.error('Print setup failed:', error);
      reject(error);
    }
  });
};
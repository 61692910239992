import { ApiUrl, Call, ParseGeneralResponse } from "./ApiUtils"

export const fetchColorTags = async (id) => {
    const url  = ApiUrl(`v2/color_tags?organization_id=${id}`)
    return Call('get', url, {}, ParseGeneralResponse, {
        responseDataKeys: { data: '' },
    })
}

export const createColorTag = async (data) => {
    const url  = ApiUrl('v2/color_tags')
    return Call('POST', url, data, ParseGeneralResponse, {
        responseDataKeys: { data: '' },
    })
}

export const updateColorTag = async (id, data) => {
    const url  = ApiUrl(`v2/color_tags/${id}`)
    return Call('PUT', url, data, ParseGeneralResponse, {
        responseDataKeys: { data: '' },
    })
}

export const deleteColorTag = async (id) => {
    const url  = ApiUrl(`v2/color_tags/${id}`)
    return Call('DELETE', url, {}, ParseGeneralResponse, {
        responseDataKeys: { data: '' },
    })
}

export const bulkOrderColorTagsChange = async (data) => {
    const url = ApiUrl("v2/nav_routes/bulk_update_color_tags")
    return Call('PUT', url, data, ParseGeneralResponse, {
        responseDataKeys : {data: ""}
    })
}
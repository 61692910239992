/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _ from 'lodash'
import I18n from '../common/I18n';

export const defaultPreferences = [
  {
    s_no: 1,
    item_preference: '',
    item_option: [],
    has_expedite: false,
  },
  // {
  //   s_no: 2,
  //   item_preference: '',
  //   item_option: [],
  // }, {
  //   s_no: 3,
  //   item_preference: '',
  //   item_option: [],
  // }
]

export const pickupLocation = {
  type_of_loc: 'PICKUP',
  orderItems: [],
  preferences: [
    {
      s_no: 1,
      item_preference: '',
      item_option: [],
      has_expedite: false,
    },
  ]
};

export const dropLocation = {
  type_of_loc: 'DELIVERY',
  orderItems: [],
  preferences: [
    {
      s_no: 1,
      item_preference: '',
      item_option: [],
      has_expedite: false,
    },
  ]
};

export const requiredFields = [
  {
    form_field: 'customer_first_name',
    ui_name: I18n.t('general.first_name'),
    isRequired: false,
    inputType: 'text',
  },
  {
    form_field: 'customer_last_name',
    ui_name: I18n.t('general.last_name'),
    isRequired: false,
    inputType: 'text',
  },
  {
    form_field: 'customer_email',
    ui_name: I18n.t('general.email'),
    isRequired: false,
    inputType: 'email',
  },
]

export const mobileFields = [
  {
    form_field: 'customer_phone_one',
    ui_name: `${I18n.t('general.contact_no')} 1`,
    isRequired: true,
    inputType: 'mobile',
  },
  {
    form_field: 'customer_phone_two',
    ui_name: `${I18n.t('general.contact_no')} 2`,
    isRequired: false,
    inputType: 'mobile',
  },
]

export const orderRequiredFields = [
  // {
  //   form_field: "customer_order_number",
  //   ui_name: I18n.t("order.order_no"),
  //   isRequired: true,
  //   inputType: "text",
  // },
  {
    form_field: 'weight_in_kgs',
    ui_name: I18n.t('order.weight_in_kgs'),
    isRequired: false,
    inputType: 'price',
  },
  {
    form_field: 'weight',
    ui_name: I18n.t('order.weight'),
    isRequired: true,
    inputType: 'price',
  },
  {
    form_field: 'warehouse_code',
    ui_name: I18n.t('location.location'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'account_code',
    ui_name: I18n.t('general.account'),
    isRequired: true,
    inputType: 'account_code',
  },
  {
    form_field: 'los',
    ui_name: I18n.t('los.label'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'type_of_order',
    ui_name: I18n.t('order.type'),
    isRequired: true,
    inputType: 'text',
  },
]

export const dispatchFields = [
  {
    form_field: 'driver_id',
    ui_name: I18n.t('general.Driver'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'fleet_id',
    ui_name: I18n.t('fleet.label'),
    isRequired: true,
    inputType: 'text',
  },
  {
    form_field: 'trailer_number',
    ui_name: I18n.t('fleet.trailer_no'),
    isRequired: true,
    inputType: 'text',
  }
]

export const defaultDropParams = {
    type_of_order: 'D',
    orderItems: [],
    is_location_based: false,
    preferences: [
    {
        s_no: 1,
        item_preference: '',
        item_option: [],
        has_expedite: false,
    },
    ],
}

/* eslint-disable import/prefer-default-export */
import React, { Fragment } from 'react'
import _ from 'lodash'
import moment from 'moment'
import {  ellipseText, isEmpty, sqInchesToSqFeet } from '../common/Common'
import I18n from '../common/I18n'
import AppConfig from '../config/AppConfig'
import { formatByTimeConfig, transformKeysToMoment } from './common'
import { momentTime } from './date_functions'
import { defaultPreferences } from '../constants/orders'
import { arrangeOrderByIdSeq, findDependencyOrders } from './preplan'
import { Row, Col, Icon } from '../common/UIComponents'
import AddressInfo from '../components/common/AddressInfo'

const commonFirelds = ["customer_first_name", "customer_last_name", "customer_phone_one", "customer_phone_two", "customer_email", "cs_location", "preferences"];

export const getOrderAppointment = (appointments = []) => {
  let appointmentConfirmDate = null
  let appointmentSchedule = ''
  let aptSlots = []
  if (appointments && _.isArray(appointments) && appointments.length > 0) {
    appointmentConfirmDate = _.find(
      appointments,
      { confirmed: true },
      'appt_date',
    )
  }
  if (
    !isEmpty(appointmentConfirmDate) &&
    appointmentConfirmDate.slots &&
    _.isArray(appointmentConfirmDate.slots)
  ) {
    aptSlots = appointmentConfirmDate.slots.map((slot) => {
      if (slot === 'M') return `(${I18n.t('appointmentForm.morning_time')})`
      if (slot === 'E') return `(${I18n.t('appointmentForm.afternoon_time')})`
      if (slot === 'ANY') return `(${I18n.t('appointmentForm.any_time')})`
    })
    appointmentSchedule = appointmentConfirmDate
      ? `${moment(appointmentConfirmDate.appt_date).format(
          'MMM DD YYYY',
        )} ${aptSlots.join(', ')}`
      : ''
  }
  return appointmentSchedule
}

export const findServicesTotal = (itemAccessorials = [], accessorials) => {
  let total = 0
  const masterAccessorials = !isEmpty(accessorials) ? accessorials : []
  const targetAccessorials = !isEmpty(itemAccessorials) ? itemAccessorials : []
  targetAccessorials.forEach((accessorial) => {
    const accessorialIndex = _.findIndex(masterAccessorials, [
      'org_accessorial_id',
      accessorial.org_accessorial_id,
    ])
    if (accessorialIndex >= 0) {
      total += !isEmpty(masterAccessorials[accessorialIndex].amount)
        ? masterAccessorials[accessorialIndex].amount
        : 0
    }
  })
  return total
}

export const accessorialValue = (accessorials, id, keyValue, element) => {
  if (accessorials && _.isArray(accessorials)) {
    const record = _.find(accessorials, { [id]: keyValue })
    if (!isEmpty(record) && _.isObject(record)) {
      return record[element]
    }
    return ''
  }
}

export const formatDimension = (
  len = null,
  lengthUOM = '',
  width = null,
  widthUOM = '',
  height = null,
  heightUOM = '',
  showUnits = true,
  requireShortForm = false,
) => {
  if (isEmpty(len) && isEmpty(width) && isEmpty(height)) {
    return ''
  }
  let formattedStr = ''
  if (isEmpty(len)) {
    formattedStr = 'NA'
  } else {
    formattedStr = `${len + (lengthUOM ? ` ${lengthUOM.toLowerCase()}` : '')}`
  }
  if (isEmpty(width)) {
    formattedStr += ' * NA'
  } else {
    formattedStr += ` * ${
      width + (widthUOM ? ` ${widthUOM.toLowerCase()}` : '')
    }`
  }
  if (isEmpty(height)) {
    formattedStr += ' * NA'
  } else {
    formattedStr += ` * ${
      height + (heightUOM ? ` ${heightUOM.toLowerCase()}` : '')
    }`
  }
  if (isEmpty(lengthUOM) && isEmpty(widthUOM) && isEmpty(heightUOM)) {
    if (requireShortForm) {
      formattedStr += ` ( Units : ${AppConfig.dimension_unit_short_form} )`
    } else {
      formattedStr += ` ( Units : ${AppConfig.dimension_unit} )`
    }
  }
  return formattedStr
}

export const formatWeight = (weight, showDefault = true, defaultValue = "") => {
  return `${
    weight ? `${weight} ${AppConfig.weight_unit}` : `${showDefault ? (!isEmpty(defaultValue) ? defaultValue : 'NA') : ''}`
  }`
}

export const formatVolume = (weight, showDefault = true) => {
  return `${
    weight
      ? `${weight} ${AppConfig.dimension_unit_short_form}`
      : `${showDefault ? 'NA' : ''}`
  }`
}

export const formatSurfaceArea = (sa, showDefault = true) => {
  const surfaceArea =
    !isEmpty(sa) && sa !== 'NA' && sa !== 0
      ? sqInchesToSqFeet(sa)
      : showDefault
      ? 'NA'
      : ''
  return (
    <Fragment>
      {surfaceArea}
      {!isEmpty(sa) && surfaceArea !== 'NA' ? (
        <Fragment>
          &nbsp;ft<sup>2</sup>
        </Fragment>
      ) : (
        ''
      )}
    </Fragment>
  )
}

export const getStartTime = (data) => {
  if (_.isArray(data) && data.length > 0) {
    const appointments = _.sortBy(data, ['confirmed']).reverse()
    if (appointments.length > 0) {
      return appointments[0].start_datetime
        ? appointments[0].start_datetime
        : null
    }
  }
  return null
}

export const getSortingKey = (data) => {
  if (_.isArray(data) && data.length > 0) {
    const appointments = _.sortBy(data, ['confirmed']).reverse()
    if (appointments.length > 0) {
      return appointments[0].slot_name &&
        I18n.t(`appointmentForm.sorting.${appointments[0].slot_name}`)
        ? I18n.t(`appointmentForm.sorting.${appointments[0].slot_name}`)
        : I18n.t('appointmentForm.sorting.ANY')
    }
  }
  return I18n.t('appointmentForm.sorting.ANY')
}

const getOrderPrefix = (orderType) => {
  switch(orderType) {
    case 'R':
    case 'TR':
      return 'P'; // Pickup
    case 'D':
    case 'TD':
      return 'D'; // Delivery
    default:
      return '';
  }
};

const formatTimeRange = (start, end, isMilitaryTime, hideDate) => {
  if (!start) return '';
  
  const startFormat = hideDate ? 'HH:mm' : 'Do MMM  HH:mm';
  const startFormatAMPM = hideDate ? 'hh:mm A' : 'Do MMM  hh:mm A';
  const endFormat = 'HH:mm';
  const endFormatAMPM = 'hh:mm A';

  const formattedStart = formatByTimeConfig(
    moment.utc(start),
    isMilitaryTime,
    startFormat,
    startFormatAMPM
  );

  if (!end) return formattedStart;

  const formattedEnd = formatByTimeConfig(
    moment.utc(end),
    isMilitaryTime,
    endFormat,
    endFormatAMPM
  );

  return (
    <span className="appointment-time">
      <span className="date-time">{formattedStart}</span>
      <span className="time-separator"> - </span>
      <span className="end-time">{formattedEnd}</span>
    </span>
  );
};

const formatTransportAppointment = (appointment, isMilitaryTime, hideDate) => {
  const { start_datetime, end_datetime, type_of_loc } = appointment;
  const prefix = type_of_loc === 'PICKUP' ? 'P' : 'D';
  const timeRange = formatTimeRange(start_datetime, end_datetime, isMilitaryTime, hideDate);
  
  return (
    <span className="transport-appointment">
      {type_of_loc && (
        <span className={`location-prefix ${type_of_loc.toLowerCase()}`}>{prefix}</span>
      )}
      {timeRange}
    </span>
  );
};

export const getAppointmentTime = (
  data,
  organizationSettings = {},
  hideAppointmentdate = false,
  orderType = ""
) => {
  const isMilitaryTime = organizationSettings.is_military_time === 'true';
  const appointments = [...data];

  // For transport orders (T and LH types)
  if (['T', 'LH'].includes(orderType)) {
    const sortedAppointments = appointments
      .sort((a, b) => a.type_of_loc === 'PICKUP' ? -1 : 1)
      .map(appointment => formatTransportAppointment(
        appointment, 
        isMilitaryTime, 
        hideAppointmentdate
      ));

    return (
      <Fragment>
        {sortedAppointments.map((appointment, index) => (
          <Fragment key={index}>
            {appointment}
            {index < sortedAppointments.length - 1 && <span className="appointment-separator">, </span>}
          </Fragment>
        ))}
      </Fragment>
    );
  }

  // For other order types
  const confirmedAppointment = appointments.find(apt => apt.confirmed) || 
    _.sortBy(appointments, ['start_datetime'])[0];

  if (!confirmedAppointment) return '';

  const prefix = getOrderPrefix(orderType);
  const timeRange = formatTimeRange(
    confirmedAppointment.start_datetime,
    confirmedAppointment.end_datetime,
    isMilitaryTime,
    hideAppointmentdate
  );

  return (
    <span className="transport-appointment">
      {prefix && (
        <span className={`location-prefix ${prefix === 'P' ? 'pickup' : 'delivery'}`}>
          {prefix}:&nbsp;
        </span>
      )}
      {timeRange}
    </span>
  );
};

export const getAppointmentTimeFromSlots = (
  data = [],
  field = 'all',
  formatStr = AppConfig.dateTimeFormat,
  whSlots
) => {
  data = _.compact(data);
  const apptIndex = data.length > 0 ? 0 : -1
  _.findIndex(data, ['confirmed', true])
  if (apptIndex >= 0) {
    const appointment = data[apptIndex]
    const slots = appointment.slots || []
    if (slots.length > 0) {
      const currentSlot = slots[0]
      if (currentSlot === 'CUSTOM' || currentSlot === 'ANY') {
        return `${
          appointment[field]
            ? `${moment.utc(appointment[field], formatStr).format(formatStr)}`
            : ''
        }`
      } else {
        const timeSlots = whSlots.find(slot => slot.start_time === slots[ 0 ].split("-")[ 0 ] && slot.end_time === slots[ 0 ].split("-")[ 1 ]);
        return `${
          timeSlots && timeSlots[field]
            ? `${moment.utc(timeSlots[field], formatStr).format(formatStr)}`
            : ''
        }`
      }
    }
    return ''
  }
}

export const getScheduledTime = (
  data = [],
  field = 'all',
  formatStr = 'DD MMM  hh:mm A',
  timeZoneId,
) => {
  // let apptIndex = _.findIndex(data, ['confirmed', true])
  data = _.compact(data);
  let apptIndex = data?.length > 0 ? 0 : -1;
  const appointments = [...data]
  if (apptIndex >= 0) {
    if (field === 'all') {
      return `${
        appointments[apptIndex].start_datetime
          ? momentTime(
              formatStr,
              appointments[apptIndex].start_datetime,
              timeZoneId,
            )
          : ''
      } ${
        appointments[apptIndex].end_datetime
          ? ` - ${momentTime(
              formatStr,
              appointments[apptIndex].end_datetime,
              timeZoneId,
            )}`
          : ''
      }`
    } else if (!isEmpty(field)) {
      return `${
        appointments[apptIndex][field]
          ? momentTime(formatStr, appointments[apptIndex][field], timeZoneId)
          : ''
      }`
    }
    return ''
  }
}

export const checkEndTime = (
  currentPreference,
  value,
  maxEndHour = 20,
  startMaxEndHour = 18,
) => {
  const preference = Object.create(currentPreference)
  const currentValue = value.clone()
  const startHour = moment(currentValue).hour()
  let endTime = !isEmpty(preference.end_time) ? preference.end_time : null
  if (!isEmpty(preference.end_time)) {
    const isAfter = currentValue.isAfter(preference.end_time)
    if (startHour >= startMaxEndHour) {
      endTime = moment(preference.end_time)
        .set('hour', maxEndHour)
        .set('minute', 0)
    } else if (isAfter) {
      endTime = currentValue.add(120, 'minutes')
    }
  } else if (startHour >= startMaxEndHour) {
    // preference.end_time = moment(value).add(120, 'minutes');
    endTime = moment(currentValue).set('hour', maxEndHour).set('minute', 0)
  } else {
    endTime = currentValue.add(120, 'minutes')
  }
  return endTime
}

export const setAccessorialResponse = (
  response = [],
  checkExistance = false,
  allowedIds = {},
) => {
  let accessorials = [...response]
  if (checkExistance === true) {
    accessorials = response.filter(
      (accessorial) => !isEmpty(allowedIds[accessorial.account_accessorial_id]),
    )
    // allowedIds.includes(accessorial.account_accessorial_id)
  }
  const orderAccessorials = []
  accessorials.forEach((accessorial) => {
    const accessorialObject = {
      account_accessorial_id: accessorial.account_accessorial_id,
      accessorial_code: accessorial.accessorial_code,
      accessorial_type: accessorial.accessorial_type || '',
      standard_code: accessorial.standard_code || '',
      driver_pay_params: accessorial.driver_pay_params || null,
      component_params: [],
      authorized_by: accessorial.authorized_by,
      authorization_code: accessorial.authorization_code,
      comments: accessorial.comments,
      created_by: accessorial.created_by,
      created_at: accessorial.created_at,

    }
    // const accIndex = _.findIndex(orderAccessorials, [
    //   "account_accessorial_id",
    //   accessorial.account_accessorial_id,
    // ]);
    // if (accIndex >= 0) {
    //   orderAccessorials[accIndex].component_params.push(
    //     accessorial.component_params
    //   );
    // } else {
    //   orderAccessorials.push(
    //     Object.assign({}, accessorial, {
    //       component_params: [accessorial.component_params],
    //     })
    //   );
    // }
    const componentParams = []
    const componentParamsArray = accessorial.component_params || [];
    componentParamsArray.forEach((param) => {
      if (
        allowedIds[accessorial.account_accessorial_id] &&
        allowedIds[accessorial.account_accessorial_id].includes(
          param.component_code,
        )
      ) {
        const currentParam = { ...param }
        const componentIndex = _.findIndex(componentParams, [
          'component_code',
          param.component_code,
        ])
        delete currentParam.component_code
        if (componentIndex >= 0) {
          componentParams[componentIndex].component_values.push(currentParam)
        } else {
          const paramObject = {
            component_code: param.component_code,
            component_values: [currentParam],
          }
          componentParams.push(paramObject)
        }
      }
    })
    accessorialObject.component_params = [...componentParams]
    orderAccessorials.push(accessorialObject)
  })
  return orderAccessorials
}
export const setTemplateAccessorialObject = (
  response = [],
  checkExistance = false,
  allowedIds = {},
) => {
  let accessorials = [...response]
  if (checkExistance === true) {
    accessorials = response.filter(
      (accessorial) => !isEmpty(allowedIds[accessorial.account_accessorial_id]),
    )
    // allowedIds.includes(accessorial.account_accessorial_id)
  }
  const orderAccessorials = []
  accessorials.forEach((accessorial) => {
    const accessorialObject = {
      account_accessorial_id: accessorial.account_accessorial_id,
      accessorial_code: accessorial.accessorial_code,
      accessorial_type: accessorial.accessorial_type || '',
      standard_code: accessorial.standard_code || '',
      driver_pay_params: accessorial.driver_pay_params || null,
      component_params: [],
      authorized_by: accessorial.authorized_by,
      authorization_code: accessorial.authorization_code,
      comments: accessorial.comments,
    }
    // const accIndex = _.findIndex(orderAccessorials, [
    //   "account_accessorial_id",
    //   accessorial.account_accessorial_id,
    // ]);
    // if (accIndex >= 0) {
    //   orderAccessorials[accIndex].component_params.push(
    //     accessorial.component_params
    //   );
    // } else {
    //   orderAccessorials.push(
    //     Object.assign({}, accessorial, {
    //       component_params: [accessorial.component_params],
    //     })
    //   );
    // }
    const componentParams = []
    accessorial.component_params.forEach((param) => {
      if (
        allowedIds[accessorial.account_accessorial_id] &&
        allowedIds[accessorial.account_accessorial_id].includes(
          param.component_code,
        )
      ) {
        const currentParam = { ...param }
        const componentIndex = _.findIndex(componentParams, [
          'component_code',
          param.component_code,
        ])
        delete currentParam.component_code
        if (componentIndex >= 0) {
          componentParams[componentIndex].component_values.push(...currentParam.component_values)
        } else {
          const paramObject = {
            component_code: param.component_code,
            component_values: [...currentParam.component_values],
          }
          componentParams.push(paramObject)
        }
      }
    })
    accessorialObject.component_params = [...componentParams]
    orderAccessorials.push(accessorialObject)
  })
  return orderAccessorials
}

export const getOrderStatus = (orderType) => {
  return ['TR', 'TD'].includes(orderType)
    ? AppConfig.orderTypes['M'].label
    : AppConfig.orderTypes[orderType]
    ? AppConfig.orderTypes[orderType].label
    : 'NA'
}

export const getOrderTypeLabel = (order, key, dependencyKey) => {
  if (!isEmpty(order) && _.isObject(order) && !isEmpty(order[key])) {
    if (!isEmpty(order[dependencyKey]) && ['R', 'D'].includes(order[key])) {
      return AppConfig.orderTypeShortForms[`M${order[key]}`]
    }
    return AppConfig.orderTypeShortForms[order[key]]
  }
  return ''
}

export const isOptedExpedite = (appointments = []) => {
  if (!isEmpty(appointments) && appointments.length > 0) {
    const hasExpedite =
      appointments.filter((preference) => preference.has_expedite === true)
        .length > 0
    return hasExpedite
  }
  return false
}

export const getDisabledHoursFromStartTime = (
  startTime,
  endTime,
  isStartHours,
) => {
  let hours = []
  if (!isEmpty(startTime) || !isEmpty(endTime)) {
    if (!isEmpty(startTime)) {
      const startHour = moment(startTime, 'HH:mm').hour()
      //if (isStartHours) {
      for (let i = 0; i < startHour; i += 1) {
        hours.push(i)
      }
      // } else {
      //   for (let i = 0; i <= startHour; i += 1) {
      //     hours.push(i)
      //   }
      // }
    }
    if (!isEmpty(endTime)) {
      const endHour = moment(endTime, 'HH:mm').hour()
      if (isStartHours) {
        for (let i = endHour; i < 24; i += 1) {
          hours.push(i)
        }
      } else {
        for (let i = endHour + 1; i < 24; i += 1) {
          hours.push(i)
        }
      }
    }
  } else {
    if (isStartHours) {
      hours = [...AppConfig.dispatcherDisabledStartHours]
    } else {
      hours = [...AppConfig.dispatcherDisabledEndHours]
    }
  }

  return hours
}

export const checkElementValueExists = (data, key) => {
  if (_.isArray(data) && data.length > 0 && !isEmpty(key)) {
    return data.filter((rec) => !isEmpty(rec[key])).length > 0
  }
  return false
}

export const createLocationsFromOrder = (currentOrder, type_of_order) => {
  if(type_of_order && currentOrder){
    const location = {
      cs_location: {...currentOrder.cs_location},
      order_items: currentOrder.order_items ? [...currentOrder.order_items] : [],
      pictures: currentOrder.order_items ? currentOrder.order_items : [],
      invoices: currentOrder.invoices ? currentOrder.invoices : [],
      service_duration: currentOrder.service_duration,
      los: currentOrder.los,
      type_of_loc: ['TR', 'R'].includes(type_of_order) ? 'PICKUP' : 'DELIVERY',
    }
    return location;
  }
  return null
}

const formLocObject = (loc = {}, appendFullDetails = true, appointments = false) => {
  let obj = {
    customer_first_name: loc.first_name,
    customer_last_name: loc.last_name,
    customer_phone_one: loc.phone,
    customer_phone_two: loc.phone_2,
    customer_email: loc.email,
    cs_location: { l_address: loc.l_address},
    service_duration: loc.service_duration,
  };
  if(loc.id){
    obj.id = loc.id;
  }
  if(appendFullDetails) return ({...obj, ...loc})
  if(appointments) return ({...obj, ...loc.preferences})
  return {...obj};
}

export const formLocationFromOrder = (currentOrder, isMilitaryTime = false) => {
  if(currentOrder.locations){
    const decodeTimeFormat = !isMilitaryTime ? 'HH:mm' : 'hh:mm A';
    const locations = currentOrder.locations.map(loc => {
      const details = formLocObject(loc);
      const defaultApptValues = {
        slots: [],
        item_preference: null,
        confirmed: false,
        start_time: null,
        end_time: null,
        has_expedite: false,
      };
      
      let transformedData = transformKeysToMoment(loc.appointments, { dateKeys: [ 'appt_date' ], timeKeys: [ 'start_time', 'end_time' ], dateFormat: 'YYYY-MM-DD', timeFormat: decodeTimeFormat, isMilitaryTime });
      // modify transformedData to add default values
      const modifiedTransformedData = transformedData.map((appt) => {
        return { ...defaultApptValues, ...appt, has_expedite: loc.has_expedite };
      });
      loc.appointments = modifiedTransformedData;
      const location = {
        ...details,
        order_items: loc.order_items ? [...loc.order_items] : [],
        pictures: loc.pictures ? loc.pictures : [],
        service_duration: loc.service_duration,
        los: loc.los,
        type_of_loc: loc.type_of_loc,
        orderItems: [],
        appointments: loc.appointments ? [...loc.appointments] : [],
        // preferences: [...defaultPreferences],
        feasible_dow: [],
        feasible_dates: [],
        is_location_based: true,
      }
      return location;
    })
    return locations;
  }
  return []
}

const getLocationTime = (appt_date, appt_time) => {
  return `${appt_date} ${appt_time}`;
}

export const locationDetails = (currentOrder) => {
  if(currentOrder.locations){
    const orderLocations = _.sortBy(currentOrder.locations, 'loc_order_sequence');
    const locations = orderLocations.map(loc => {
      const details = formLocObject(loc);
      const firstAppointment = loc.appointments && loc.appointments ? loc.appointments[0] : null;
      const location = {
        ...details,
        ...loc,
        order_items: loc.order_items ? [...loc.order_items] : [],
        pictures: loc.pictures ? loc.pictures : [],
        service_duration: loc.service_duration,
        los: loc.los,
        type_of_loc: loc.type_of_loc,
        scheduled_start_datetime: firstAppointment ? getLocationTime(firstAppointment.appt_date, firstAppointment.start_time) : null,
        scheduled_end_datetime: firstAppointment ? getLocationTime(firstAppointment.appt_date, firstAppointment.end_time) : null,
        suggested_start_datetime: loc.suggested_start_datetime,
        suggested_end_datetime: loc.suggested_end_datetime,
        window_start_time: loc.window_start_time,
        window_end_time: loc.window_end_time,
        actual_start_datetime: loc.actual_start_datetime,
        actual_end_datetime: loc.actual_end_datetime,
        appointments: loc.appointments ? loc.appointments : [],
        order_tz_short_name: currentOrder.order_tz_short_name ? currentOrder.order_tz_short_name : null,
        is_location_based: currentOrder.is_location_based === true
      }
      return location;
    })
    return locations;
  }
  return []
}

export const isOrderHasLocation = (currentOrder, locationIndex) => {
 return currentOrder && currentOrder.is_location_based && locationIndex >= 0 && currentOrder.locations && currentOrder.locations[locationIndex];
}

export const retrueveLocationFromOrder = (currentOrder, locationIndex) => {
  const hasLocation = isOrderHasLocation(currentOrder, locationIndex)
  if(hasLocation){
    return currentOrder.locations[locationIndex];
  }
  return {};
}

export const retrieveDataObject = (currentOrder, locationIndex) => {
  const hasLocation = isOrderHasLocation(currentOrder, locationIndex)
  if(hasLocation){
    return currentOrder.locations[locationIndex];
  }else {
    return currentOrder;
  }
}

export const formAppointments = (customerPreferences, preferences = [], has_expedite = false) => {
   customerPreferences.forEach((preferenceObj, index) => {
   const preferenceIndex = _.findIndex(preferences, ['s_no', index + 1])
    if (preferenceIndex >= 0) {
      const slots = preferenceObj.slots || []
      preferences[preferenceIndex].item_preference = preferenceObj.appt_date
      preferences[preferenceIndex].item_option = slots
      preferences[preferenceIndex].confirmed =
        preferenceObj.confirmed || false
      preferences[preferenceIndex].start_time =
        slots.includes('CUSTOM') && !isEmpty(preferenceObj.start_time)
          ? moment(
              `${preferenceObj.appt_date} ${preferenceObj.start_time}`,
              'YYYY-MM-DD HH:mm',
            )
          : null
      preferences[preferenceIndex].end_time =
        slots.includes('CUSTOM') && !isEmpty(preferenceObj.end_time)
          ? moment(
              `${preferenceObj.appt_date} ${preferenceObj.end_time}`,
              'YYYY-MM-DD HH:mm',
            )
          : null
      // preferences[preferenceIndex].has_expedite= preferenceObj.has_expedite;
      preferences[preferenceIndex].has_expedite = has_expedite;
    }
  })
  return preferences;  
}

export const pickPickupNDelivery = (formData, param) => { 
  if(formData.is_location_based) {
    const locations = formData.locations ? formData.locations : [];
    const location = _.find(locations, {type_of_loc: AppConfig.locToOrder[param]});
    return (location ? _.pick(location, commonFirelds) : {});
  }
  return {};
}

export const getAppointmentTimeFromPreferences = (
  data = [],
  dateField,
  field = 'all',
  formatStr = AppConfig.dateTimeFormat,
) => {
  const apptIndex = data.length > 0 ? 0 : -1
  if (apptIndex >= 0) {
    const appointment = data[apptIndex]
    const slots = appointment['slots'] || []
    if (slots.length > 0) {
      const currentSlot = slots[0]
      const dateValue =  appointment[dateField] ? moment.utc(appointment[dateField]).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
      if(!isEmpty(dateValue)){
        if (currentSlot === 'CUSTOM') {
          return `${
            appointment[field]
              ? moment.utc(`${dateValue} ${moment(appointment[field]).format('HH:mm')}`, "YYYY-MM-DD HH:mm")
              : null
          }`
        } else {
          const timeSlots = AppConfig.preferences[currentSlot] || {}
          return `${
            timeSlots[field]
              ? moment.utc(`${dateValue} ${timeSlots[field]}`, "YYYY-MM-DD HH:mm")
              : null
          }`
        }
      }
    }
    return ''
  }
}

export const findAppointmentErros = (locations) => {
  const locs = locations.length > 0 ? [...locations] : [];
  const errors = [];
  if (locs.length > 1) {
    let step = 0;
    locs.forEach((loc, index) => {
      const appointments = loc.appointments || [];
      step = index;
      const firstSartTime = getAppointmentTimeFromPreferences(appointments, "appt_date", "start_time");
      if (!isEmpty(firstSartTime)) {
        locs.filter((remLoc, remIndex) => remIndex > index).forEach((remLoc, remIndex) => {
          step = step + 1;
          const secAppointments = remLoc.appointments || [];
          const secondSartTime = getAppointmentTimeFromPreferences(secAppointments, "appt_date", "start_time");
          if (!isEmpty(secondSartTime)) {
            const isBefore = moment(secondSartTime).isBefore(firstSartTime);
            if (isBefore) {
              errors.push(`Stop ${index + 1} should be before stop ${step + 1}`);
            }
          } else {
            errors.push(`Stop ${step + 1} should be valid`);
          }
        })
      } else {
        errors.push(`Stop ${index + 1} should be valid`);
      }
    });
 }
 return _.uniq(errors);
}

export const renderDriverNotes = (notes = "") => {
  if (_.isArray(notes)) {
    return notes.length > 0 ? notes.map((note) => note.notes).join('; ') : 'NA';
  } else if (!isEmpty(notes)) {
    return notes
  }
  return 'NA'
}

export const getLocationIds = (ids = [], records = [], filesToRetrieve = '', fieldType = 'array') => {
  if(fieldType === 'string'){
    // const location_ids = _.uniq(_.compact(_.flatten(records.filter(rec => ids.includes(rec.id)).map(rec => rec[filesToRetrieve]))));
    // return location_ids;
    const recs = records.filter(rec => ids.includes(rec.id))
    const sortedRecs = arrangeOrderByIdSeq(recs, ids);
    const location_ids = _.uniq(_.compact(_.flatten(sortedRecs.map(rec => rec[filesToRetrieve]))))
    return location_ids;
  }else if(fieldType === 'array'){
    const recs = records.filter(rec => ids.includes(rec.id) && rec[filesToRetrieve] && rec[filesToRetrieve].length > 0);
    const sortedRecs = arrangeOrderByIdSeq(recs, ids);
    const finalValues = sortedRecs.reduce(
      (accumulator, currentValue) => {
        const loc_ids = currentValue[filesToRetrieve].map(loc => loc.id)
        return accumulator.concat(loc_ids);
      },
      []
    );
    const location_ids = _.uniq(_.compact(_.flatten(finalValues)));
    return location_ids;
  }
  return [];
}

export const findIsbeyondArea = (locations = []) => {
  // return the type_of_order for which the beyond area is true
   const locs = locations.length > 0 ? [...locations] : [];
   const beyondArea = locs.filter(loc => loc.is_beyond_area === true ).map(loc => loc.type_of_loc);
   const beyondAreaLength = beyondArea.length;
   if(beyondAreaLength > 0){
     const beyondAreaResults = beyondArea.join(' & ')
     return beyondAreaResults;
   }
   return "";
}

export const retrieveOrderValue = (record, key, showIcon = false, screen = "") => {
  const limitToShow = key === 'status' ? null : 10
  if(!['T', 'LH'].includes(record.type_of_order)){
    const value = _.get(record, key );
    const type = ['R', 'TR'].includes(record.type_of_order) ? 'P' : ['D', 'TD'].includes(record.type_of_order) ? 'D' : '';
    if(isEmpty(value)){
      return '';
    }
    return renderColumnValue(value, type, showIcon, limitToShow);
  }else {
    const originValue = _.get(record.origin, key);
    const destinationValue = _.get(record.destination, key);
    if (isEmpty(originValue) && isEmpty(destinationValue)) {
      return '';
    }
    const pickupFieldValue = <Col>{renderColumnValue( originValue || 'NA', 'P', showIcon, limitToShow)}</Col>
    const  destinationFieldValue =  <Col>{renderColumnValue(destinationValue || 'NA', 'D', showIcon, limitToShow)}</Col>
    {
      return screen !=="beta" ? (<Row type="flex" gutter={4}>{pickupFieldValue}<Col>-</Col>{destinationFieldValue}</Row>) : (<Row type="flex">{pickupFieldValue}&nbsp;- &nbsp;{destinationFieldValue}</Row>)
    }
  }
};

// export const retrieveAddress = (record, key, showIcon = false) => {
//   if(!['T', 'LH'].includes(record.type_of_order)){
//     const address = _.get(record, "customer_address", {});
//     const type = ['R', 'TR'].includes(record.type_of_order) ? 'P' : ['D', 'TD'].includes(record.type_of_order) ? 'D' : '';
//     if(isEmpty(address)){
//       return '';
//     }
//     return Object.keys(address).length > 0 ? <Row type="flex" gutter={ 4 } align="middle">
//     <Col>{type}:</Col>
//     <Col>
//       <AddressInfo address={ address } />
//     </Col>
//   </Row> : ''
//   }else {
//     const sourcePoint = _.get(record, "origin", {});
//     const destinationPoint = _.get(record, "destination", {});
//     if (isEmpty(sourcePoint) && isEmpty(destinationValue)) {
//       return '';
//     }
//     return <div>
//     { _.isObject(sourcePoint) && Object.keys(sourcePoint).length > 0 && (
//       <Row type="flex" gutter={ 4 } align="middle">
//         <Col>P:</Col>
//         <Col>
//           <AddressInfo address={ sourcePoint } />
//         </Col>
//       </Row>
//     ) }
//     { _.isObject(destinationPoint) && Object.keys(destinationPoint).length > 0 && (
//       <Row type="flex" gutter={ 4 } align="middle">
//         <Col>D:</Col>
//         <Col>
//           <AddressInfo address={ destinationPoint } />
//         </Col>
//       </Row>
//     ) }
//   </div>
//   }
// };

export const retrieveAddress = (record, key, showIcon = false) => {
  if(!['T', 'LH'].includes(record.type_of_order)){
    const address = _.get(record, "customer_address", {});
    const warehouseAddress = _.get(record, "warehouse_address", {});
    const type = ['R', 'TR'].includes(record.type_of_order) ? 'P' : ['D', 'TD'].includes(record.type_of_order) ? 'D' : '';
    if(isEmpty(address)){
      return '';
    }

    // if (type == 'P' && key=="pickup") {
    //   return Object.keys(address).length > 0 ? <Row type="flex" gutter={4} align="middle">
    //     <Col>{type}:</Col>
    //     <Col>
    //       <AddressInfo address={address} />
    //     </Col>
    //   </Row> : ''
    // } else if (type == 'D'&& key=="delivery") {
    //   return Object.keys(address).length > 0 ? <Row type="flex" gutter={4} align="middle">
    //     <Col>{type}:</Col>
    //     <Col>
    //       <AddressInfo address={address} />
    //     </Col>
    //   </Row> : ''
    // }

    // return "no address"


    return Object.keys(address).length > 0 ? <Row type="flex" gutter={4} align="middle">
      <Col>{type}:</Col>
    <Col>
        <AddressInfo address={address} />
    </Col>
  </Row> : ''
  }else {
    const sourcePoint = _.get(record, "origin", {});
    const destinationPoint = _.get(record, "destination", {});
    if (isEmpty(sourcePoint) && isEmpty(destinationValue)) {
      return '';
    }
    if (key == 'pickup') {
      return <div>
        {_.isObject(sourcePoint) && Object.keys(sourcePoint).length > 0 && (
          <Row type="flex" gutter={4} align="middle">
            <Col>P:</Col>
            <Col>
              <AddressInfo address={sourcePoint} />
            </Col>
          </Row>
        )}
      </div>
    } else if (key == 'delivery') {
      return <div>
        {_.isObject(destinationPoint) && Object.keys(destinationPoint).length > 0 && (
          <Row type="flex" gutter={4} align="middle">
            <Col>D:</Col>
            <Col>
              <AddressInfo address={destinationPoint} />
            </Col>
          </Row>
        )}
      </div>

    }
    
    // return " "

    return <div>
      {_.isObject(sourcePoint) && Object.keys(sourcePoint).length > 0 && (
        <Row type="flex" gutter={4} align="middle">
          <Col>P:</Col>
          <Col>
            <AddressInfo address={sourcePoint} />
          </Col>
        </Row>
      )}
      {_.isObject(destinationPoint) && Object.keys(destinationPoint).length > 0 && (
        <Row type="flex" gutter={4} align="middle">
          <Col>D:</Col>
          <Col>
            <AddressInfo address={destinationPoint} />
          </Col>
        </Row>
      )}
    </div>
  }
};

// export const retrieveOrderValue = (record, key, showIcon = false) => {
//   if (['T', 'LH'].includes(record.type_of_order)) {
//     const originKey = key === 'status' ? 'pickup_status' : `origin.${key}`;
//     const destinationKey = key === 'status' ? 'del_status' : `destination.${key}`;

//     const originValue = _.get(record, originKey, 'NA');
//     const destinationValue = _.get(record, destinationKey, 'NA');

//     const pickupFieldValue = renderColumnValue(originValue, 'P', showIcon);
//     const destinationFieldValue = renderColumnValue(destinationValue, 'D', showIcon);

//     if (pickupFieldValue === '' && destinationFieldValue === '') {
//       return '';
//     }

//     return (
//       <Row type="flex" gutter={4}>
//         {pickupFieldValue}
//         {pickupFieldValue !== '' && destinationFieldValue !== '' && <Col>-</Col>}
//         {destinationFieldValue}
//       </Row>
//     );
//   } else if (['R', 'TR'].includes(record.type_of_order)) {
//     return renderColumnValue(_.get(record, key, 'NA'), 'P', showIcon);
//   } else if (['D', 'TD'].includes(record.type_of_order)) {
//     return renderColumnValue(_.get(record, key, 'NA'), 'D', showIcon);
//   } else {
//     return ''; // Handle other cases here if needed
//   }
// };

export const renderColumnValue = (value, type, showIcon = false, limitToShow = 6) => {
  const isCompleted = value === 'COMPLETED';
  // const shortForm = type === 'P' ? "P/U" : "DEL";
  const shortForm = type //=== 'P' ? "P" : "D";
  return <span className="textBold">
    {showIcon && <Icon type={"check-circle"} className={`${isCompleted ? "textGreen" : "textGrey"} textBold marginRight5`}/>}
    {shortForm}:{" "}{ellipseText(value , limitToShow ,false,null ,true, false)}
  </span>
}

export const retrieveMapIdentity = (isAirCargo = false, orderObject = {}) => {
  if(!isAirCargo){
    return orderObject['customer_order_number'] || ''
  }
  return orderObject['hawb'] || orderObject['customer_order_number'] || ''
}

export const getLocIdFormLoc = (locObj = {}, key = "id") => {
  return locObj?.l_type === "CS" ? locObj?.[key] : "";
};

export const pickLocsFromOrders = (orders, locationKey, findDependency = true) => {

  let dependencyOrders = [];

  if(findDependency){
    let totalSelOrders = _.cloneDeep(orders);
    totalSelOrders.forEach((currentRecord) => {
      const dependencies = findDependencyOrders(
        totalSelOrders,
        'related_order',
        'id',
        currentRecord.id,
        'object',
        'type_of_order',
      );
      const dependencyIds = dependencies.map(order => order.id);
      totalSelOrders = _.filter(totalSelOrders, order => !dependencyIds.includes(order.id));
      dependencyOrders = _.concat(dependencyOrders, dependencies);
    });
  } else{
    dependencyOrders = _.cloneDeep(orders);
  }

  const locationWiseOrders = _.flatMap(dependencyOrders, order => {
    const pickupLocId = _.get(order.origin, locationKey, "");
    const dropLocId = _.get(order.destination, locationKey, "");
    const pickupStatus = _.get(order.origin, "status", "");
    const dropStatus = _.get(order.destination, "status", "");

    if (['T', 'LH'].includes(order.type_of_order)) {
      if (order.wh_storage) {
        if (pickupStatus !== 'COMPLETED' && pickupLocId) {
          return [{ ...order, location_id: pickupLocId, order_type: 'PICKUP' }];
        } else if (dropStatus !== 'COMPLETED' && dropLocId) {
          return [{ ...order, location_id: dropLocId, order_type: 'DELIVERY' }];
        }
      } else {
        const ordersToAdd = [];
        if (pickupStatus !== 'COMPLETED' && pickupLocId) {
          ordersToAdd.push({ ...order, location_id: pickupLocId, order_type: 'PICKUP' });
        }
        if (dropStatus !== 'COMPLETED' && dropLocId) {
          ordersToAdd.push({ ...order, location_id: dropLocId, order_type: 'DELIVERY' });
        }
        return ordersToAdd;
      }
    } else {
      if (order.origin?.l_type === 'CS') {
        return [{ ...order, location_id: pickupLocId, order_type: 'PICKUP' }];
      } else if (order.destination?.l_type === 'CS') {
        return [{ ...order, location_id: dropLocId, order_type: 'DELIVERY' }];
      }
    }

    return [];
  });

  return locationWiseOrders;
};

export const pickLocsFromRouteOrders = (route, locationKey) => {
  if(!route?.stop_details){
    return [];
  }
  if(route.multi_stop_orders !== 0 ){
    const stopOrders = route.stop_details.flatMap(stop =>
      stop.stop_orders.map(order => ({
        ...order,
        stop_location_ids: stop.stop_location_ids
      }))
    );
    const orders = _.flatMap(stopOrders);
    return orders;
  }
  const stopOrders = route.stop_details.flatMap(stop =>
    stop.stop_orders.map(order => ({
      ...order,
      stop_location_ids: stop.stop_location_ids,
      location_id: _.get(stop, "stop_location_ids[0]", "") || "", 
      order_type: 'PICKUP',
    }))
  );

  const locationWiseOrders = _.flatMap(stopOrders, order => {
    const pickupLocId = _.get(order.origin, locationKey, "");
    const dropLocId = _.get(order.destination, locationKey, "");
    const pickupStatus = _.get(order.origin, "status", "");
    const dropStatus = _.get(order.destination, "status", "");

    const ordersToAdd = [];

    if (['T', 'LH'].includes(order.type_of_order)) {
      if (order.wh_storage) {
        if (pickupStatus !== 'COMPLETED' && order.stop_location_ids.includes(pickupLocId)) {
          ordersToAdd.push({ ...order, location_id: pickupLocId, order_type: 'PICKUP' });
        } else if (dropStatus !== 'COMPLETED' && order.stop_location_ids.includes(dropLocId)) {
          ordersToAdd.push({ ...order, location_id: dropLocId, order_type: 'DELIVERY' });
        }
      } else {
        if (pickupStatus !== 'COMPLETED' && order.stop_location_ids.includes(pickupLocId)) {
          ordersToAdd.push({ ...order, location_id: pickupLocId, order_type: 'PICKUP' });
        }
        if (dropStatus !== 'COMPLETED' && order.stop_location_ids.includes(dropLocId)) {
          ordersToAdd.push({ ...order, location_id: dropLocId, order_type: 'DELIVERY' });
        }
      }
    } else {
      if (order.origin?.l_type === 'CS' && order.stop_location_ids.includes(pickupLocId)) {
        ordersToAdd.push({ ...order, location_id: pickupLocId, order_type: 'PICKUP' });
      }
      if (order.destination?.l_type === 'CS' && order.stop_location_ids.includes(dropLocId)) {
        ordersToAdd.push({ ...order, location_id: dropLocId, order_type: 'DELIVERY' });
      }
    }

    return ordersToAdd;
  });

  return locationWiseOrders;
};

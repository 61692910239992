import React, { memo } from "react";
import I18n from "../common/I18n";
const Users = React.lazy(() => import("../containers/Users"));
const Zones = React.lazy(() => import("../containers/Zones"));
const Locations = React.lazy(() => import("../containers/Locations"));
const EditProfile = React.lazy(() => import("../containers/EditProfile"));
const ChangePassword = React.lazy(() => import("../containers/ChangePassword"));
const Dashboard = React.lazy(() => import("../containers/Dashboard"));
const Dispatches = React.lazy(() => import("../containers/Dispatches"));
const Tasks = React.lazy(() => import("../containers/Tasks"));
const Orders = React.lazy(() => import("../containers/Orders"));
const Stops = React.lazy(() => import("../containers/Stops"));
const RouteInfo = React.lazy(() => import("../containers/RouteInfo"));
const Poc = React.lazy(() => import("../containers/Poc"));
const UploadOrders = React.lazy(() => import("../containers/UploadOrders"));
const Questionaries = React.lazy(() => import("../containers/Questionaries"));
const Surveys = React.lazy(() => import("../containers/Surveys"));
const Trucks = React.lazy(() => import("../containers/Trucks"));
const PreplanRouteMang = React.lazy(() => import("../containers/PreplanScreen"));
const PreplanMangement = React.lazy(() => import("../containers/PreplanMangement"));
const Organizations = React.lazy(() => import("../containers/Organizations"));
const Accessorials = React.lazy(() => import("../containers/Accessorial"));
const Accounts = React.lazy(() => import("../containers/Accounts"));
const Configurations = React.lazy(() => import("../containers/Configurations"));
const CsvSettings = React.lazy(() => import("../components/CsvSettings"));
const Invoices = React.lazy(() => import("../containers/Invoices"));
const ExternalInvoices = React.lazy(() => import("../containers/ExternalInvoices"));
const Forms = React.lazy(() => import("../containers/Forms"));
const DriverRates = React.lazy(() => import("../containers/DriverRates"));
const PredefinedStops = React.lazy(() => import("../containers/PredefinedStops"));
const Support = React.lazy(() => import("../containers/Support"));
const SearchFiles = React.lazy(() => import("../containers/SearchFiles"));
const BillingScreen = React.lazy(() => import("../containers/BillingScreen"));
const Activity = React.lazy(() => import("../containers/Activity"));
const OrderStatus = React.lazy(() => import("../containers/OrderStatus"));
const Roles = React.lazy(() => import("../containers/Roles"));
const RolesActionMapping = React.lazy(() => import("../components/users/RolesActionMapping"));
const Renewal = React.lazy(() => import("../containers/Renewal"));
const ModuleMapping = React.lazy(() => import("../containers/ModuleMapping"));
const Packages = React.lazy(() => import("../containers/Packages"));
const Integrations = React.lazy(() => import("../components/Integrations/Integrations"));
const TemplateParams = React.lazy(() => import("../containers/TemplateParams"));
const TemplateEvents = React.lazy(() => import("../containers/TemplateEvents"));
const Quotes = React.lazy(() => import("../containers/Quotes"));
const Releases = React.lazy(() => import("../containers/Releases"));
const Recovery = React.lazy(() => import("../containers/Recovery"));
const PendingOrders = React.lazy(() => import("../containers/PendingOrders"));
const MappingSettings = React.lazy(() => import("../containers/MappingSettings"));
const LableSetting = React.lazy(() => import("../containers/LableSettings"));
const Templates = React.lazy(() => import("../containers/Templates.jsx"));
const AccountSetting = React.lazy(() => import("../containers/AccountSetting"));
const ItemGrouping = React.lazy(() => import("../components/orders/ItemGrouping"));
const DisplaySettings = React.lazy(() => import("../containers/DisplaySettings"));
const UpdateOrderLatLng = React.lazy(() => import("../containers/UpdateOrderLatLng"));
const CarrierCustomize = React.lazy(() => import("../components/CarrierCustomize"));
const AccountOutStandings = React.lazy(() => import("../containers/AccountOutStandings"));
const OutStandingDetails = React.lazy(() => import("../components/account_out_standings/OutStandingDetails"));
const Linehaul = React.lazy(() => import("../containers/LineHaul/Linehaul"));
const BatchPayments = React.lazy(() => import("../containers/BatchPayments"));
const VehicleType = React.lazy(() => import("../containers/VehicleType"));
const OrderConfigurations = React.lazy(() => import("../components/orders/OrderConfigurations"));
const RemittanceManage = React.lazy(() => import("../components/account_ar/RemittanceManage"));
const BatchHomeScreen = React.lazy(() => import("../containers/BatchHomeScreen"));
const AccountReceivablesComponent = React.lazy(() => import("../containers/AccountReceivables"));
const ARPayment = React.lazy(() => import("../components/ar_module/ARPayment"));
const WrappedManageBatchComponent = React.lazy(() => import("../components/account_ar/ManageBatch"));
const ViewChecksHomePage = React.lazy(() => import("../components/ar_module/ViewChecksHomePage"));
const TransactionsInfo = React.lazy(() => import("../components/ar_module/TransactionsInfo"));
const MobileConfig = React.lazy(() => import("../containers/MobileConfiguration"));
const CreditNoteComponent = React.lazy(() => import("../containers/CreditNotes"));
const VehicleTypeMapping = React.lazy(() => import("../containers/VehicleTypeMapping"));
const DmsConfig = React.lazy(() => import("../containers/dmsConfig"));
const NewDmsConfig = React.lazy(() => import("../containers/NewDmsConfig"));
const DriverLogReport = React.lazy(() => import("../components/DriverLogReport/DriverLogReport"));
const CustomerLogReport = React.lazy(() => import("../components/CustomerLogs/CustomerLogReport"));
const Consolidate = React.lazy(() => import("../components/billing/Consolidate"));
const ConsolidationList = React.lazy(() => import("../components/ar_module/ConsolidationList"));
const CreditLimit = React.lazy(() => import("../components/configurations/CreditLimit"));
const AgingReports = React.lazy(() => import("../components/reports/aging_reports/AgingReports"));
const PaymentJournal = React.lazy(() => import("../components/reports/aging_reports/PaymentJournal"));
const OpenBalance = React.lazy(() => import("../components/reports/aging_reports/OpenBalance"));
const StatusReports = React.lazy(() => import("../components/reports/status_reports/StatusReports"));
const DriverHomeScreen = React.lazy(() => import("../components/driver_monthly_report/DriverHomeScreen.jsx"));
const CapcityHomeScreen = React.lazy(() => import("../components/CapacityReports/CapcityHomeScreen.jsx"));
const DunningReports = React.lazy(() => import("../components/reports/dunning_reports/DunningReports"));
const InvoiceStatusReports = React.lazy(() => import("../components/reports/invoice_status_reports/InvoiceStatusReports"));
const PodReport = React.lazy(() => import("../components/DriverLogReport/PodReport"));
const GlCodeReport = React.lazy(() => import("../components/DriverLogReport/GLCodeReport"));
const DockScanReportComponent = React.lazy(() => import("../components/reports/dock_scan_report/DockScanScreen.jsx"));
const QbReport = React.lazy(() => import("../components/reports/qb_reports/QbReports.jsx"));
const WarehouseComponent = React.lazy(() => import("../containers/Warehouses"));
const InvoiceForm = React.lazy(() => import("../components/billing/InvoiceForm.jsx"));
const AgentComponent = React.lazy(() => import("../components/agent/Agent"));
const DockScanIncomingScreen = React.lazy(() => import("../components/reports/dock_scan_report/DockScanIncomingScreen.jsx"));
const MonthlyCutOffScreen = React.lazy(() => import("../components/billing_screen/MonthlyCutOffScreen.jsx"));
const OrdersGrid = React.lazy(() => import("../components/orders/OrderGrid"));
const WeeklyReports = React.lazy(() => import("../components/users/WeeklyReports.jsx"));
const CollectionReports = React.lazy(() => import("../components/reports/collection_reports/CollectionReports.jsx"));
const DeletedOrdersGrid = React.lazy(() => import("../components/orders/DeletedOrdersGrid.jsx"));
import {PageLoadingScreen} from "../components/common/LogisticsLoadingScreen";
const ChartredAccounts = React.lazy(() => import("../containers/ChartredAccounts.jsx"));
const Divisions = React.lazy(() => import("../containers/Divisions.jsx"));

// const WrappedManageMasterChecksComponent = React.lazy(() => import("../components/ar_master_checks/ManageMasterChecks.jsx")) ;
const CreditTransactionInfo = React.lazy(() => import("../components/ar_master_checks/ManageMasterChecks.jsx")) ;
const ChequeDetailsTransactionInfo = React.lazy(() => import("../components/ar_module/ChequeDetailsTransactionInfo.jsx")) ;
const MasterTransactionInfo = React.lazy(() => import("../components/ar_module/MasterTransactionInfo.jsx")) ;
const ManageMasterChecks = React.lazy(() => import("../components/ar_master_checks/ManageMasterChecks.jsx"));
const UploadRemittance = React.lazy(() => import("../components/ar_module/UploadRemittance.jsx")) ;
const WrappedManageMasterChecksComponent = memo(ManageMasterChecks);

const SuperAdminRoutes = [
  {
    key: "022",
    exact: true,
    path: "/organizations",
    component: Organizations,
  },
  {
    key: "030",
    exact: true,
    path: "/search_files",
    component: Support,
  },
  {
    key: "031",
    exact: true,
    path: "/route_tracking",
    component: SearchFiles,
  },
  {
    key:"080",
    exact:true,
    path:"/driver_monthly_report",
    component:DriverHomeScreen,
  },
  {
    key: "039",
    exact: true,
    path: "/packages",
    component: Packages,
  },
  {
    key: "048",
    exact: true,
    path: "/lable_settings",
    component: LableSetting,
  },
  {
    key: "047",
    exact: true,
    path: "/mapping_settings",
    component: MappingSettings,
  },
  {
    key: "050",
    exact: true,
    path: "/account_settings",
    component: AccountSetting,
  },
  {
    key: "040",
    exact: true,
    path: "/integrations",
    component: Integrations,
  },
  {
    key: "055",
    exact: true,
    path: "/carrier_integration",
    component: CarrierCustomize,
  },
  {
    key: "033",
    exact: true,
    path: "/activities",
    component: Activity,
  },
  {
    key: "034",
    exact: true,
    path: "/order_status_code",
    component: OrderStatus,
  },
  {
    key: "052",
    exact: true,
    path: "/set_order_lat_lng",
    component: UpdateOrderLatLng,
  },
  {
    key: "041",
    exact: true,
    path: "/template_params",
    component: TemplateParams,
  },
  {
    key: "042",
    exact: true,
    path: "/event_params",
    component: TemplateEvents,
  },
  {
    key: "056",
    exact: true,
    path: "/locations",
    component: Locations,
    title: I18n.t("menu.locations"),
    code: 'warehouses',
    sequence: 27,
  },
  {
    key: "060",
    exact: true,
    path: "/mobile_configuration",
    component: MobileConfig,
  },
  {
    key: "061",
    exact: true,
    path: "/vehicletype_mapping",
    component: VehicleTypeMapping,
  },
  {
    key: "004",
    exact: true,
    path: "/edit_profile",
    component: EditProfile,
  },
  {
    key: "005",
    exact: true,
    path: "/changePassword",
    component: ChangePassword,
  },

];

const DriverRoutes = [
  {
    key: "001",
    exact: true,
    path: "/weekly_settlement_reports",
    component: WeeklyReports,
  },
  {
    key: "002",
    exact: true,
    path: "/edit_profile",
    component: EditProfile,
  },
  {
    key: "003",
    exact: true,
    path: "/changePassword",
    component: ChangePassword,
  },
];

const UserRoutes = [
  {
    key: "000",
    exact: true,
    path: "/zones",
    component: Zones,
  },
  {
    key: "001",
    exact: true,
    path: "/drivers",
    component: Users,
    title: I18n.t("menu.drivers"),
    code: 'drivers',
    sequence: 32,
  },
  {
    key: "004",
    exact: true,
    path: "/edit_profile",
    component: EditProfile,
  },
  
  {
    key: "003",
    exact: true,
    path: "/locations",
    component: WarehouseComponent,
    title: I18n.t("menu.locations"),
    code: 'warehouses',
    sequence: 27,
  },
  {
    key: "005",
    exact: true,
    path: "/changePassword",
    component: ChangePassword,
  },
  {
    key: "052",
    exact: true,
    path: "/displaySettings",
    component: DisplaySettings,
  },
  {
    key: "006",
    exact: true,
    path: "/dashboard",
    component: Dashboard,
    title: I18n.t("menu.dashboard"),
    code: 'dashbord',
    sequence: 1,
  },
  {
    key: "007",
    exact: true,
    path: "/routes",
    component: Dispatches,
    title: I18n.t("menu.routes"),
    code: 'routes',
    sequence: 10,
  },
  {
    key: "008",
    exact: true,
    path: "/tasks",
    component: Tasks,
  },
  {
    key: "009",
    exact: true,
    path: "/tasks/:routeId",
    component: Tasks,
  },
  {
    key: "010",
    exact: true,
    path: "/orders",
    component: OrdersGrid,
    title: I18n.t("menu.orders"),
    code: 'orders',
    sequence: 3,
  },
  {
    key: "011",
    exact: true,
    path: "/stops/:routeId",
    component: Stops,
  },
  {
    key: "012",
    exact: true,
    path: "/route/:routeId",
    component: RouteInfo,
  },
  {
    key: "014",
    exact: true,
    path: "/upload",
    component: UploadOrders,
    title: I18n.t("menu.upload_orders"),
    code: 'upload_orders',
    sequence: 4,
  },
  {
    key: "015",
    exact: true,
    path: "/questionaries/:routeId",
    component: Questionaries, //  Surveys, //
  },
  {
    key: "016",
    exact: true,
    path: "/surveys",
    component: Surveys,
    title: I18n.t("menu.surveys"),
    code: 'surveys',
    sequence: 26,
  },
  {
    key: "017",
    exact: true,
    path: "/trucks",
    component: Trucks,
    title: I18n.t("menu.trucks"),
    code: 'trucks',
    sequence: 33,
  },
  {
    key: "018",
    exact: true,
    path: "/configurations",
    component: Configurations,
    title: I18n.t("menu.org_settings"),
    code: 'org_settings',
    sequence: 29,
  },
  {
    key: "019",
    exact: true,
    path: "/preplan",
    component: PreplanRouteMang,
  },
  {
    key: "020",
    exact: true,
    path: "/preplan_management",
    component: PreplanMangement,
    title: I18n.t("menu.preplan_management"),
    code: 'preplan_management',
    sequence: 9,
  },
  {
    key: "021",
    exact: true,
    path: "/preplan/:preplanId",
    component: PreplanRouteMang,
  },
  {
    key: "023",
    exact: true,
    path: "/accessorial",
    component: Accessorials,
  },
  {
    key: "024",
    exact: true,
    path: "/accounts",
    component: Accounts,
    title: I18n.t("menu.account"),
    code: 'accounts',
    sequence: 24,
  },
  {
    key: "025",
    exact: true,
    path: "/accounts/:accountId/settings",
    component: Configurations,
  },
  {
    key: "027",
    exact: true,
    path: "/billing",
    component: Invoices,
    title: I18n.t("menu.billing_management"),
    code: 'billing',
    sequence: 11,
  },
  {
    key: "028",
    exact: true,
    path: "/forms",
    component: Forms,
    title: I18n.t("menu.forms"),
    code: 'forms',
    sequence: 25,
  },
  {
    key: "029",
    exact: true,
    path: "/predefined_stops",
    component: PredefinedStops,
    title: I18n.t("menu.predefined_stops"),
    code: 'predefined_stops',
    sequence: 28,
  },
  {
    key: "032",
    exact: true,
    path: "/invoices",
    component: BillingScreen,
    title: I18n.t("menu.invoices"),
    code: 'invoices',
    sequence: 12,
  },
  // {
  //   key: "089",
  //   exact: true,
  //   path: "/invoices-beta",
  //   component: BillingScreen,
  //   title: I18n.t("menu.invoices"),
  //   code: 'invoices',
  //   sequence: 12,
  // },
  {
    key: "035",
    exact: true,
    path: "/roles",
    component: Roles,
    title: I18n.t("menu.roles"),
    code: 'roles',
    sequence: 34,
  },
  {
    key: "036",
    exact: true,
    path: "/roles/:roleId/settings",
    component: RolesActionMapping,
  },
  {
    key: "043",
    exact: true,
    path: "/quotes",
    component: Quotes,
    title: I18n.t("menu.quotes"),
    code: 'quotes',
    sequence: 5,
  },
  {
    key: "044",
    exact: true,
    path: "/releases",
    component: Releases,
    title: I18n.t("menu.releases"),
    code: 'releases',
    sequence: 7,
  },
  {
    key: "045",
    exact: true,
    path: "/recovery",
    component: Recovery,
    title: I18n.t("menu.recovery"),
    code: 'recovery',
    sequence: 6,
  },
  {
    key: "046",
    exact: true,
    path: "/pending_orders",
    component: PendingOrders,
    title: I18n.t("menu.pending_orders"),
    code: 'pending_orders',
    sequence: 2,
  },
  {
    key: "049",
    exact: true,
    path: "/templates",
    component: Templates,
    title: I18n.t("menu.recurring_operations"),
    code: 'recurring_operations',
    sequence: 8,
  },
  {
    key: "051",
    exact: true,
    path: "/item_grouping",
    component: ItemGrouping,
  },
  {
    key: "053",
    exact: true,
    path: "/account_out_standings",
    component: AccountOutStandings,
  },
  {
    key: "054",
    exact: true,
    path: "/account_out_standings/:accountId/",
    component: OutStandingDetails,
  },
  {
    key: "029",
    exact: true,
    path: "/driver_rates",
    component: DriverRates,
  },
  {
    key: "013",
    exact: true,
    path: "/poc",
    component: Poc,
  },
  {
    key: "037",
    exact: true,
    path: "/upgrade",
    component: Renewal,
  },
  {
    key: "038",
    exact: true,
    path: "/package_features",
    component: ModuleMapping,
  },
  {
    key: "039",
    exact: true,
    path: "/linehaul",
    component: Linehaul,
    title: I18n.t("menu.linehaul"),
    code: 'linehaul',
    sequence: 7,
  },
  {
    key: "040",
    exact: true,
    path: "/vehicle_types",
    component: VehicleType,
    title: I18n.t("menu.vehicle_type"),
    code: 'vehicle_type',
    sequence: 31,
  },
  {
    key: "057",
    exact: true,
    path: "/batch_payments",
    component: BatchPayments,
  },
  {
    key: "058",
    exact: true,
    path: "/orders/:orderId/:actionType",
    component: OrderConfigurations,
    validActionTypes: ["edit", "details"],
  },
  {
    key: "059",
    exact: true,
    path: "/orders/create",
    component: OrderConfigurations,
  },
  {
    key: "060",
    exact: true,
    path: "/batches",
    component: BatchHomeScreen,
    title: I18n.t("menu.batch_management"),
    code: 'batches',
    sequence: 14,
  },
  {
    key: "061",
    exact: true,
    path: "/manage_batch/:batchId",
    component: WrappedManageMasterChecksComponent
  },
  {
    key: "062",
    exact: true,
    path: "/remittances/:batchId/:checkId",
    component: RemittanceManage
  },
  {
    key: "063",
    exact: true,
    path: "/account_receivables",
    component: AccountReceivablesComponent,
    title: I18n.t("menu.account_receivables"),
    code: 'account_receivables',
    sequence: 16,
  },
  {
    key: "064",
    exact: true,
    path: "/account_receivables/:accountId",
    component: ARPayment
  },
  {
    key: "065",
    exact: true,
    path: "/view_check_details/:accountId",
    component: ViewChecksHomePage
  },
  {
    key: "066",
    exact: true,
    path: "/check_transaction_info/:checkId",
    component: MasterTransactionInfo ||  TransactionsInfo
  },
  {
    key: "067",
    exact: true,
    path: "/open_invoices",
    component: ARPayment,
    title: I18n.t("menu.open_invoices"),
    code: 'open_invoices',
    sequence: 17,
  },
  {
    key: "068",
    exact: true,
    path: "/manage_checks",
    component: WrappedManageMasterChecksComponent,
    title: I18n.t("menu.manage_checks"),
    code: 'manage_checks',
    sequence: 15,

  },
  // {
  //   key: "069",
  //   exact: true,
  //   path: "/document_management_system",
  //   component: DmsConfig,
  //   title: I18n.t("dms.title"),
  //   code: 'dms',
  //   sequence: 35,
  // },
  {
    key: "069",
    exact: true,
    path: "/document_management_system", // "/dms"
    component: NewDmsConfig,
  },
  { key: "070",
    path: "/credit_memo",
    component: CreditNoteComponent,
    title: I18n.t("menu.credit_notes"),
    code: 'credit_memo',
    sequence: 17,
  },
  {
    key: "071",
    exact: true,
    path: "/customer_log_report",
    component: CustomerLogReport,
    title: I18n.t("menu.customer_log_report"),
    code: 'customer_log_report',
    sequence: 18,
  },
  {
    key: "072",
    exact: true,
    path: "/driver_log_report",
    component: DriverLogReport,
    title: I18n.t("driver_log_report.menu"),
    code: 'driver_log_report',
    sequence: 19,
  },
  {
    key: "073",
    exact: true,
    path: "/consolidate",
    component: Consolidate
  },
  {
    key:"074",
    exact:true,
    path:"/credit_limit",
    component: CreditLimit,
    title: I18n.t("menu.credit_limit"),
    code: 'credit_limit',
    sequence: 13,
  },  
  {
    key: "075",
    exact: true,
    path: "/aging_report",
    component: AgingReports,
    title: I18n.t("aging.menu"),
    code: 'aging_report',
    sequence: 21,
  },
  {
    key:"076",
    exact:true,
    path:"/open_balance",
    component:OpenBalance,
    title: I18n.t("menu.open_bal"),
    code: 'orders',
    sequence: 22,
  },

  {
    key:"077",
    exact:true,
    path:"/payment_journal",
    component:PaymentJournal,
    title: I18n.t("menu.payment_journal"),
    code: 'payment_journal',
    sequence: 22,
  },
  {
    key: "078",
    exact: true,
    path: "/consolidation/:consolidate_no/",
    component: Consolidate,
  },
  {
    key: "079",
    exact: true,
    path: "/status_report",
    component: StatusReports,
    title: "EDI Status Report",
    code: 'edi_status_report',
    sequence: 23,
  },
  {
    key: "080",
    exact: true,
    path: "/routes-beta",
    component: Dispatches,
    title: I18n.t("menu.routes"),
    code: 'routes-beta',
    sequence: 24
  },
   { key: "081",
    exact: true,
    path: "/capacity_report",
    component: CapcityHomeScreen,
    title: I18n.t("capacity_report.title"),
    code: 'capacity_report',
    sequence: 25
  },
  {
    key: "082",
    exact: true,
    path: "/invoice_status_report",
    component: InvoiceStatusReports,
  },
  // {
  //   key: "083",
  //   exact: true,
  //   path: "/orders-beta",
  //   component: OrdersGrid,
  //   title: I18n.t("menu.routes"),
  //   code: 'orders-beta',
  //   sequence: 25
  // },
  {
    key: "084",
    exact: true,
    path: "/preplan_management-beta",
    component: Dispatches,
    title: I18n.t("menu.routes"),
    code: 'preplan_management-beta',
    sequence: 26
  },
  {
    key: "085",
    exact: true,
    path: "/dunning_notice",
    component: DunningReports,
    title: I18n.t("dunning_notice.title"),
    code: 'dunning_notice',
  },
  {
    key: "086",
    exact: true,
    path: "/gl_code_report",
    component: GlCodeReport,
    title: "GL Code Report",
    code: 'gl_code_report',
  },
  {
    key: "087",
    exact: true,
    path: "/pod_report",
    component: PodReport,
    title: I18n.t("pod_report.title"),
    code: 'pod_report',
  },
  {
    key: "084",
    exact: true,
    path: "/dock_scan_report",
    component: DockScanReportComponent,
    title: I18n.t("dock_scan_report.title"),
    code: 'dock_scan_report',
  },
  {
    key: "088",
    exact: true,
    path: "/qb_report",
    component: QbReport,
  },
  {
    key: "090",
    exact: true,
    path: "/dock_scan_management",
    component: DockScanIncomingScreen,
  },
  {
    key: "091",
    exact: true,
    path: "/agent", // "/dms"
    component: AgentComponent,
  },
  {
    key: "092",
    exact: true,
    path: "/monthly_cutoff",
    component: MonthlyCutOffScreen,
  },
  // {
  //   key: "093",
  //   exact: true,
  //   path: "/collection_report",
  //   component: CollectionReports,
  // },
  // {
  //   key: "preplan_beta",
  //   exact: true,
  //   path: "/preplan_management_beta",
  //   component: PreplanMangement,
  //   title: I18n.t("menu.preplan_management"),
  //   code: 'preplan_management_beta',
  // },
  {
    key: "093",
    exact: true,
    path: "/credit_transaction_info/:creditNoteId",
    component: CreditTransactionInfo,
  },
  {
    key: "094",
    exact: true,
    path: "/check_details_info/:checkDetailsId",
    component: ChequeDetailsTransactionInfo,
  },
  {
     key: "095",
    exact: true,
    path: "/deleted-orders",
    component: DeletedOrdersGrid,
    // title: I18n.t("menu.preplan_management"),
  },
  {
    key: "095",
    exact: true,
    path: "/external_billing",
    component: ExternalInvoices,
    title: I18n.t("menu.external_billing"),
    code: 'external_billing',
   
  },
  {
    key: "charted_accounts",
    exact : true,
    path : "/charted_accounts",
    component: ChartredAccounts
  },
  {
    key: "divisions",
    exact : true,
    path : "/divisions",
    component: Divisions
  },
  {
    key: "096",
    exact: true,
    path: "/upload_remittance/:checkId",
    component: UploadRemittance,
  },
];

const CommonRoutes = [
  {
    key: "002",
    exact: true,
    path: "/users",
    component: Users,
    title: I18n.t("menu.users"),
    code: 'users',
    sequence: 30,
  },
  {
    key: "026",
    exact: true,
    path: "/csv_settings",
    component: CsvSettings,
  },
  {
    key: "HOME",
    exact: true,
    path: "/",
    component: PageLoadingScreen,
  },
];

export { SuperAdminRoutes, UserRoutes, CommonRoutes, DriverRoutes};
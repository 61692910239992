import React, { Component } from 'react';
import MixedGallery from '../stories/MixedGallery';
import BaseModal from '../components/BaseModal';
import { handlePrint } from './preview';


class PdfPreviewViewer extends Component {
  state = {
    showOrderPreview: false,
    orderPreviewUrl: null,
    orderFileName: null,
  };

  componentDidUpdate(prevProps) {
    const { blobUrl, fileName } = this.props;

    if (
      blobUrl !== prevProps.blobUrl &&
      blobUrl &&
      fileName
    ) {
      this.setState({
        showOrderPreview: true,
        orderPreviewUrl: blobUrl,
        orderFileName: fileName,
      });
    }
  }

  handleClosePreview = () => {
    if (this.state.showOrderPreview && this.state.orderPreviewUrl) {
      URL.revokeObjectURL(this.state.orderPreviewUrl);
    }
    this.setState({
      showOrderPreview: false,
      orderPreviewUrl: null,
      orderFileName: null,
    });
  };

  renderPreviewModal = () => {
    const { orderFileName, showOrderPreview, orderPreviewUrl } = this.state;

    return (
      <BaseModal
        title={orderFileName}
        visible={showOrderPreview}
        onCancel={this.handleClosePreview}
        width="80%"
        footer={null}
        destroyOnClose
      >
        <MixedGallery
          files={[
            {
              type: 'pdf',
              fileUrl: orderPreviewUrl,
              originalTitle: orderFileName,
            },
          ]}
          showPrintButton={true}
          closeComponent={this.handleClosePreview}
          handlePrint={handlePrint}
        />
      </BaseModal>
    );
  };

  render() {
    return <>{this.renderPreviewModal()}</>;
  }
}

export default PdfPreviewViewer;

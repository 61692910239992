/* eslint-disable no-nested-ternary */
import React, { Fragment } from "react";
import BaseList from "../BaseList";
import { alertMessage, checkNegitive, isEmpty } from "../../common/Common";
import equal from "fast-deep-equal";
import {
  Icon,
  Popconfirm,
  Tooltip,
  Input,
  Select,
  Row,
  Col,
  Button,
  Modal
} from "../../common/UIComponents";
import { checkServiceExistance } from "../../helpers/common";
import DeleteIcon from "../common/DeleteIcon";
import { _ } from "core-js";
import I18n from "../../common/I18n";
import BaseSelect from "../common/SelectDropdowns/BaseSelect";
import GLCodeSelect from "../common/SelectDropdowns/GLCodeSelect";
import LocationSelect from "./LocationSelect";
import PropTypes from "prop-types";

const { TextArea } = Input;
class AccesorialList extends BaseList {
  constructor (props) {
    super(props);
    this.state = {
      uniqRows: [],
      invoiceName: "",
      invoiceCode: "",
      invoiceQuantity: 1,
      invoiceAmount: "",
      orderType: "",
      error: "",
      glCodeError: "",
      errorName: "",
      orderError: "",
      isSaved: false,
      DriverSettlementApproved: this.props.DriverSettlementApproved,
      hasConsolidatedOrders: this.props.hasConsolidatedOrders,
      isLocked: this.props.isLocked,
      recordForEdit: {},
      removeEditingVals: this.props.removeEditingVals
    };
    // const { orderData } = this.props;
    this.columns = [];
    this.setColumns(props);
    this.renderCommonActions = this.renderCommonActions.bind(this);
  }

  renderCommonActions = (record, index, isEditing, showCharges, ObtainedVal, checkLocationLength) => (
    <div style={ { float: "right" } }>
      <Row type="flex" align="middle" gutter={ 8 }>
        { !isEditing ? (
          <>
            <Col>
              <Tooltip title={this.props.isAdjusted ? 'Already Adjusted' : 'Edit'}>
                <Icon
                  type="edit"
                  theme="twoTone"
                  twoToneColor={this.props.isAdjusted ? 'gray' : ''}
                  onClick={ () => {
                    if (!this.props.isAdjusted) {
                      const updateRecord = {
                        ...record,
                        isEditing: true,
                      };
                      this.props.handleEditItem(
                        index,
                        this.props.invoice_type,
                        _.get(record, "id", ""),
                        updateRecord,
                        this.props.orderData.locations
                      );
                      this.setState({
                        isSaved: false,
                        recordForEdit: record,
                        invoiceName: record.invoice_name,
                        invoiceCode: record.gl_code,
                        invoiceQuantity: record.invoice_quantity,
                        invoiceAmount: record.invoice_value,
                        orderType: ObtainedVal,
                      });
                  }
                  }}
                 style={ { fontSize: 15,cursor: this.props.isAdjusted ? 'not-allowed' : 'pointer'}}
                />
              </Tooltip>
            </Col>
            <Col>
      {this.props.isAdjusted ? (
      <Tooltip placement="topLeft" title="Already Adjusted">
        <Icon
          type="delete"
          style={{
            fontSize: 14,
            color: "gray",
            cursor: "not-allowed",
            pointerEvents: "none", 
          }}
        />
      </Tooltip>
    ) : (
              <Popconfirm
                placement="left"
                title={ I18n.t("messages.delete_confirm") }
                onConfirm={ () =>
                  this.props.deleteClick(
                    index,
                    this.props.invoice_type,
                    this.props.currentSection.order_number
                  )
                }
                okText="Yes"
                cancelText="No"
              >
                <Tooltip title="Delete">
                  <Button type="link" icon="delete" size="small" style={ { fontSize: 14, color: "red", marginTop: -4 } }>
                  </Button>
                </Tooltip>
              </Popconfirm>
    )}</Col>
            { !this.props.isAdjusted && <Col style={ {
              minWidth: "20px"
            } }>
              { showCharges && (
                <Tooltip
                  placement="topLeft"
                  title={ I18n.t("accessorials.add") }
                >
                  <Icon
                    type="plus-circle"
                    theme="filled"
                    style={ { fontSize: 14 } }
                    onClick={ () => this.props.addItem(this.props.invoice_type, this.props.currentSection.order_number, this.props.orderData.locations) }
                  />
                </Tooltip>
              ) }
            </Col>}

          </>
        ) :
          ((isEditing && this.props.isActiveRecordFlatCharge)  || (isEditing && !this.props.isActiveRecordFlatCharge && !_.toNumber(this.state.recordForEdit.invoice_value) == 0)) && (
            <>
            <Col>
              { checkServiceExistance("CINL") && (
                <Tooltip placement="topLeft" title="Save">
                  <Icon type="save" theme="twoTone" className="fontSize14 cusorPointer"
                    onClick={ () => {
                      if (isEmpty(this.state.invoiceAmount) || isEmpty(this.state.invoiceName) || isEmpty(record.location_id) || isEmpty(this.state.invoiceCode)) {
                        this.setState({
                          error: isEmpty(this.state.invoiceAmount) ? "Cannot be blank" : "",
                          glCodeError: isEmpty(this.state.invoiceCode) ? "Cannot be blank" : "",
                          errorName: isEmpty(this.state.invoiceName) ? (this.props.invoice_type === "accessorial_charges" ? "Select the accessorial" : "cannot be blank") : "",
                          orderError: isEmpty(record.location_id) ? "Cannot be blank" : ""
                        });
                      } else {
                        this.props.saveItem(
                          {
                            invoice_name: this.state.invoiceName,
                            gl_code: this.state.invoiceCode,
                            invoice_quantity: this.state.invoiceQuantity,
                            invoice_value: this.state.invoiceAmount,
                            id: record.id,
                          }, index, this.props.invoice_type, this.props.currentSection.order_number);
                        this.setState({
                          invoiceName: "",
                          invoiceCode: "",
                          invoiceQuantity: 1,
                          invoiceAmount: "",
                          isSaved: true,
                          error: "",
                          glCodeError: "",
                          errorName: "",
                          orderType: checkLocationLength ? "" : this.props.orderData?.locations[ 0 ]?.location_id,
                          orderError: ""
                        });
                      }
                    }
                    } />
                </Tooltip>
              ) }
            </Col>
            <Col>
              <Icon
                type="close"
                onClick={ () => {
                  this.props.deleteClick(index, this.props.invoice_type, this.props.currentSection.order_number);
                  this.setState({
                    recordForEdit: null,
                    invoiceName: "",
                    invoiceCode: "",
                    invoiceQuantity: 1,
                    invoiceAmount: "",
                    orderType: "",
                  });
                } }
                style={ { fontSize: 15 } }
              />
            </Col>
            <Col style={ {
              minWidth: "20px"
            } }>
            </Col>
          </>
          ) }
       
      </Row>
    </div>
  );



  setColumns = (props) => {
    const { orderData } = props;
    const columns = [
      {
        title: "#",
        key: "index",
        render: (text, record, index) => {
          const currentRowIndex = this.state.uniqRows.findIndex(rec => rec.id === record.id || (!isEmpty(record.order_item_group_id) && rec.order_item_group_id === record.order_item_group_id));
          const obj = {
            children: <Fragment>
              <span>{ currentRowIndex < 0 ? this.state.uniqRows.length + 1 : currentRowIndex + 1 }</span>
              <div className="textBold textPrimary">{ record.is_fmp_applied === 'true' ? 'FMP' : '' }</div>
            </Fragment>,
            props: {}
          };
          if (!isEmpty(record.order_item_group_id)) {
            obj.props.rowSpan = this.findSpanValue(this.props.data, record, index);
            return obj;
          }
          return obj;
        },
        width: "25%",
        align: "center",
      },
      {
        key: "invoice_name",
        title: I18n.t("general.description"),
        dataIndex: "invoice_name",
        render: (data, record, index) => {
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const tooltipTitle = "Accessorial can't be changed";

          return record.isNew && !this.state.isSaved || isEditing ? (
            <Fragment>
              <Row>
                { this.props.invoice_type === "accessorial_charges" ? (
                  <Tooltip title={isEditing ? tooltipTitle : ""}>
                    <Select
                      value={ record.accessorial_code }
                      showSearch
                      filterOption={ (input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      className="width100Per"
                      size="small"
                      disabled={isEditing} // Disable when editing
                      onChange={ (e) => {
                        this.props.handleOnChange(
                          index,
                          "accessorial_code",
                          e,
                          this.props.currentSection.order_number
                        );
                        this.setState({ invoiceName: e, invoiceCode: record.gl_code });
                      }}
                    >
                      <Select.Option value="">Select accessorial</Select.Option>
                      { this.props.reqData.map((reqRecord) => (
                        <Select.Option
                          key={ reqRecord.accessorial_code }
                          value={ reqRecord.accessorial_code }
                        >
                          { reqRecord.accessorial_name }
                        </Select.Option>
                      )) }
                    </Select>
                  </Tooltip>
                ) : (
                  <Tooltip title={isEditing ? tooltipTitle : ""}>
                    <Input
                      value={ this.state.invoiceName }
                      size="small"
                      disabled={isEditing} // Disable when editing
                      onChange={ (e) =>
                        this.setState({ invoiceName: e.target.value })
                      }
                    />
                  </Tooltip>
                )
                }
              </Row>
              { !isEmpty(this.state.errorName) && isEmpty(this.state.invoiceName) ? <Row><span style={ { color: "red", fontSize: "13px" } }>{ this.state.errorName }</span></Row> : "" }
            </Fragment>
          ) : (
            <Fragment>
              { data }{ " " }
              { !isEmpty(record.item_description) ? (
                <i className="fontSize10">{ record.item_description }</i>
              ) : (
                ""
              ) }{ " " }{ record.added_from === "MANUAL" && <sup className="textRed textBold">M</sup> }
            </Fragment>
          );        },
        width: "20%"
      },
      {
        key: "location_id",
        title: I18n.t("general.order_type"),
        dataIndex: "location_id",
        render: (data, record, index) => {

          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          return (
            <LocationSelect 
              orderData={orderData}
              isSaved={this.state.isSaved}
              record={record}
              index={index}
              handleOnChange={this.props.handleOnChange}
              currentSection={this.props.currentSection}
              isEditing={isEditing}
              listData={this.props.data}
            />
          );
        },
        width: "10%"
      },
      {
        key: "gl_code",
        title: I18n.t("invoices.gl_code"),
        dataIndex: "gl_code",
        render: (data, record, index) => {
          const currentAccessorialRecord = this.props.accessorials.find(rec => rec.accessorial_code === record.accessorial_code)
          const currentAccessorialHasGlCode = !_.isEmpty(currentAccessorialRecord) ? !isEmpty(currentAccessorialRecord.gl_code): false
          const dataSource = this.props.data;
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const child = record.isNew && !this.state.isSaved || isEditing ? (
            // this.props.invoice_type === "accessorial_charges" ? (
            //   <Fragment>
            //     <TextArea
            //       rows={1}
            //       size="small"
            //       placeholder={I18n.t("general.description")}
            //       value={record.invoice_name}
            //       onChange={(e) =>
            //         this.props.handleOnChange(
            //           index,
            //           "invoice_name",
            //           e.target.value,
            //           this.props.currentSection.order_number
            //         )
            //       }
            //     />
            //   </Fragment>
            // ) : (
            <Fragment>
              {/* <Input
                  type="text"
                value={this.state.invoiceCode}
                  placeholder={I18n.t("invoices.gl_code")}
                  size="small"
                  style={{ width: "75%" }}
                onChange={(e) => {
                  this.setState({ invoiceCode: e.target.value })
                }
                }
                /> */}

              <GLCodeSelect
                invoiceType="accessorial_charges"
                data={ this.props.glCodesList || [] }
                value={ this.state.invoiceCode }
                onChange={ (e) => {
                  this.setState({ invoiceCode: e });
                  this.props.handleOnChange(
                    index,
                    "gl_code",
                    e,
                    this.props.currentSection.order_number
                  );
                } }
                selectKey={ "gl_code" }
                selectValue={ "gl_code" }
                selectedValue={ "gl_code" }
                selectedCode={ "description" }
                style={ { width: 120 } }
                showSearch={ true }
                placeholder={ I18n.t("invoices.gl_code") }
                dropdownMatchSelectWidth={ false }
                size="small"
                currentAccessorialHasGlCode={currentAccessorialHasGlCode || !isEmpty(record.id)}
              />
              { !isEmpty(this.state.glCodeError) && isEmpty(this.state.invoiceCode) ? <Row><span style={ { color: "red", fontSize: "13px" } }>{ this.state.glCodeError }</span></Row> : "" }
            </Fragment>
            // )
          ) : (
            <Fragment>
              { data || "" }
              {/* {record.order_item_group_id} */ }
            </Fragment>
          );
          const obj = {
            children: child,
            props: {}
          };
          // if(!isEmpty(record.order_item_group_id)){
          //   obj.props.rowSpan = this.findSpanValue(this.props.data, record, index)
          //   return obj;
          // }
          return obj;
        },
        width: "31%"
      },
      {
        key: "invoice_value",
        title: I18n.t("invoices.amount"),
        dataIndex: "invoice_value",
        render: (data, record, index) => {
          const isEditing = this.state.recordForEdit?.id === record.id && record.isEditing;
          const invValue = _.get(this.state.recordForEdit, 'invoice_value', null);
          const isHavingZeroAmount = _.toNumber(invValue) == 0;
          const children = record.isNew && !this.state.isSaved || isEditing ? (
            <Fragment>
              { (((isEditing || (props.isActiveRecordFlatCharge)) && props.isActiveRecordFlatCharge) || (isEditing && !props.isActiveRecordFlatCharge && !isHavingZeroAmount)) && (
                <Input
                  autoFocus={ true }
                  type="number"
                  // value={record.invoice_value || this.state.invoiceAmount }
                  value={this.state.invoiceAmount}
                  // value={data}
                  placeholder={ I18n.t("invoices.amount") }
                  size="small"
                  style={ { width: "85%" } }
                  addonBefore={ I18n.t("general.price_symbol") }
                  onChange={ (e) => {
                    this.setState({ invoiceAmount: e.target.value });
                    if (this.props.invoice_type === "accessorial_charges") {
                      this.props.handleOnChange(
                        index,
                        "invoice_value",
                        e.target.value,
                        this.props.currentSection.order_number
                      );
                    }
                  }
                  }
                />
              ) }
              { !isEmpty(this.state.error) && isEmpty(this.state.invoiceAmount) ? <Row><span style={ { color: "red", fontSize: "13px" } }>{ this.state.error }</span></Row> : "" }
            </Fragment>
          ) : (
            <Fragment>{ checkNegitive(data) }</Fragment>
          );
          const obj = {
            children,
            props: {}
          };
          if (!isEmpty(record.order_item_group_id)) {
            obj.props.rowSpan = this.findSpanValue(this.props.data, record, index);
            return obj;
          }
          return obj;
        },
        align: "right",
        width: "10%",
      },
      {
        key: "actions",
        title: "",
        dataIndex: "isNew",
        render: (data, record, index) => {
          const currentRowIndex = _.findIndex(this.state.uniqRows, rec => rec.id === record.id || (!_.isEmpty(record.order_item_group_id) && rec.order_item_group_id === record.order_item_group_id));
          const isLastRow = currentRowIndex < 0 ? true : currentRowIndex + 1 === this.state.uniqRows.length;
          const checkLocationLength = _.get(this.props.orderData, 'locations.length', 0) > 1;
          const showCharges = (this.props.showAddIcon && isLastRow && this.props.hasNew && this.props.showActions === true) || (this.props.showAddIcon && isLastRow && this.props.hasNew && (!this.props.isLocked));
          const isEditing = _.get(this.state, 'recordForEdit.id') === record.id && record.isEditing;
          const locationData = _.find(this.props.orderData?.locations, { location_id: record.location_id });
          const ObtainedVal = _.get(locationData, 'location_id');

          const children = this.props.isLocked ? null :
            ((record.isNew && !this.state.isSaved) ? (
              <div className="line-actions">
                { !_.isEmpty(record.accessorial_code) ? (
                  (this.props.isActiveRecordFlatCharge && record.isNew) &&
                    (
                      <Row type="flex" align="middle" gutter={ 8 }>
                        <Col>
                          { checkServiceExistance("CINL") && (
                            <Tooltip placement="topLeft" title="Save">
                              <Icon type="save" theme="twoTone" className="fontSize14 cusorPointer"
                                onClick={ () => {
                                  if (isEmpty(this.state.invoiceAmount) || isEmpty(this.state.invoiceName) || isEmpty(record.location_id) || isEmpty(this.state.invoiceCode)) {
                                    this.setState({
                                      error: isEmpty(this.state.invoiceAmount) ? "Cannot be blank" : "",
                                      glCodeError: isEmpty(this.state.invoiceCode) ? "Cannot be blank" : "",
                                      errorName: isEmpty(this.state.invoiceName) ? (this.props.invoice_type === "accessorial_charges" ? "Select the accessorial" : "Cannot be blank") : "",
                                      orderError: isEmpty(record.location_id) ? "Cannot be blank" : ""

                                    });
                                  } else {
                                    this.props.saveItem({
                                      invoice_name: this.state.invoiceName,
                                      gl_code: this.state.invoiceCode,
                                      invoice_quantity: this.state.invoiceQuantity,
                                      invoice_value: this.state.invoiceAmount,
                                } , index, this.props.invoice_type, this.props.currentSection.order_number, );
                                    this.setState({
                                      invoiceName: "",
                                      invoiceCode: "",
                                      invoiceQuantity: 1,
                                      invoiceAmount: "",
                                      isSaved: true,
                                      error: "",
                                      glCodeError: "",
                                      errorName: "",
                                      orderType: checkLocationLength ? "" : this.props.orderData?.locations[ 0 ]?.location_id,
                                      orderError: ""
                                    });
                                  }

                                }
                                } />
                            </Tooltip>
                          ) }
                        </Col>
                        <Col>
                          { checkServiceExistance("BLDINVI") && (
                            <Tooltip placement="topLeft" title="Delete">
                              <Popconfirm
                                placement="left"
                                title={ I18n.t("messages.delete_confirm") }
                                onConfirm={ () => {
                                  this.props.deleteClick(
                                    index,
                                    this.props.invoice_type,
                                    this.props.currentSection.order_number
                                  );
                                  this.setState({
                                    invoiceName: "",
                                    invoiceCode: "",
                                    invoiceQuantity: 1,
                                    invoiceAmount: "",
                                    error: "",
                                    glCodeError: "",
                                    errorName: '',
                                    orderType: "",
                                    orderError: ""
                                  });

                                }
                                }
                                okText="Yes"
                                cancelText="No"
                              >
                                <Icon type="close" style={ { fontSize: 14 } } />
                              </Popconfirm>
                            </Tooltip>
                          ) }
                        </Col>
                      </Row>
                    ) 
                ) : (
                  <Col>
                    { checkServiceExistance("BLDINVI") && (
                      <Button
                        type="link"
                        onClick={ () => {
                          this.props.deleteClick(
                            index,
                            this.props.invoice_type,
                            this.props.currentSection.order_number
                          );
                          this.setState({
                            invoiceName: "",
                            invoiceCode: "",
                            invoiceQuantity: 1,
                            invoiceAmount: "",
                            error: "",
                            glCodeError: "",
                            errorName: '',
                            orderType: "",
                            orderError: ""
                          });
                        } }
                        style={ { fontSize: 14 } }
                        icon="close"
                      >
                        Cancel
                      </Button>
                      ) }
                  </Col>
                ) }
              </div>
            ) : this.renderCommonActions(record, index, isEditing, showCharges, ObtainedVal, checkLocationLength)); 

          const obj = {
            children,
            props: {}
          };
          if (!_.isEmpty(record.order_item_group_id)) {
            obj.props.rowSpan = this.findSpanValue(this.props.data, record, index);
          }
          return obj;
        },

        // width: "10%",
        // align: "right",
      },
    ];
    this.columns = [ ...columns ];
  };

  findSpanValue = (dataSource, record, index) => {
    const valueIndex = _.findIndex(dataSource, { order_item_group_id: record.order_item_group_id });
    if (index === valueIndex) {
      const rowSpan = dataSource.filter(rec => rec.order_item_group_id === record.order_item_group_id).length;
      return rowSpan;
    }
    return 0;
  };

  componentDidMount () {
    if (this.props.invoice_type === "accessorial_charges") {
      const accessorialCodeColumn =
      {
        key: "accessorial_code",
        title: I18n.t("general.code"),
        dataIndex: "accessorial_code",
        render: (data, record, index) => data || "",
        width: 100,
        align: "left",
      };

      this.columns.splice(2, 0, accessorialCodeColumn);
    }
    if (this.props.orderData && this.props.orderData?.locations?.length === 1) {
      this.setState({ orderType: this.props.orderData?.locations[ 0 ]?.location_id });
    }
    this.setUniqData();
  }

  setUniqData = () => {
    const dataSource = this.props.data || [];
    const uniqRows = [];
    dataSource.forEach(rec => {
      if (!isEmpty(rec.order_item_group_id)) {
        if (_.findIndex(uniqRows, { order_item_group_id: rec.order_item_group_id }) < 0) {
          uniqRows.push(rec);
        }
      } else {
        uniqRows.push(rec);
      }
    });
    this.setState({
      uniqRows,
      isSaved: false
    });
  };


  componentDidUpdate (prevProps, prevState) {
    let newState = {};

    const newRecord = this.props.data.find(rec => rec.isNew);
    if (!equal(prevProps.data, this.props.data)) {
      this.setUniqData();
      this.columns = [];
      this.setColumns(this.props);
      // const newRecord = this.props.data.find(rec => rec.isNew);
      if (newRecord) {
        newState = {
          ...newState,
          invoiceAmount: isEmpty(newRecord.accessorial_code) ? "" : !equal(prevState.invoiceAmount, this.state.invoiceAmount) ? newRecord.invoice_value : this.state.invoiceAmount,
          invoiceCode: isEmpty(newRecord.accessorial_code) ? "" : newRecord.gl_code,
          orderType:  isEmpty(newRecord.accessorial_code) ? "" : newRecord.location_id
        };
      }
    }

    if ((!equal(prevProps.currentAccCost, this.props.currentAccCost) || !equal(prevState.invoiceName , this.state.invoiceName)) || !equal(prevProps.isActiveRecordFlatCharge, this.props.isActiveRecordFlatCharge)) {
      this.setUniqData();
      this.columns = [];
      this.setColumns(this.props);
      if (newRecord) {
        const { isActiveRecordFlatCharge, currentAccCost } = this.props;
        const invoiceAmount = isActiveRecordFlatCharge ? currentAccCost : newRecord.invoice_value;
        newState = { ...newState, invoiceAmount: invoiceAmount };
      }
    }

    // if ((!equal(prevProps.currentAccCost, this.props.currentAccCost) || !equal(prevState.invoiceName , this.state.invoiceName)) || !equal(prevProps.isActiveRecordFlatCharge, this.props.isActiveRecordFlatCharge)) {
    //   this.setUniqData();
    //   this.columns = [];
    //   this.setColumns(this.props);
    //   // if (!equal(prevState.invoiceName, this.state.invoiceName) && Object.keys(prevState.recordForEdit).length > 0) {
    //     // check if recordedit is object first then keys
    //     // if(_.isEmpty(this.state.recordForEdit) && !equal(prevState.invoiceName, this.state.invoiceName)){
    //     const isValidRecordForEdit = _.isObject(prevState.recordForEdit) && Object.keys(prevState.recordForEdit).length > 0;
    //     if(isValidRecordForEdit && !equal(prevState.invoiceName, this.state.invoiceName)){
    //     if (newRecord) {
    //       const { isActiveRecordFlatCharge, currentAccCost } = this.props;
    //       const invoiceAmount = isActiveRecordFlatCharge ? currentAccCost : newRecord.invoice_value;
    //       newState = { ...newState, invoiceAmount: invoiceAmount };
    //     }
    //   }
    //   else {
    //     // its in edit
    //     // const { isActiveRecordFlatCharge, currentAccCost } = this.props;
    //     // const invoiceAmount = isActiveRecordFlatCharge ? currentAccCost : newRecord.invoice_value;
    //     // newState = { ...newState, invoiceAmount: invoiceAmount };
    //     //
    //   }
    // }

    if (!equal(prevProps.clickedSave, this.props.clickedSave) && this.props.clickedSave) {
      const findIndex = this.props.data.findIndex(rec => rec.isNew);
      const { accessorials } = this.props;
      const currentState = {
        invoice_name: accessorials.find(rec => rec.accessorial_code === this.state.invoiceName)?.accessorial_name || this.state.invoiceName,
        gl_code: this.state.invoiceCode || "",
        invoice_quantity: this.state.invoiceQuantity,
        invoice_value: this.state.invoiceAmount,
        location_id: this.state.orderType
      }

      if (isEmpty(this.state.orderType)) {
        alertMessage("Please select the order type", "error");
        this.props.setClickedSave(false);
        return;
      }

      this.props.saveItem(currentState);
    }

    if (!equal(prevProps.removeEditingVals, this.props.removeEditingVals)) {
      newState = {
        ...newState,
        recordForEdit: null,
        invoiceName: "",
        invoiceCode: "",
        invoiceQuantity: 1,
        invoiceAmount: "",
        orderType: "",
        error: "",
        glCodeError: "",
        errorName: "",
        orderError: "",
      }
    }

    if (!equal(prevProps.clickedCancel, this.props.clickedCancel)) {
      const isEditing = this.state.recordForEdit?.isEditing;
      if (this.props.clickedCancel) {
        let findIndex = this.props.data.findIndex(rec => rec.isNew);
        const isParamEdit = this.props.data.findIndex(rec => rec.isEditing);
        if(isParamEdit > -1){
          this.props.deleteClick(
            isParamEdit,
            this.props.invoice_type,
            this.props.currentSection.order_number
          );
        }
        else if (!isEditing && isEditing !== undefined) {
          this.props.deleteClick(
            findIndex,
            this.props.invoice_type,
            this.props.currentSection.order_number
          );
        }
        newState = {
          ...newState,
          invoiceName: "",
          invoiceCode: "",
          invoiceQuantity: 1,
          invoiceAmount: "",
          error: "",
          glCodeError: "",
          errorName: '',
          orderType: "",
          orderError: ""
        };
      }
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }
}

export default AccesorialList;

AccesorialList.propTypes = {
  isAdjusted: PropTypes.bool,
}

AccesorialList.defaultProps = {
  isAdjusted: false
}
import React, { Component, Fragment } from 'react'
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import { groupItems, updateItem } from '../../api/OrdersApi'
import { alertMessage, isEmpty, randomNumber } from '../../common/Common'
import { Link, Card, Row, Col, Icon, Button, Popconfirm, Spin } from '../../common/UIComponents'
import FormButtons from '../common/FormButtons'
import { formatDimension } from '../../helpers/orders'
import AppConfig from '../../config/AppConfig'
import I18n from '../../common/I18n'

class ItemGrouping extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items || [],
      customer_order_id: props.customer_order_id || '',
      item_type: '',
      accountDim: {},
      isLoading: false,
      groups: {},
      isModified: false,
    }
  }

  componentDidMount() {
    this.setGroups()
  }

  getListStyle = (isDraggingOver) => ({
    //background: isDraggingOver ? '#e8e8e8' : 'white',
    // padding: grid,
    // height: isDraggingOver ? 75 : "auto",
    borderStyle: isDraggingOver ? "dashed" : "",
    padding: '10px 6px',
    fontSize: '12px !important',
  });

  setGroups = () => {
    const items = this.state.items
    const groups = {}
    items.forEach((item) => {
      if (isEmpty(item.item_group_id)) {
        const key = _.uniqueId()
        groups[key] = [item]
      } else {
        if (isEmpty(groups[item.item_group_id])) {
          groups[item.item_group_id] = []
        }
        groups[item.item_group_id].push(item)
      }
    })
    this.setState({ groups })
  }

  fetchAccountConfigs() {
    if (Object.keys(this.props.account).length > 0) {
      this.getAccountConfigs()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.account !== this.props.account) {
      this.fetchAccountConfigs()
    }
  }

  unGroup = (grp, item_id) => {
    const groups = _.cloneDeep(this.state.groups)
    if (groups[grp]) {
      const items = [...groups[grp]]
      const itemIndex = _.findIndex(items, { item_id: item_id })
      const key = randomNumber(5)
      groups[key] = []
      if (itemIndex >= 0) {
        groups[key].push(items[itemIndex])
        groups[grp].splice(itemIndex, 1)
        if (groups[grp].length === 0) {
          delete groups[grp]
        }
        this.setState({
          groups,
        })
      }
    }
  }

  onDragEnd = (result) => {
    const groups = { ...this.state.groups }
    if (!result.destination) {
      return
    }
    if (result.source) {
      const sourceObj = result.source
      const destinationObj = result.destination
      const sourceGroup = sourceObj.droppableId
      const destinationGroup = destinationObj.droppableId
      const draggableId = result.draggableId
      const items = [...groups[sourceGroup]]
      const itemIndex = _.findIndex(items, { item_id: draggableId })
      if (itemIndex >= 0) {
        if (isEmpty(groups[destinationGroup])) {
          groups[destinationGroup] = []
        }
        groups[destinationGroup].push(items[itemIndex])
        if (!isEmpty(groups[sourceGroup] && groups[sourceGroup][itemIndex])) {
          groups[sourceGroup].splice(itemIndex, 1)
        }
        if (groups[sourceGroup].length === 0) {
          delete groups[sourceGroup]
        }
        this.setState({
          groups,
        })
      }
    }
  }

  onReset = () => {
    this.setGroups()
  }
  onCancel = () => {
    this.props.onCancel();
  }

  handleSave = () => {
    this.setState({ isLoading: true })
    const groupedItems = []
    Object.keys(this.state.groups).forEach((grp) => {
      let items = this.state.groups[grp].map((item) => item.item_id)
      items = _.flatten(items)
      if (items.length === 1) {
        groupedItems.push(items)
      } else {
        groupedItems.push(items)
      }
    })
    const data = {
      customer_order_id: this.state.customer_order_id,
      group_order_item_ids: groupedItems,
    }
    groupItems(data)
      .then((result) => {
        if (result.success) {
          const items = result.order_items || [];
          alertMessage(I18n.t('messages.saved'));
          this.props.updateItems(items);
        } else {
          alertMessage(result.errors, 'error', 10)
        }
      })
      .finally(() => {
        this.setState({ isLoading: false })
      })
  }

  render() {
    const { items, groups, isLoading } = this.state
    return (
      <div className="item-grouping">
        <Spin spinning={isLoading}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Card size="small" bodyStyle={{ fontSize: '12px !important'}}>
              <Row className="textBold alignLeft headings" gutter={8}>
                <Col xs={4}>{I18n.t('general.name')}</Col>
                <Col xs={1}>{I18n.t('order.pieces')}</Col>
                <Col xs={2}>{I18n.t('order.model_no')}</Col>
                <Col xs={3}>{I18n.t('order.serial_no')}</Col>
                <Col xs={2}>{I18n.t('order.weight')}</Col>
                <Col xs={1}>{I18n.t('order.pallets')}</Col> {/* Add Pallets column */}
                <Col xs={2}>{I18n.t('order.wh_dock')}</Col>
                <Col xs={4}>{I18n.t("order.dimensions")}&nbsp;(<small>L * W * H</small>)</Col>
                <Col xs={2}>{I18n.t("configurations.dim_factor")}</Col>
                <Col xs={2}>{I18n.t("configurations.dim_weight")} ({AppConfig.weight_unit})</Col>
                <Col xs={2}></Col>
              </Row>
            </Card>
            {Object.keys(groups).map((grp) => {
              return (
                <Droppable droppableId={`${grp}`}>
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <Card size="small" className="boxShadow" bodyStyle={this.getListStyle(snapshot.isDraggingOver)}>
                        {groups[grp].map((item, index) => (
                          <Draggable
                            key={item.item_id}
                            draggableId={item.item_id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Row className={`alignLeft ${index === groups[grp].length-1 ? '': 'marginBottom5'}`} gutter={8}>
                                  <Col xs={4}>
                                    <Icon
                                      type="menu"
                                      className="textBold marginRight10"
                                      style={{
                                        width: 14,
                                      }}
                                    />
                                    {item.item_name}
                                  </Col>
                                  <Col xs={1}>{item.item_quantity}</Col>
                                  <Col xs={2}>{item.item_model}</Col>
                                  <Col xs={3}>{item.serial_number}</Col>
                                  <Col xs={2}>{item.item_weight}</Col>
                                  <Col xs={1}>{item.pallets || 0}</Col> {/* Add Pallets display */}
                                  <Col xs={2}>{item.wh_dock}</Col>
                                  <Col xs={4}>
                                      {formatDimension(
                                          item.item_length,
                                          item.item_length_uom,
                                          item.item_width,
                                          item.item_width_uom,
                                          item.item_height,
                                          item.item_height_uom
                                      )}
                                  </Col>
                                  <Col xs={2}>{item.dim_factor}</Col>
                                  <Col xs={2}>{item.dim_weight}</Col>
                                  <Col xs={2}>
                                    {groups[grp].length > 1 && (
                                      <Link
                                        onClick={() =>
                                          this.unGroup(grp, item.item_id)
                                        }
                                        className="textBold"
                                      >
                                        <span className="textBold">Ungroup</span>
                                      </Link>
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      </Card>
                      {provided && provided.placeholder}
                    </div>
                  )}
                </Droppable>
              )
            })}
          </DragDropContext>
          {Object.keys(groups).length > 0 && <div className='alignCenter marginTop20'>
              <Popconfirm
                placement="topRight"
                title={I18n.t("general.are_you_sure")}
                onConfirm={this.onCancel}
                okText="Yes"
                cancelText="No"
              >
                  <Button type="default" size="small" className="marginRight10">{I18n.t('general.cancel')}</Button>
              </Popconfirm>
              <Popconfirm
                placement="topRight"
                title={I18n.t("general.are_you_sure")}
                onConfirm={this.onReset}
                okText="Yes"
                cancelText="No"
              >
                  <Button type="danger" size="small" className="marginRight10">{I18n.t('general.reset')}</Button>
              </Popconfirm>
          
              <Button type="primary"  size="small" isLoading={this.state.isLoading} onClick={this.handleSave}>{I18n.t('general.save')}</Button>
              {/* {FormButtons(this.state.inProgress, this.handleSave, )} */}
          </div>
          }
        </Spin>
      </div>
    )
  }
}
export default ItemGrouping

ItemGrouping.propTypes = {
  updateItems: PropTypes.func,
  customer_order_id: PropTypes.string,
  onCancel: PropTypes.func,
  items: PropTypes.array,
}
ItemGrouping.defaultProps = {
  customer_order_id: '',
  onCancel: () => {},
  updateItems: () => {},
  items: [],
}

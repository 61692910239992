import React, { Component, Fragment } from "react";
import { Form, Row, Col, Select, Input, Button, Alert, Radio, Space } from "antd";
import PropTypes from "prop-types";
import userStore from "../../stores/UserStore";
import { fetchAccountDims } from "../../api/Account";
import AppConfig from "../../config/AppConfig";
import { createItem, updateItem } from "../../api/OrdersApi";
import { alertMessage, isEmpty } from "../../common/Common";
import _ from "lodash";
import I18n from "../../common/I18n";
import { convertToKilograms, convertToPounds, getWeightUnits } from "../../helpers/common";
const { Option } = Select;

const decimalPlaces = Number(window.localStorage.getItem("round_off_decimals")) || 2;

class DIMSInput extends React.Component {
  handleNumberChange = (e, element )=> {
    const number = parseInt(e.target.value || 0, 10);
    if (isNaN(number)) {
      return;
    }
    this.triggerChange({ [element]: number });
  };

  // handleCurrencyChange = currency => {
  //   this.triggerChange({ currency });
  // };

  triggerChange = changedValue => {
    const { onChange, value,handleDimCalculation } = this.props;
    if (onChange) {
      onChange({
        ...value,
        ...changedValue,
      });
    }
    if(handleDimCalculation){
      handleDimCalculation('dim',{
        ...value,
        ...changedValue,
      });
    }
  };

  render() {
    const { size, value,length } = this.props;
    return (
      <span>
        L:&nbsp;
        <Input
          type="text"
          size={size}
          value={value.item_length}
          onChange={(e) => this.handleNumberChange(e, 'item_length')}
          style={{ width: 58, marginRight: 3 }}
        />
        X&nbsp;
        W:&nbsp;
        <Input
          type="text"
          size={size}
          value={value.item_width}
          onChange={(e) => this.handleNumberChange(e, 'item_width')}
          style={{ width: 58, marginRight: 3 }}
        />
        X&nbsp;
        H:&nbsp;
        <Input
          type="text"
          size={size}
          value={value.item_height}
          onChange={(e) => this.handleNumberChange(e, 'item_height')}
          style={{ width: 58, marginRight: 3 }}
        />
        <Select
          value={length}
          defaultValue={"inch"}
          size={size}
          style={{ width: '18%' }}
          onChange={this.props.handleLengthChange}
          dropdownMatchSelectWidth={false}
        >
          <Option value="inch">inch</Option>
          <Option value="cm">cm</Option>
          <Option value="feet">feet</Option>
        
        </Select>
      </span>
    );
  }
}
class ItemDetails extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isEditing: false,
      item_type: "",
      accountDim: {},
      isLoading: false,
      customer_order_id: "",
      uom: "lbs",
      length: "inch",
      weight_type: "individual",
      stops: "",
    };
  }

  componentDidMount () {
    this.fetchAccountConfigs();
    if (!this.state.isEditing) {
      this.assignItemType();
    }
  }

  handleLengthChange = (value) => {
    this.setState({ length: value });
  }

  convertLengthToInches = (e, length) => {
    let inches;
  
    switch (length) {
        case 'inch':
            inches = e;
            break;
        case 'cm':
            inches = (e * 0.393701).toFixed(2);
            break;
        case 'feet':
            inches = (e * 12).toFixed(2);
            break;
        default:
            console.log('Invalid length unit');
            return;
    }
    return inches;
}

  fetchAccountConfigs () {
    if (Object.keys(this.props.account).length > 0) {
      this.getAccountConfigs();
    }
  }

  componentDidUpdate (prevProps) {
    if ((prevProps.account !== this.props.account)) {
      this.fetchAccountConfigs();
    }
    if (this.state.isNew && prevProps.order !== this.props.order) {
      this.assignItemType();
    }

    if (prevProps.currentItem !== this.props.currentItem && Object.keys(this.props.currentItem).length > 0) {
      this.setState({
        isEditing: true,
        item_type: this.props.currentItem.item_type,
        customer_order_id: this.props.currentItem.customer_order_id,
      });
      const {
        item_name,
        item_model,
        serial_number,
        wh_dock,
        dim_factor,
        item_height,
        item_quantity,
        item_weight,
        item_weight_in_kgs,
        item_width,
        item_length,
        dim_weight,
        is_indv_weight,
        location_id,
        pallets
      } = this.props.currentItem;
      const weight_type = isEmpty(is_indv_weight) || is_indv_weight == 1 ? "individual" : "complete";
      const formVals = {
        item_name,
        item_model,
        serial_number,
        wh_dock,
        dim_factor,
        item_height,
        item_quantity,
        item_weight,
        item_weight_in_kgs,
        item_width,
        item_length,
        dim_weight,
        weight_type,
        location_id,
        pallets
      };
      this.props.form.setFieldsValue(formVals);
    }
  }

  handleOnChange = (element, value) => {
    const { form } = this.props;
    
    if (element === "item_weight") {
      const weightNum = Number(value);
      if (!isNaN(weightNum)) {
        const kgsValue = Number(convertToKilograms(weightNum, false));
        form.setFieldsValue({ 
          item_weight_in_kgs: kgsValue,
          item_weight: weightNum
        });
      }
    }
    
    if (element === "item_weight_in_kgs") {
      const weightNum = Number(value);
      if (!isNaN(weightNum)) {
        const lbsValue = Number(convertToPounds(weightNum, false));
        form.setFieldsValue({ 
          item_weight: lbsValue,
          item_weight_in_kgs: weightNum
        });
      }
    }

    let dimWeight = 0;
    const { getFieldsValue, getFieldValue } = this.props.form;
    const {
      dim_factor = 1,
      item_width = 1,
      item_height = 1,
      item_length = 1,
      item_weight = 0,
      item_quantity = 1,
      weight_type = "complete"
    } = getFieldsValue();
    const dim = getFieldValue('dim');
    let itemLength = _.get(dim, 'item_length', 1);
    let itemWidth = _.get(dim, 'item_width', 1);
    let itemHeight = _.get(dim,'item_height',1);
    let dimFactor = dim_factor;
    let itemQuantity = item_quantity || 1;
    let itemWeight= item_weight

    if (element === "item_length") {
      itemLength = value;
    }
    if (element === "item_width") {
      itemWidth = value;
    }
    if (element === "item_height") {
      itemHeight = value;
    }
    if (element === "dim_factor") {
      dimFactor = value;
    }
    if (element === "item_quantity") {
      itemQuantity = value;
    }
    if (element === "item_weight") {
      itemWeight = value
      form.setFieldsValue({ item_weight_in_kgs: convertToKilograms(value, false) });
    }
    if (element === "item_weight_in_kgs") {
      // itemWeight = value
      form.setFieldsValue({ item_weight: convertToPounds(value, false) });
    }

      if (!isEmpty((itemWidth)) || !isEmpty((itemHeight)) || !isEmpty((itemLength))
      && !isEmpty(dimFactor) && !isEmpty(itemQuantity)) {
      const configuredMinWeight = !isEmpty(this.state.accountDim.min_weight)
        ? this.state.accountDim.min_weight
        : 0;
      if ((configuredMinWeight === 0 || configuredMinWeight < itemWeight) && dim_factor > 0) {
        let dimsWeight = ((itemWidth * itemHeight * itemLength) / dimFactor).toFixed(2);
        dimWeight = itemQuantity * Math.ceil(dimsWeight);
      }
    }
    form.setFieldsValue({ dim_weight: dimWeight });
  };

  // calculateDimValues = () => {
  //   let dimWeight = 0;
  //   const { form } = this.props;
  //   const { getFieldsValue, getFieldValue } = this.props.form;
  //   const {
  //     dim_factor = 1,
  //     item_width = 1,
  //     item_height = 1,
  //     item_length = 1,
  //     item_weight = 0,
  //   } = getFieldsValue();

  //   if (!isEmpty(getFieldValue('item_width')) || !isEmpty(getFieldValue('item_height')) || !isEmpty(getFieldValue('item_length'))
  //     && !isEmpty(getFieldValue('dim_factor'))) {
  //     const configuredMinWeight = !isEmpty(this.state.accountDim.min_weight)
  //       ? this.state.accountDim.min_weight
  //       : 0;
  //     if (configuredMinWeight < item_weight && dim_factor > 0) {
  //       dimWeight = ((item_width * item_height * item_length) / dim_factor);
  //     }
  //   }
  //   form.setFieldsValue({ dim_weight: dimWeight });
  // };

  assignItemType = () => {
    const { order, form } = this.props;
    if (order.type_of_order !== "X") {
      const orderType = order.type_of_order === "R" || order.type_of_order === "M" ? "RETURN" : order.type_of_order === "D" ? "DELIVERY" : "";
      this.setState({ item_type: orderType });

    }
  };

  getAccountConfigs = () => {
    const { account, form } = this.props;
    const orgId = userStore.getStateValue("selectedOrg");
    const accountId =
      account.primary_account_id || account.id;
    fetchAccountDims(orgId, accountId).then((result) => {
      if (result.success) {
        const accountDims = result.configurations || [];
        const accountDim = accountDims.length > 0 ? accountDims[ 0 ] : {};
        const { dim_factor } = accountDim;
        const { getFieldValue } = this.props.form;
        if (isEmpty(
          getFieldValue("dim_factor")
        )) {
          form.setFieldsValue({ dim_factor });
        }
        this.setState(
          {
            accountDim,
          }
        );
      }
    });
  };

  getOrderID = () => {
    const { order, form, orderConfig } = this.props;
    const { item_type } = this.state;
    const currentItem = form.getFieldsValue();
    let orderId = "";
    let itemType = "";
    if (order.type_of_order === "T") {
      // itemType = item_type === "RETURN" ? "TR" : "TD";
      itemType =  "T";
    } else if (order.type_of_order === "LH") {
      itemType =  "LH";
    } else if (order.type_of_order === "X") {
      itemType = "X";
    } else if(order.type_of_order === "MS"){
      itemType = "MS";
    } else if(order.type_of_order === "M"){
      itemType = item_type === "RETURN" ? "TR" : "TD";
    } else {
      itemType = item_type === "RETURN" ? "R" : "D";
    }
    const detailsObj = _.find(orderConfig.orderTypes[order.type_of_order].types, {
      type_of_order: itemType,
    });
    if (!isEmpty(detailsObj)) {
      orderId = order[detailsObj.key] ? order[detailsObj.key].id : "";
    }
    return orderId;
  };

  checkLastItem = () => {
    const { items = [], order } = this.props;
    const { isEditing } = this.state;
    return !isEditing && order.quantity - 1 === items.length;
  };

  handleUOMChange = (value) => {
    this.setState({ uom: value });
  }

  convertWeightToLbs = (weight, uom) => {
    if (uom === 'kg') {
      return (Number(weight) * 2.20462262185).toFixed(2); // Convert kgs to lbs
    }
    if (uom === 'g') {
      return (Number(weight) * 0.00220462262185).toFixed(2); // Convert grams to lbs
    }
    if (uom === 'lbs'){
      return (Number(weight) * 1).toFixed(2);
    }
    if (typeof weight === 'number') {
      return weight.toFixed(2); 
    }
    // return "0.00"; 
  }


  handleSelectChange = (loc_order_sequence) => {
    const { validateFields } = this.props.form;
    validateFields((err, values) => {
      if (!err) {
        const location_id = this.getLocationIdBySequence(loc_order_sequence);
          this.handleCreateItem(values,location_id);
      }})
    
  };

  getLocationIdBySequence = (loc_order_sequence) => {
    const { order } = this.props;
  
    const location = order?.transfer_params?.locations.find(
      (loc) => loc.loc_order_sequence === loc_order_sequence
    );
  
    return location ? location.id : "";
  };


  handleCreateItem = (vals) => {
    const { account } = this.props;
    const accountUnitContext = this.props.accountUnitContext;
    const existingWeightUnit = _.get(accountUnitContext.accountWeightUnits, [account.code], 'lbs');

    // Get input weight
    const item_weight = vals.item_weight;
    const weightInLbs = vals.item_weight;
    // const weightInKgs = vals.item_weight_in_kgs;
    const lengthInInches = this.convertLengthToInches(vals.dim.item_length,this.state.length)
    const widthInInches = this.convertLengthToInches(vals.dim.item_width, this.state.length)
    const heightInInches = this.convertLengthToInches(vals.dim.item_height, this.state.length)
    const dimObject = _.pick(vals.dim, ['item_width', 'item_height', 'item_length']);

    // Parse weights before creating the item
    const itemWeight = Number(vals.item_weight) || 0;
    const itemWeightInKgs = Number(vals.item_weight_in_kgs) || 0;

    let currentItem = {...vals, 
      item_width:widthInInches,
      item_length:lengthInInches,
      item_height:heightInInches ,
      item_weight:itemWeight,
      item_weight_in_kgs:itemWeightInKgs,
      item_quantity: vals.item_quantity || 1,
      is_indv_weight: vals.weight_type === 'individual' ? 1 : 0,
      pallets: Number(vals.pallets) || 1, // Add pallets conversion
    };
    delete currentItem.dim;

    currentItem.account_id = account.id;
    this.setState({ isLoading: true });
    const isLastItem = this.checkLastItem();

    if (isEmpty(currentItem.customer_order_id)) {
      currentItem.customer_order_id = this.getOrderID();
    }
    currentItem.item_type = this.state.item_type;

    createItem(currentItem, !this.state.isEditing).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        const savedItems = result.order_items || [];

        // Convert weights back to account's preferred unit for display
        const items = savedItems.map((item) => {
          const displayWeight = existingWeightUnit === 'kgs'
            ? Math.ceil(convertToKilograms(item_weight, false))  // Convert to kgs
            : Math.ceil(Number(item_weight));      // Keep as lbs

          const displayTotalWeight = existingWeightUnit === 'kgs'
            ? Math.ceil(convertToKilograms((item.item_weight || item_weight)))
            : Math.ceil(Number(item.item_weight || item_weight));

          return Object.assign({}, item, {
            item_id: item.id,
            item_weight: item.item_weight,
            item_weight_in_kgs: item.item_weight_in_kgs || 0,
            total_weight: item.total_weight,
            total_weight_in_kgs: item.total_weight_in_kgs, // In account's preferred unit
          });
        });

        this.setState(
          {
            currentItem: {},
            isEditing: false,
          },
          () => {
            this.assignItemType();
          }
        );

        this.props.saveItem(
          items,
          isLastItem,
          result.order_area,
          result.order_quantity,
          result.order_weight,
          result.order_weight_in_kgs
        );
        this.props.updatePallets(result.order_pallets, this.props.currentOrderType)
        this.props.form.resetFields();
        this.setState({ uom: "lbs" });
      } else {
        alertMessage(result.errors, "error", 10);
      }
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  };

  handleSaveItem = (vals) => {
    const lengthInInches = this.convertLengthToInches(vals.dim.item_length, this.state.length);
    const widthInInches = this.convertLengthToInches(vals.dim.item_width, this.state.length);
    const heightInInches = this.convertLengthToInches(vals.dim.item_height, this.state.length);

    const { account } = this.props;
    const { weight_type } = (vals);

    let currentItem = {
      ...vals,
      item_width: widthInInches,
      item_length: lengthInInches,
      item_height: heightInInches,
      is_indv_weight: vals.weight_type === 'individual' ? 1 : 0,
      item_quantity: vals.item_quantity || 1,
      pallets: Number(vals.pallets) || 1, // Add pallets conversion
    };
    delete currentItem.dim;

    currentItem.account_id = account.id;
    if (isEmpty(currentItem.customer_order_id)) {
      currentItem.customer_order_id = this.state.customer_order_id;
    }
    currentItem.item_type = this.state.item_type;
    currentItem.weight_type = weight_type;

    this.setState({ isLoading: true });
    updateItem({
      ...this.props.currentItem,
      ...currentItem,
    }, !this.state.isEditing).then((result) => {
      if (result.success) {
        alertMessage(I18n.t("messages.saved"));
        const order_item = result.item || {};

        this.props.saveItem(
          {
            ...this.props.currentItem,
            ...currentItem,
            item_weight: order_item?.item_weight,
            item_weight_in_kgs: order_item?.item_weight_in_kgs,
            total_weight: order_item?.total_weight, 
            total_weight_in_kgs: order_item?.total_weight_in_kgs, 
          },
          true,
          result.order_area,
          result.order_quantity,
          result.order_weight,
          result.order_weight_in_kgs,
        );
        this.props.updatePallets(result.order_pallets, this.props.currentOrderType)
        this.setState({
          isEditing: false,
          customer_order_id: "",
        });
        this.props.form.resetFields();
        this.setState({ uom: "lbs" });
      } else {
        alertMessage(result.errors, "error", 10);
      }
    }).finally(() => { this.setState({ isLoading: false }); });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { validateFields } = this.props.form;
    validateFields((err, values) => {
      if (!err) {
        const isNew = !this.state.isEditing;
        if (isNew) {
          this.handleCreateItem(values);
        } else {
          this.handleSaveItem(values);
        }
      }
    });
  };

  renderField = (fieldName, labelName, isMandatory, isNumberInput, isDisabled = false, width = "100%", errMsg = '') => {
    const { getFieldDecorator } = this.props.form;
    let formOptions = {
      rules: [ { required: isMandatory, message: "Invalid Value" } ],
    };
    if (!isMandatory && !isNumberInput) {
      formOptions.initialValue = "";
    }
    return (
      <Form.Item
        style={ {
          marginBottom: "0px",
        } }
        label={ labelName }
      >
        { getFieldDecorator(fieldName, formOptions)(<Input
          disabled={ isDisabled }
          onChange={(e) => this.handleOnChange(fieldName, e.target.value)}
          // onChange={ () => {
          //   if (isNumberInput) {
          //     this.calculateDimValues();
          //   }
          // } }
          size={this.props.size}
          type={ isNumberInput ? "number" : "text" } 
          min={isNumberInput ? 1 : null}
          width={{width: "100%"}}
          />) }
      </Form.Item>
    );
  };

  checkDimValues = (rule, value, callback) => {
    if (value.item_height > 0 && value.item_length > 0 && value.item_width > 0) {
      return callback();
    }
    callback('Dim values must greater than zero!');
  };

  render () {
    const { form, ItemDetailsFormRef, currentItem , currentOrg = {}} = this.props;
    const { accountDim } = this.state;
    const
      { getFieldDecorator } = form;
    const { isEditing } = this.state;
    const dimFactor = form.getFieldValue('dim_factor');
    const hasDimFacitor = !isEmpty(dimFactor) ? true : false;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 18 },
        lg: { span: 16 },
        xl: { span: 16 },
        xxl: { span: 16 },
      },
    }
    const {dim={}} = currentItem;
    const existingWeightUnit = _.get(this.props.accountUnitContext.accountWeightUnits, [this.props.account.code], 'lbs');
    return (
      <Form
        {...formItemLayout}
        className="formHorizontal"
        onSubmit={this.onSubmit}
        title={"formTitle"}
      >
        <div className="paymentDetailsCard" ref={ItemDetailsFormRef}>
          <Row gutter={[16, 16]}>
            {/* Main Content Column - now spans full width */}
            <Col span={24}>
              {/* First Row */}
              <Row gutter={16}>
                {this.props.order.type_of_order === "MS" && (
                  <Col span={6}>
                    <Form.Item label="Stops">
                      {getFieldDecorator("location_id", {
                        initialValue: this.state.stops,
                        rules: [{ required: true, message: "Please select a Stop" }],
                      })(
                        <Select
                          size={this.props.size}
                          dropdownMatchSelectWidth={false}
                          onChange={this.handleSelectChange}
                        >
                          <Select.Option value="">--Select--</Select.Option>
                          {this.props.order.transfer_params?.locations.map(
                            (location) => (
                              <Select.Option key={location.id} value={location.id}>
                                {`Stop${location.loc_order_sequence + 1}`}
                              </Select.Option>
                            )
                          )}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col span={this.props.order.type_of_order === "MS" ? 6 : 6}>
                  {this.renderField("item_name", I18n.t("order.item_name"), false, false)}
                </Col>
                <Col span={this.props.order.type_of_order === "MS" ? 6 : 6}>
                  {this.renderField("item_model", I18n.t("order.model_no"), false, false)}
                </Col>
                <Col span={this.props.order.type_of_order === "MS" ? 6 : 6}>
                  {this.renderField("serial_number", I18n.t("order.serial_no"), false, false)}
                </Col>
                <Col span={this.props.order.type_of_order === "MS" ? 6 : 6}>
                  {this.renderField("wh_dock", I18n.t("order.wh_short_form_dock"), false, false)}
                </Col>
              </Row>

              {/* Second Row */}
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label={I18n.t("order.pcs")}>
                    {getFieldDecorator("item_quantity", {
                      normalize: (value) => (value ? Number(value) : value),
                    })(
                      <Input
                        type="number"
                        onChange={(e) => this.handleOnChange("item_quantity", e.target.value)}
                        size={this.props.size}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item label={ I18n.t("order.pallets") }>
                    { getFieldDecorator("pallets", {
                      normalize: (value) => (value ? Number(value) : value),
                    })(
                      <Input
                        type="number"
                        onChange={ (e) => this.handleOnChange("pallets", e.target.value) }
                        size={ this.props.size }
                        min={ 1 }
                      />
                    ) }
                  </Form.Item>
                </Col>
                <Col span={ 6 }>
                  <Form.Item label="Weight (lbs)">
                    {getFieldDecorator("item_weight", {
                      normalize: (value) => (value ? Number(value) : value),
                    })(
                      <Input
                        type="number"
                        onChange={(e) => this.handleOnChange("item_weight", e.target.value)}
                        size={this.props.size}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Weight (kgs)">
                    {getFieldDecorator("item_weight_in_kgs", {
                      normalize: (value) => (value ? Number(value) : value),
                    })(
                      <Input
                        type="number"
                        onChange={(e) => this.handleOnChange("item_weight_in_kgs", e.target.value)}
                        size={this.props.size}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="Weight Type">
                    {getFieldDecorator("weight_type", {
                      initialValue: this.state.weight_type,
                      rules: [{ required: true, message: "Please select a weight type" }],
                    })(
                      <Select
                        value={this.state.weight_type}
                        size={this.props.size}
                        dropdownMatchSelectWidth={false}
                      >
                        <Select.Option value="complete">Complete Weight</Select.Option>
                        <Select.Option value="individual">Individual Weight</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {/* Third Row */}
              <Row gutter={16}>
                <Col span={10}>
                  <Form.Item label="DIM" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
                    {getFieldDecorator("dim", {
                      initialValue: {
                        item_length: dim.item_length || 1,
                        item_width: dim.item_width || 1,
                        item_height: dim.item_height || 1,
                      },
                      rules: [{ validator: this.checkDimValues }],
                    })(
                      <DIMSInput
                        size="small"
                        length={this.state.length}
                        handleLengthChange={this.handleLengthChange}
                        handleDimCalculation={this.handleOnChange}
                      />
                    )}
                  </Form.Item>
                </Col>

                {accountDim && !isEmpty(accountDim.dim_factor) && (
                  <>
                    <Col span={6}>
                      <Form.Item label={`${I18n.t("configurations.dim_factor")}`}>
                        {getFieldDecorator("dim_factor", {
                          initialValue: accountDim?.dim_factor,
                          rules: [{ required: true, message: "Invalid Value" }],
                        })(
                          <Select
                            style={{ width: "100%" }}
                            onChange={(e) => this.handleOnChange("dim_factor", e)}
                            size="small"
                          >
                            {AppConfig.dimValues.map((key) => (
                              <Select.Option value={key} key={key}>
                                {key}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label={`${I18n.t("configurations.dim_weight")}`}>
                        {getFieldDecorator("dim_weight", {
                          rules: [{ required: true, message: "Invalid Value" }],
                        })(
                          <Input
                            type="number"
                            disabled={true}
                            size="small"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              {/* Fourth Row - Action Buttons */}
              <Row gutter={16} style={{ marginTop: 16 }}>
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.state.isLoading}
                    size={this.props.size}
                    style={{ marginRight: 8 }}
                    icon={isEditing ? "save" : "plus"}
                  >
                    {isEditing ? "Save" : "Add"}
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ isEditing: false });
                      this.props.form.resetFields();
                      this.props.clearActiveItem();
                      this.setState({ uom: "lbs" });
                    }}
                    size={this.props.size}
                    type="danger"
                    icon="close"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Alert Message Row */}
          {this.state.accountDim.dim_factor && (
            <Row style={{ marginTop: 16 }}>
              <Col span={24}>
                <Alert
                  message={
                    <Col className="notes_content">
                      Configured DIM values for the Account{" "}
                      <span className="">{this.props.account.name}</span>:{" "}
                      <span className="textBold">
                        {I18n.t("configurations.dim_factor")}:{" "}
                        {this.state.accountDim.dim_factor}
                      </span>
                      , &nbsp;&nbsp;
                      <span className="textBold">
                        {I18n.t("configurations.dim_min_weight_label")}:{" "}
                        {this.state.accountDim.min_weight
                          ? this.state.accountDim.min_weight.toFixed(
                              decimalPlaces
                            ) + " lbs"
                          : "NA"}
                        {" / "}
                      </span>
                      <span className="textBold">
                        {this.state.accountDim.min_weight_in_kgs
                          ? this.state.accountDim.min_weight_in_kgs.toFixed(
                              decimalPlaces
                            ) + " kgs"
                          : "NA"}
                      </span>
                    </Col>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
          )}
        </div>
      </Form>
    );
  }
}

const ItemDetailsForm = Form.create({ name: "ItemDetail" })(ItemDetails);
export default ItemDetailsForm;

ItemDetails.propTypes = {
  form: PropTypes.object,
  account: PropTypes.object,
  currentItem: PropTypes.object,
  order: PropTypes.object,
  saveItem: PropTypes.func,
  account: PropTypes.object,
  isItemNew: PropTypes.bool,
  items: PropTypes.array,
  accountUnitContext: PropTypes.object,
};
ItemDetails.defaultProps = {
  account: {},
  currentItem: {},
  order: {},
  saveItem: () => { },
  account: {},
  isItemNew: false,
  items: [],
  accountUnitContext: {},
};

import React, { createContext, useState, useEffect, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import { OrgContext } from "./OrgContext";
import I18n from "../common/I18n";
import { getFromToDate } from "../components/orders/helpers";

const OrderConfigContext = createContext({});

const getDefaultGlobalFilter = (fromDate, toDate) => {
  return {
    fromDate,
    toDate,
    filter: "VERIFIED",
    sortBy: "none",
    sortByType: "descend",
    search_key_type: "",
    search_type : "EQUALS",
    schedule_orders_type: "SCHEDULED",
    search_order_token : "",
    account_codes: [],
    zone_ids: [],
    order_type: "",
    vehicle_type: "",
    schedule_day_filter: "Current Week",
    search_key_value : "",
    created_by_ids: [],
    }
}

const OrderConfigContextProvider = ({ children }) => {
  const { fromDate, toDate } = getFromToDate('Current Week')
  const [ filterPlaceHolder, setFilterPlaceHolder ] = useState(getDefaultGlobalFilter(fromDate, toDate));
  const [paginationData, setPaginationData] = useState({});
  const [recordsPerPage, setRecordPerPage] = useState("");
  const [orderSelectedInfo, setOrderSelectedInfo] = useState([]);
  const [selectedException, setSelectedException] = useState("");
  const orgContextData = useContext(OrgContext);
  const { isAirCargo } = orgContextData;
  const isOrgAirCargo = isAirCargo();

  const statusForActions = {
    "SCHEDULE":  { status: ["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"], isIncludes : true },
    "MOVE_TO_RECEIVED":  { status: ["NEW"], isIncludes : true },
    "QUICK_DISPATCH":  { status: ["NEW", "RECEIVED", "VERIFIED"],isIncludes : true },
    "MOVE_TO_VERIFY":  { status: ["RECEIVED"],isIncludes : true },
    "CHANGE_STATUS":   { status: ["PENDING", "REJECTED"],isIncludes : false },
    "PRINT_LABELS":  { status: ["PENDING", "REJECTED"],isIncludes : false },
    "CLONE_ORDERS":  { status: ["NEW", "RECEIVED", "VERIFIED", "ON_HOLD"],isIncludes : true },
    "LINEHAUL":  { status: ["NEW", "RECEIVED", "VERIFIED"],isIncludes : true },
    "SWITCH_ORDERS":  { status: ["NEW", "RECEIVED", "VERIFIED"],isIncludes : true },
    "BULK_DELETE":  { status: [
      "NEW",
      "RECEIVED",
      "VERIFIED",
      "PENDING",
      "APPROVED",
      "REJECTED",
      "ON_HOLD",
    ],isIncludes : true },
    "DISPATCH_STATUS": {
      status: [
        "NEW",
        "RECEIVED",
        "VERIFIED",
        "PENDING",
        "APPROVED",
        "REJECTED",
        "ON_HOLD",
      ], isIncludes: false
    },
  }
  const orderConfig = {
    orderTypeKeysForFilter :  {
      "D,TD": "Delivery",
      "R,TR": isOrgAirCargo ? "Pickup" : "Return",
      "T": "Transfer",
      "LH": "Linehaul",
    },
    ORDER_TYPES:  {
      D: {
        label: "Delivery",
        symbol: "D",
      }
      , R: {
        label: isOrgAirCargo ? "Pickup" : "Return",
        symbol: "R",
      },
      M : {
        label: "Move",
        symbol: "M",
      },
      // LH : {
      //   label: "Linehaul",
      //   symbol: "LH",
      // },
      T : {
        label: "Transfer",
        symbol: "T",
      },
    },
    orderTypes: {
      D: {
        label: "Delivery",
        orderTypes: ["D"],
        is_location_based: false,
        types: [
          {
            type_of_order: "D",
            key: "drop_params",
            label: "Delivery",
            is_location_based: false,
          },
        ],
      },
      R: {
        label: isOrgAirCargo ? "Pickup" : "Return",
        orderTypes: ["R"],
        is_location_based: false,
        types: [
          {
            type_of_order: "R",
            key: "pickup_params",
            label: "Pickup",
            is_location_based: false,
          },
        ],
      },
      X: {
        label: "Exchange",
        orderTypes: ["R", "D"],
        is_location_based: false,
        types: [
          {
            type_of_order: "X",
            key: "exchange_params",
            label: "Order",
            is_location_based: false,
          },
        ],
      },
      MS: {
        label: "Multistop",
        orderTypes: ["MS"],
        is_location_based: true,
        types: [
          {
            type_of_order: "MS",
            key: "transfer_params",
            label: "Multistop",
            is_location_based: true,
          },
        ]
      },
       M: {
        label: "Move",
        orderTypes: ["TR", "TD"],
        is_location_based: false,
        types: [
          {
            type_of_order: "TR",
            key: "pickup_params",
            label: "Pickup",
            is_location_based: false,
          },
          {
            type_of_order: "TD",
            key: "drop_params",
            label: "Delivery",
             is_location_based: false,
          },
        ],
      },
      // LH: {
      //   label: "Linehaul",
      //   orderTypes: ["LH"],
      //   is_location_based: true,
      //   types: [
      //     {
      //       type_of_order: "LH",
      //       key: "linehaul_params",
      //       label: "Linehaul",
      //       is_location_based: true,
      //     },
      //   ],
      // },
      T: {
        label: "Transfer",
        orderTypes: ["T"],
        is_location_based: true,
        types: [
          {
            type_of_order: "T",
            key: "transfer_params",
            label: "Transfer",
            is_location_based: true,
          },
        ],
      },
      LH: {
        label: "Linehaul",
        orderTypes: ["LH"],
        is_location_based: true,
        types: [
          {
            type_of_order: "LH",
            key: "transfer_params",
            label: "Linehaul",
            is_location_based: true,
          },
        ],
      },
  
    },
    quoteOrderTypes: {
      D: {
        label: "Delivery",
        orderTypes: ["D"],
        is_location_based: false,
        types: [
          {
            type_of_order: "D",
            key: "drop_params",
            label: "Delivery",
            is_location_based: false,
          },
        ],
      },
      R: {
        label: isOrgAirCargo ? "Pickup" : "Return",
        orderTypes: ["R"],
        is_location_based: false,
        types: [
          {
            type_of_order: "R",
            key: "pickup_params",
            label: "Pickup",
            is_location_based: false,
          },
        ],
      },
      X: {
        label: "Exchange",
        orderTypes: ["R", "D"],
        is_location_based: false,
        types: [
          {
            type_of_order: "X",
            key: "exchange_params",
            label: "Order",
            is_location_based: false,
          },
        ],
      },
      M: {
        label: "Move",
        orderTypes: ["TR", "TD"],
        is_location_based: false,
        types: [
          {
            type_of_order: "TR",
            key: "pickup_params",
            label: "Pickup",
            is_location_based: false,
          },
          {
            type_of_order: "TD",
            key: "drop_params",
            label: "Delivery",
             is_location_based: false,
          },
        ],
      },
      T: {
        label: "Transfer",
        orderTypes: ["T"],
        is_location_based: true,
        types: [
          {
            type_of_order: "TR",
            key: "pickup_params",
            label: "Pickup",
            is_location_based: false,
          },
          {
            type_of_order: "TD",
            key: "drop_params",
            label: "Delivery",
             is_location_based: false,
          },
        ],
      },
      LH: {
        label: "Linehaul",
        orderTypes: ["LH"],
        is_location_based: true,
        types: [
          {
            type_of_order: "LH",
            key: "transfer_params",
            label: "Linehaul",
            is_location_based: true,
          },
        ],
      },
  
    },
    orderTypeLabels: {
      X: "Exchange",
      R: isOrgAirCargo ? "Pickup" : "Return",
      D: "Delivery",
      P: "Pickup",
      TD: "Delivery",
      TR: "Pickup",
      MD: "Delivery",
      MR: "Pickup",
      LH: "Linehaul",
      M: "Move",
      T: "Transfer",
    }
  }

  const getOrderStatus = (orderType) => {
    return ['TR', 'TD'].includes(orderType)
      ? orderConfig.orderTypes['M'].label
      : orderConfig.orderTypes[orderType]
      ? orderConfig.orderTypes[orderType].label
      : 'NA'
  }

  const getOrderKeys = (key = "id") => {
    return orderSelectedInfo ? orderSelectedInfo.map(o => o[key]) :[]
  }

  

  useEffect(() => {}, []);

  const contextValues = {
    statusForActions,
    filterPlaceHolder,
    orderConfig,
    setFilterPlaceHolder,
    paginationData,
    setPaginationData,
    recordsPerPage,
    setRecordPerPage,
    selectedException,
    setSelectedException,
    getOrderStatus,
    getDefaultGlobalFilter,
    orderSelectedInfo, 
    setOrderSelectedInfo,
    getOrderKeys,
    statusForActions,
  };

  return (
    <OrderConfigContext.Provider value={contextValues}>
      {children}
    </OrderConfigContext.Provider>
  );
};

export { OrderConfigContext, OrderConfigContextProvider };

import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Modal,
  Popconfirm,
  Checkbox,
} from "../../common/UIComponents";
import { alertMessage } from "../../common/Common";
import { _ } from "core-js";
import Copyable from "../common/Copyable";
import { postInvoiceToQb, validInvoices } from "../../api/BillingScreenAPI";
import I18n from "../../common/I18n";
const PostInvoiceToQB = ({
  buttonType,
  className,
  label,
  isDisabled,
  invoiceIds,
  size,
  handleSuccess,
  handleCancel,
  checkListData,
  enableChecklistApproval,
  selectedInfo,
  handleUnmount,
  indexList,
  accountInvoices,
}) => {
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showInvailidInvoices, setShowInvalidInvoices] = useState(false);
  const [data, setData] = useState([]);
  const [validData, setValidData] = useState([]);
  const [checkData, setCheckData] = useState(checkListData);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isMoveToValid, setIsMoveToValid] = useState(false);
  const [accountModalVisible, setAccountModalVisible] = useState(false);
  const [isEmailUnlocked, setIsEmailUnlocked] = useState(true);
  const resetValues = (closeModal = false) => {
    setLoading(false);
  };

  const showErrors = (errors) => {
    if (errors.length > 0) {
      const firstError = errors[0];
      let title = "";
      let content = "";
      if (_.isObject(firstError)) {
        const errorInvoices = errors.filter((err) => err.errors.length > 0);
        title = "Following invoices could not be processed";
        content = (
          <div>
            {errorInvoices.map((err) => (
              <Row key={err.frequency_invoice_number}>
                <Col xs={24}>
                  <span className="textRed">
                    {err.frequency_invoice_number}
                  </span>
                  : &nbsp;
                  <span>{err.errors.join(",")}</span>
                </Col>
              </Row>
            ))}
          </div>
        );
      } else {
        title = "Errors";
        content = (
          <div>
            <Row key={"error"}>
              <Col xs={24}>
                <span>{errors.join(",")}</span>
              </Col>
            </Row>
          </div>
        );
      }
      return Modal.error({
        title,
        content,
        width: "40%",
      });
    }
  };
  const handleSave = async (payload) => {
    const invoiceNos = selectedInfo.selectedRows.map(
      (invoice) => invoice.frequency_invoice_number
    );
    try {
      setLoading(true);
      let response = await postInvoiceToQb(payload);
      if (response.success) {
        const result = response?.invoices ? response.invoices : {};
        const successMessage = result?.message
          ? result.message
          : I18n.t("messages.saved");
        //  typeof onSave === 'function' && onSave(I18n.t('messages.saved'))
        alertMessage(successMessage);
        if (indexList && typeof handleSuccess === "function") {
          handleSuccess("", invoiceNos, false);
        } else if (typeof handleUnmount === "function") {
          handleUnmount(invoiceNos, false);
        }
        if (result?.errors && result.errors.length > 0) {
          showErrors(result.errors);
        }
        resetValues();
      } else {
        const errors = response.errors || [];
        showErrors(errors);
        resetValues();
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } finally {
      setLoading(false);
      setModalVisible(false);
    }
  };
  const handleSaveWithList = async () => {
    try {
      if (selectedIds.length === checkData.length) {
        const payload = {
          invoice_ids: invoiceIds,
          checkList_ids: selectedIds,
        };
        setLoading(true);
        let response = await validInvoices(payload);
        if (response.success) {
          const result = response?.invoices ? response.invoices : {};
          if (
            result.invalid_invoices.length > 0 &&
            result.valid_invoice_ids.length > 0
          ) {
            setModalVisible(false);
            setShowInvalidInvoices(true);
            setIsMoveToValid(true);
            setData(result.invalid_invoices);
            setValidData(result.valid_invoice_ids);
          } else if (result.invalid_invoices.length > 0) {
            setModalVisible(false);
            setShowInvalidInvoices(true);
            setData(result.invalid_invoices);
          } else if (result.valid_invoice_ids.length > 0) {
            const payload = {
              invoice_id: result.valid_invoice_ids.join(","),
            };
            handleSave(payload);
          }
        }
      } else {
        alertMessage("Please select all check lists", "error");
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } finally {
      setLoading(false);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((item) => item !== id)
        : [...prevIds, id]
    );
  };
  const handleSelectAll = () => {
    const allIds = checkData.map((item) => item.id);
    setSelectedIds((prevIds) =>
      prevIds.length === allIds.length ? [] : allIds
    );
  };
  const handleMoveInvoices = () => {
    const payload = {
      invoice_id: validData.join(","),
    };
    handleSave(payload);
    setShowInvalidInvoices(false);
    setIsMoveToValid(false);
  };
  const handleCanelMoveInvoices = () => {
    setModalVisible(false);
    handleCancel();
  };
  const InvoiceCards = ({ invoiceData }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            flex: "flex",
            maxHeight: "300px",
            overflowY: "auto",
            marginBottom: "16px",
            padding: "10px",
          }}
        >
          {invoiceData.map((invoice) => (
            <div
              key={invoice.invoice_id}
              style={{ marginBottom: "20px" }}
              className="dataContainer"
            >
              <div>
                <span
                  style={{ paddingRight: "10px", fontSize: "14px" }}
                  className="textBold"
                >
                  Invoice Number: {invoice.invoice_number}
                </span>
                <Copyable text={invoice.invoice_number} />
              </div>
              <ul>
                {invoice.orders.map((order) => (
                  <div key={order.order_id} style={{ marginTop: "10px" }}>
                    <p>
                      {" "}
                      Order No : {order.order_number}
                      <Copyable text={order.order_number} />
                      <span style={{ marginLeft: "8px" }}>
                        {order.validation_errors.join(",")}
                      </span>
                    </p>
                  </div>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {isMoveToValid && (
          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "5px",
                }}
              >
                <p
                  style={{ fontSize: "14px", marginTop: "-0.5px" }}
                  className="textBold"
                >
                  Do you want to proceed with remaining valid invoices?
                </p>
                <div>
                  <Button
                    type="primary"
                    style={{ marginRight: "5px" }}
                    onClick={() => handleMoveInvoices()}
                  >
                    yes
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => handleCanelMoveInvoices()}
                  >
                    no
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  };
  const allKeysExist = accountInvoices?.reapproved_invoices.filter(obj => selectedInfo?.selectedKeys.includes(obj._id));
  return (
    <>
      {enableChecklistApproval == "true" ? (
        <>
          <Fragment>
            <Button
              type={buttonType ? buttonType : "primary"}
              icon="save"
              disabled={isDisabled}
              size={size ? size : "default"}
              onClick={() => {
                setModalVisible(true);
              }}
            >
              {I18n.t("invoices.approve")}
            </Button>
            <Modal
              style={{ color: "red" }}
              title="Check List"
              visible={modalVisible}
              onCancel={() => {
                setModalVisible(false);
              }}
              footer={null}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ flex: "flex", overflowY: "auto", padding: "10px" }}
                >
                  <Row
                    key="selectAll"
                    style={{
                      borderBottom: "2px solid #E9E9E9",
                      marginBottom: "10px",
                      paddingBottom: "4px",
                    }}
                    className="textBold"
                  >
                    <Checkbox
                      style={{
                        fontSize: "15px",
                      }}
                      checked={selectedIds.length === checkData.length}
                      indeterminate={
                        selectedIds.length > 0 &&
                        selectedIds.length < checkData.length
                      }
                      onChange={handleSelectAll}
                    >
                      Select All
                    </Checkbox>
                  </Row>
                  {checkData.map((item) => (
                    <Row key={item.id}>
                      <Checkbox
                        style={{
                          fontSize: "16px",
                        }}
                        checked={selectedIds.includes(item.id)}
                        onChange={() => handleCheckboxChange(item.id)}
                      >
                        {item.name}
                      </Checkbox>
                    </Row>
                  ))}
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Fragment>
                    <Button
                      type="danger"
                      style={{ marginRight: "8px" }}
                      loading={loading}
                      disabled={isDisabled}
                      size="default"
                      onClick={() => handleCanelMoveInvoices()}
                    >
                      Cancel
                    </Button>
                  </Fragment>

                  <Fragment>
                    {allKeysExist ? (
                      // Show button if allKeysExist === true
                      <Button
                        type="primary"
                        icon="save"
                        className={className ? className : ""}
                        loading={loading}
                        disabled={isDisabled}
                        size={size ? size : "default"} 
                        onClick={() => setAccountModalVisible(true)}
                      >
                        {label}
                      </Button>
                    ) :  (
                      <Popconfirm
                        placement="topRight"
                        title={I18n.t("messages.confirm")}
                        onConfirm={handleSaveWithList}
                        okText="Yes"
                        cancelText="No"
                        disabled={isDisabled}
                      >
                        <Button
                          type="primary"
                          icon="save"
                          className={className ? className : ""}
                          loading={loading}
                          disabled={isDisabled}
                          size="default"
                        >
                          {label}
                        </Button>
                      </Popconfirm>
                    )} 
                  </Fragment>
                </div>
              </div>
            </Modal>
          </Fragment>
          <Fragment>
            {showInvailidInvoices && (
              <Modal
                title="Invalid Invoices"
                visible={showInvailidInvoices}
                onCancel={() => {
                  setShowInvalidInvoices(false);
                }}
                footer={null}
                width={560}
              >
                {data ? <InvoiceCards invoiceData={data} /> : ""}
              </Modal>
            )}
          </Fragment>
        </>
      ) : (
        <>
          <Fragment>
           
             {allKeysExist?.length > 0 ? (
              // Show button if allKeysExist === true
              <Button
               type={buttonType ? buttonType : "primary"}
                icon="save"
                className={className ? className : ""}
                loading={loading}
                disabled={isDisabled}
                size={size ? size : "default"}
                onClick={() => setAccountModalVisible(true)}
              >
                {label}
              </Button>
            ) :  (
              <Popconfirm
                placement="topRight"
                title={I18n.t("messages.confirm")}
                onConfirm={() =>
                  handleSave({ invoice_id: invoiceIds.join(",") })
                }
                okText="Yes"
                cancelText="No"
                disabled={isDisabled}
              >
                <Button
                  type={buttonType ? buttonType : "primary"}
                  icon="save"
                  className={className ? className : ""}
                  loading={loading}
                  disabled={isDisabled}
                  size={size ? size : "default"}
                >
                  {label}
                </Button>
              </Popconfirm>
            )}
          </Fragment>

          <Modal
            title="Reapproved invoices"
            visible={accountModalVisible}
            onCancel={() => setAccountModalVisible(false)}
            footer={[
              <Button
                key="cancel"
                type="danger"
                onClick={() => setAccountModalVisible(false)}
              >
                Cancel
              </Button>,
              <Button
                key="confirm"
                type="primary"
                onClick={() =>
                  handleSave({
                    invoice_id: invoiceIds.join(",") ,
                    reapproved_invoice_id: allKeysExist.map((inv) => inv.id).join(","),
                    email_unlocked_invoice: String(isEmailUnlocked),
                  })
                }
                loading={loading}
              >
                Confirm
              </Button>,
            ]}
          >
            <div
              style={{ maxHeight: "300px", overflowY: "auto", padding: "10px" }}
            >
              <p style={{ fontWeight: "bold", marginBottom: "8px" }}>
                Invoice Numbers:
              </p>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                {allKeysExist?.map((invoice, index) => (
                  <div
                    key={invoice.id}
                    style={{
                      display: "inline-flex",
                      alignItems: "center",       
                      fontSize: "14px",
                    }}
                  >
                    <span>{invoice.frequency_invoice_number}
                    {index < allKeysExist.length - 1 ? "," : ""}</span>
                  </div>
                ))}
              </div>
              <div style={{ marginTop: "16px" }}>
                <Checkbox
                checked={isEmailUnlocked} 
                onChange={(e) => setIsEmailUnlocked(e.target.checked)}
                
                >
                  send re-approved invoice emails 
                </Checkbox>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
PostInvoiceToQB.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  buttonType: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isDisabled: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  handleSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
  accountInvoices:PropTypes.shape(),

};
PostInvoiceToQB.defaultProps = {
  handleSuccess: () => {},
  indexList: true,
  accountInvoices: {reapproved_invoices:[]},
};
export default PostInvoiceToQB;
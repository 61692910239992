import React, { Fragment, useState } from "react";
import { Button, message } from "../../common/UIComponents";
import { printBillingPdf } from "../../api/Billing";
import { alertMessage, isEmpty } from "../../common/Common";
import moment from "moment";
import { base64ToBlob } from "../../common/preview";

const BillingPdfDownload = ({
  className,
  buttonType,
  size,
  label,
  isDisabled,
  handleSuccess,
  orderIds,
  retrivePreviewDetails,
}) => {
  const [loading, setLoading] = useState(false);

  const handlePrintBills = async () => {
    message.loading({
      key: "download",
      content: "Generating preview...",
    });
    const selectedIds = !isEmpty(orderIds) ? orderIds : [];
    try {
      setLoading(true);
      let result = await printBillingPdf(selectedIds);
      if (result.success) {
        const fileName = `OrderBillings-${moment().format("YYYY-MM-DDTHH:mm:ss")}.pdf`;
        const blob = base64ToBlob(result.bills_file, "application/pdf");
        const url = URL.createObjectURL(blob);
        retrivePreviewDetails(url, fileName);
      } else {
        message.destroy();
        if (result.errors.length > 0) {
          alertMessage(result.errors.join(", "), "error", 10);
        }
      }
    } catch (error) {
      alertMessage(error.message, "error", 10);
    } finally {
      setLoading(false);
      message.destroy();

    }
  };

  return (
    <Fragment>
      <Button
        onClick={() => handlePrintBills()}
        type={buttonType ? buttonType : "primary"}
        icon="printer"
        className={className ? className : ""}
        loading={loading}
        disabled={isDisabled}
        size={size ? size : "default"}
      >
        {label}
      </Button>
    </Fragment>
  );
};
export default BillingPdfDownload;
